import IProduct from './product';
import IProductOption from './productOption';
import IRestaurant from './restaurant';

import jsonApi from '@/libs/jsonapi';

interface IOrderItem {
  id: string,
  name: string,
  price: string,
  priceWithoutDiscount: string,
  discountPercent: string,
  totalPrice: string,
  totalPriceWithoutDiscount: string,
  quantity: number,
  productId: string,
  orderId: string,
  isAvailableForPurchase: boolean,

  product?: IProduct,
  productOption: IProductOption,
  restaurant?: IRestaurant,
}

jsonApi.define<IOrderItem>(
  'order_item',
  {
    name: '',
    price: '',
    priceWithoutDiscount: '',
    discountPercent: '',
    totalPrice: '',
    totalPriceWithoutDiscount: '',
    quantity: 0,
    productId: '',
    orderId: '',
    isAvailableForPurchase: false,

    product: {
      jsonApi: 'hasOne',
      type: 'products',
    },
    restaurant: {
      jsonApi: 'hasOne',
      type: 'restaurants',
    },
    productOption: {
      jsonApi: 'hasOne',
      type: 'product_options',
    },
  },
  {
    collectionPath: 'items',
  },
);

export default IOrderItem;
