import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';
import * as yup from 'yup';

import { INVALID_ORDER_ERRORS } from '@/constants';

import { IOrder, IDeliveryPrice } from '@/models/order';
import { IOrderPayment } from '@/models/orderPayment';
import IProductOption from '@/models/productOption';
import IOrderItem from '@/models/orderItem';
import { IPaymentType, PAYMENT_TYPE, isPaymentType } from '@/models/paymentType';
import {
  IDeliveryType, DELIVERY_TYPE,
} from '@/models/deliveryType';
import IPickupAddress from '@/models/pickupAddress';
import ICoupon from '@/models/coupon';
import IProduct from '@/models/product';
import IRestaurant from '@/models/restaurant';

import useCompany from './company';
import usePaymentType from './paymentType';
import { useCatalog } from './catalog';

import jsonApi from '@/libs/jsonapi';
import { addDays, getDayAndMonthStr } from '@/helpers/date';
import { orderSchema, chequeEmailSchema } from '@/validations/order';

const toast = useToast();

const ERROR_ORDERS: Record<string, number> = {
  userCustomer: 1,
  deliveryTypeId: 2,
  companyPickupAddress: 3,
  companyUserDeliveryAddress: 3,
  paymentTypeId: 4,
  chequeEmail: 5,
};

type IOrderState = {
  activeOrder?: IOrder,
  wasLoaded: boolean,
  isLoading: boolean,
  isChanging: boolean,
  paymentsTypes: IPaymentType[],
  deliveryTypes: IDeliveryType[],
  pickupAddresses: IPickupAddress[],
  processBtnWasClicked: boolean,
  invalidOrderErrorsCode: typeof INVALID_ORDER_ERRORS.codes[number] | '',

  chequeEmail: string,

  clientValidationError?: yup.ValidationError,
  serverValidationError?: Record<string, unknown>,
  errorOrders: Record<string, number>,

  priceForDelivery: string,
  isRequirePhoneConfirmation: boolean,
  isOnlinePaymentOnly: boolean,
  cookingTime: number | null,
}

const useOrder = defineStore('order', {
  state(): IOrderState {
    return {
      activeOrder: undefined,
      wasLoaded: false,
      isLoading: false,
      isChanging: false,
      paymentsTypes: [],
      deliveryTypes: [],
      pickupAddresses: [],
      priceForDelivery: '',

      chequeEmail: '',

      clientValidationError: undefined,
      serverValidationError: undefined,
      errorOrders: ERROR_ORDERS,

      processBtnWasClicked: false,
      isRequirePhoneConfirmation: false,
      isOnlinePaymentOnly: false,
      cookingTime: null,

      invalidOrderErrorsCode: '',
    };
  },

  getters: {
    getCookingDateString: () => (
      (time: number) => (getDayAndMonthStr(addDays(new Date(), Math.ceil(time / 24))))
    ),

    deliveryTime(state: IOrderState) {
      const deliveryTime = state.activeOrder?.deliveryTime;

      if (deliveryTime && deliveryTime.length) {
        return `${deliveryTime.join(' - ')} мин`;
      }
    },

    errorsByKeys(state: IOrderState) {
      const errors: Record<string, boolean> = {};

      if (!state.clientValidationError) {
        return errors;
      }

      state.clientValidationError.inner.forEach((error) => {
        errors[error.path as string] = true;
      });

      if (state.serverValidationError) {
        Object.keys(state.serverValidationError).forEach((field) => {
          errors[field] = true;
        });
      }

      return errors;
    },

    errorsKeysMessage(state: IOrderState) {
      return state.clientValidationError?.inner?.reduce((
        acc: { [path: string]: string },
        error: yup.ValidationError,
      ) => {
        if (error.path) {
          acc[error.path] = error.message;
        }

        return acc;
      }, {}) || {};
    },

    isEmpty(state: IOrderState) {
      return (
        state.activeOrder?.items?.length === 0 || state.activeOrder?.items?.length === undefined
      );
    },

    totalItemsQuantity(state: IOrderState): number {
      return state.activeOrder?.items?.reduce(((total, item) => total + item.quantity), 0) || 0;
    },

    quantitiesByOrderItemsIds(state: IOrderState) {
      const quantitiesByOrderItemsIds: {
        [key: string]: number,
      } = {};

      if (state.activeOrder?.items) {
        state.activeOrder.items.forEach((item) => {
          quantitiesByOrderItemsIds[item.id] = item.quantity;
        });
      }

      return quantitiesByOrderItemsIds;
    },

    quantitiesByProductsIds(state: IOrderState) {
      const quantitiesByProductsIds: {
        [key: string]: number,
      } = {};

      if (state.activeOrder?.items) {
        state.activeOrder.items.forEach((item) => {
          if (item.product) {
            quantitiesByProductsIds[item.product.id] = item.quantity;
          }
        });
      }

      return quantitiesByProductsIds;
    },

    getOptionQuantity(state: IOrderState) {
      return (optionId: string) => state.activeOrder?.items?.find(
        (item) => item.productOption?.id === optionId,
      )?.quantity || 0;
    },

    orderItemsIdsByProductsIds(state: IOrderState) {
      const orderItemsIdsByProductsIds: {
        [key: string]: string,
      } = {};

      if (state.activeOrder?.items) {
        state.activeOrder.items.forEach((item) => {
          if (item.product) {
            orderItemsIdsByProductsIds[item.product.id] = item.id;
          }
        });
      }

      return orderItemsIdsByProductsIds;
    },

    orderItemsIdsByOptionsIds(state: IOrderState) {
      const orderItemsIdsByOptionsIds: {
        [key: string]: string,
      } = {};

      if (state.activeOrder?.items) {
        state.activeOrder.items.forEach((item) => {
          if (item.product) {
            orderItemsIdsByOptionsIds[item.productOption.id] = item.id;
          }
        });
      }

      return orderItemsIdsByOptionsIds;
    },

    availablePaymentTypes(state: IOrderState): IPaymentType[] {
      if (state.isOnlinePaymentOnly) {
        return state.paymentsTypes.filter((pt) => pt.paymentTypeId === PAYMENT_TYPE.CARD_ONLINE);
      }

      if (!state.activeOrder?.isContactless) {
        return state.paymentsTypes;
      }

      return state.paymentsTypes.filter((pt) => pt.isContactless);
    },

    contactlessPaymentTypes(state: IOrderState): IPaymentType[] {
      return state.paymentsTypes.filter((pt) => pt.isContactless);
    },

    deliveryTypesTerms(state: IOrderState): string {
      const availableTerms: string[] = [];
      const notAvailableTerms: string[] = [];

      state.deliveryTypes.forEach(({ isAvailable, terms }) => {
        if (!terms) {
          return;
        }

        if (isAvailable) {
          availableTerms.push(terms);

          return;
        }

        notAvailableTerms.push(terms);
      });

      const allTerms = [...notAvailableTerms, ...availableTerms];

      return `${allTerms.join('. ')}${allTerms.length ? '.' : ''}`;
    },

    isAvailableDeliveryTypes(state: IOrderState): boolean {
      return state.deliveryTypes.every(({ isAvailable }) => isAvailable);
    },

    isLoaderVisible(state: IOrderState): boolean {
      return state.isLoading || state.isChanging;
    },

    deliveryAddress(state: IOrderState) {
      let address;

      if (!state.activeOrder) {
        return address;
      }

      switch (state.activeOrder?.deliveryTypeId) {
        case DELIVERY_TYPE.COURIER:
          address = state.activeOrder?.companyUserDeliveryAddress?.fullName;

          break;

        case DELIVERY_TYPE.SELF_PICKUP:
          address = state.activeOrder?.companyPickupAddress?.address;

          break;

        default:
          break;
      }

      return address;
    },

    deliveryPrices(state: IOrderState): {
      main: number,
      restaurants: IDeliveryPrice[],
      isVisibleRestaurants: boolean,
      } {
      let restaurants = [] as {name: string, price: string}[];
      let main = 0;

      // eslint-disable-next-line no-unused-expressions
      state.activeOrder?.deliveryPrice?.forEach((item) => {
        if (!item.name) {
          main = +item.price;
        } else {
          restaurants.push(item);
        }
      });

      if (restaurants.length === 1) {
        const [{ price }] = restaurants;

        main += +price;

        restaurants = [];
      }

      return ({
        main,
        restaurants,
        isVisibleRestaurants: !restaurants.every(({ price }) => !+price),
      });
    },
  },

  actions: {
    setBuilderStack(paths: string[] = []) {
      jsonApi.builderStack = [{
        path: [
          `companies/${useCompany().activeCompany!.login}/user/current-order`,
          ...paths,
        ].join('/'),
      }];
    },

    async changeChequeEmail(value: string) {
      this.chequeEmail = value;

      try {
        await chequeEmailSchema.validate({ chequeEmail: this.chequeEmail });
      } catch {
        return;
      }

      this.update({ chequeEmail: this.chequeEmail });
    },

    checkCoupon(couponCode: string) {
      return jsonApi.one('company', useCompany().activeCompany!.login)
        .one('coupon', couponCode).get<ICoupon>();
    },

    getPayment(id: string) {
      return jsonApi.one('company', useCompany().activeCompany!.login)
        .one('payment', id).get<IOrderPayment>();
    },

    getSerializedOrder(params: Partial<IOrder> = {}) {
      return jsonApi.customSerialize(
        jsonApi,
        'order',
        {
          ...this.activeOrder,
          ...params,
        },
      );
    },

    clearActiveOrder() {
      this.activeOrder = undefined;
      this.paymentsTypes = [];
      this.deliveryTypes = [];
      this.pickupAddresses = [];
      this.wasLoaded = false;
      this.processBtnWasClicked = false;
      this.priceForDelivery = '';
      this.isRequirePhoneConfirmation = false;
      this.isOnlinePaymentOnly = false;
      this.cookingTime = null;
      this.chequeEmail = '';
    },

    async fetchActiveOrder() {
      this.setBuilderStack();

      this.isLoading = true;

      try {
        const response = await jsonApi.get<IOrder>();
        this.activeOrder = response?.data;

        if (!this.chequeEmail) {
          this.chequeEmail = this.activeOrder?.chequeEmail || '';
        }

        this.priceForDelivery = response?.meta?.price_for_delivery as string;
        this.isRequirePhoneConfirmation = response?.meta?.require_phone_confirmation as boolean;
        this.isOnlinePaymentOnly = response?.meta?.online_payment_only as boolean;
        this.cookingTime = response?.meta?.cooking_time as number;

        if (isPaymentType(this.activeOrder?.paymentTypeId)) {
          usePaymentType().setType(this.activeOrder?.paymentTypeId);
        }
      } catch (e) {
        this.activeOrder = undefined;
      }

      this.wasLoaded = true;
      this.isLoading = false;
      this.processBtnWasClicked = false;
    },

    async addProduct(product: IProduct, productOption: IProductOption, quantity = 1) {
      this.setBuilderStack();

      this.isChanging = true;

      const catalogStore = useCatalog();
      const params: {
        quantity: number,
        productOption: IProductOption,
        product?: IProduct,
        restaurant?: IRestaurant,
      } = {
        quantity,
        product,
        productOption,
      };

      if (catalogStore.selectedRestaurantId) {
        params.restaurant = useCompany().getRestaurantById(
          catalogStore.selectedRestaurantId,
        );
      }

      await jsonApi.all('order_item').post<IOrderItem>(params);

      await this.fetchActiveOrder();
      this.getDeliveryTypes();

      this.isChanging = false;
    },

    async deleteItem(orderItemId: string) {
      this.setBuilderStack();

      this.isChanging = true;

      await jsonApi.one('order_item', orderItemId).destroy();

      await this.fetchActiveOrder();
      this.getDeliveryTypes();

      this.isChanging = false;
    },

    async decreaseItemQuantity(orderItemId: string) {
      this.setBuilderStack();

      this.isChanging = true;

      const newQuantity = this.quantitiesByOrderItemsIds[orderItemId] - 1;

      if (newQuantity === 0) {
        this.deleteItem(orderItemId);
      } else {
        await jsonApi.one('order_item', orderItemId).patch({
          quantity: newQuantity,
        });

        await this.fetchActiveOrder();
        this.getDeliveryTypes();
      }

      this.isChanging = false;
    },

    async increaseItemQuantity(orderItemId: string, step = 1) {
      this.setBuilderStack();

      this.isChanging = true;

      try {
        await jsonApi.one('order_item', orderItemId).patch({
          quantity: this.quantitiesByOrderItemsIds[orderItemId] + step,
        });
      } catch (error: any) {
        toast.error(error.response.data.errors[0].title);
        this.isChanging = false;

        return;
      }

      await this.fetchActiveOrder();
      this.getDeliveryTypes();

      this.isChanging = false;
    },

    async update(params: Partial<IOrder>) {
      this.setBuilderStack();
      const order = this.getSerializedOrder(params);

      this.isLoading = true;

      try {
        const response = await jsonApi.patch<IOrder>(order);

        this.activeOrder = response?.data;

        this.priceForDelivery = response?.meta?.price_for_delivery as string;
        this.isRequirePhoneConfirmation = response?.meta?.require_phone_confirmation as boolean;
        this.isOnlinePaymentOnly = response?.meta?.online_payment_only as boolean;
        this.cookingTime = response?.meta?.cooking_time as number;
      } finally {
        this.isLoading = false;
      }

      this.validate();
    },

    async getPaymentTypes() {
      const paymentTypes = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('payment-type').get<IPaymentType[]>()
      ).data;

      this.paymentsTypes = paymentTypes;
    },

    async getDeliveryTypes() {
      const deliveryTypes = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('delivery-type').get<IDeliveryType[]>()
      ).data;

      this.deliveryTypes = deliveryTypes;
    },

    async getPickupAddresses() {
      const pickupAddresses = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('company_pickup_address').get<IPickupAddress[]>()
      ).data;

      this.pickupAddresses = pickupAddresses;

      return pickupAddresses;
    },

    async payOrder(order: IOrder): Promise<IOrder | undefined> {
      const requestData = jsonApi.customSerialize(
        jsonApi,
        'order',
        {
          paymentTypeId: order.paymentTypeId,
        },
      );

      jsonApi.builderStack = [{
        path: `companies/${useCompany().activeCompany!.login}/user/orders/${order.id}/pay`,
      }];

      return (await jsonApi.post<IOrder>(requestData)).data;
    },

    async process(params: Partial<IOrder>): Promise<IOrder | undefined> {
      this.setBuilderStack(['confirm']);
      const order = this.getSerializedOrder(params);

      this.isLoading = true;

      let response: IOrder;

      try {
        response = (await jsonApi.post<IOrder>(order)).data;
      } catch (error: any) {
        this.invalidOrderErrorsCode = error.response.data.errors?.find(
          ({ code }: { code: string }) => INVALID_ORDER_ERRORS.codes.includes(code),
        )?.code;

        this.serverValidationError = error.response.data.errors[0] || error;

        return undefined;
      } finally {
        this.isLoading = false;
      }

      return response;
    },

    async validate(): Promise<boolean> {
      if (!this.processBtnWasClicked) {
        return false;
      }

      this.serverValidationError = {};
      this.clientValidationError = undefined;

      try {
        await orderSchema.validate({ ...this.activeOrder }, { abortEarly: false });
        await chequeEmailSchema.validate({ chequeEmail: this.chequeEmail }, { abortEarly: false });
      } catch (e) {
        if (e instanceof yup.ValidationError) {
          this.clientValidationError = e;
        }

        return false;
      }

      return true;
    },

    async getById(
      orderId: string,
      params?: Record<string, string | number | boolean>,
    ): Promise<IOrder> {
      jsonApi.builderStack = [{
        path: `companies/${useCompany().activeCompany!.login}/user/orders/${orderId}`,
      }];

      const order = (await jsonApi.get<IOrder>(params)).data;

      return order;
    },

    async fetch(params?: Record<string, string>): Promise<IOrder[]> {
      const response = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('order')
          .get<IOrder[]>(params)
      );

      return response.data;
    },

    async applyBonuses(bonuses: number) {
      this.setBuilderStack(['apply-bonuses']);

      await jsonApi.post({
        attributes: {
          sum: bonuses,
        },
        type: 'orders',
      });

      this.setBuilderStack();

      await this.fetchActiveOrder();
    },

    async resetBonuses() {
      this.setBuilderStack(['apply-bonuses']);

      await jsonApi.post({
        attributes: {
          sum: null,
        },
        type: 'orders',
      });

      this.setBuilderStack();

      await this.fetchActiveOrder();
    },

    async repeatOrder(orderId: string) {
      jsonApi.builderStack = [
        {
          path: `companies/${useCompany().activeCompany!.login}/user/orders/${orderId}/repeat`,
        },
      ];

      await jsonApi.post({});
      await this.fetchActiveOrder();
    },

    clearOrderErrorCode() {
      this.invalidOrderErrorsCode = '';
    },
  },
});

export default useOrder;
