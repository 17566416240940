import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e27aa5d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-item" }
const _hoisted_2 = { class: "info-wrapper" }
const _hoisted_3 = { class: "order-item__product product" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "product__weight"
}
const _hoisted_6 = { class: "order-item__control control" }
const _hoisted_7 = { class: "control__quantity" }
const _hoisted_8 = { class: "control__price" }
const _hoisted_9 = { class: "control__total rouble-symbol" }
const _hoisted_10 = {
  key: 0,
  class: "order-item__restaurant"
}
const _hoisted_11 = {
  key: 1,
  class: "order-item__cooking-time-warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuantityControl = _resolveComponent("QuantityControl")!
  const _component_FinalPricePanel = _resolveComponent("FinalPricePanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "product__title",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleFastView')))
        }, [
          _createTextVNode(_toDisplayString(_ctx.orderItem?.product?.name) + " ", 1),
          (!_ctx.isSingleOption)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(_ctx.orderItem?.productOption?.title) + ") ", 1))
            : _createCommentVNode("", true)
        ]),
        (_ctx.orderItem.productOption.weight)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.orderItem.productOption.weight) + " г ", 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_QuantityControl, {
            quantity: _ctx.orderItem.quantity,
            onIncrease: _cache[1] || (_cache[1] = ($event: any) => (_ctx.orderStore.increaseItemQuantity(_ctx.orderItem.id))),
            onDecrease: _cache[2] || (_cache[2] = ($event: any) => (_ctx.orderStore.decreaseItemQuantity(_ctx.orderItem.id)))
          }, null, 8, ["quantity"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$filters.formattedNumber(_ctx.orderItem.totalPrice)), 1),
          (_ctx.orderItem.productOption.hasStopPrice)
            ? (_openBlock(), _createBlock(_component_FinalPricePanel, {
                key: 0,
                class: "final-price",
                "is-reverse-panel": false
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.orderItem.restaurant && _ctx.isVisibleRestaurant)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.orderItem.restaurant.name), 1))
      : _createCommentVNode("", true),
    (_ctx.orderItem.productOption.cookingTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Этот товар замедляет получение, доступен " + _toDisplayString(_ctx.orderStore.getCookingDateString(_ctx.orderItem.productOption.cookingTime)), 1))
      : _createCommentVNode("", true)
  ]))
}