import jsonApi from '@/libs/jsonapi';

interface ISeoTag {
  id: string;
  tagName: string;
  tagValue: string;
}

jsonApi.define<ISeoTag>(
  'seo_tag',
  {
    tagName: '',
    tagValue: '',
  },
  {
    collectionPath: 'seo-tags',
  },
);

export default ISeoTag;
