import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-314ea84d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-addresses-list" }
const _hoisted_2 = {
  key: 0,
  class: "delivery-addresses-list__form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeliveryAddressItem = _resolveComponent("DeliveryAddressItem")!
  const _component_DeliveryAddressForm = _resolveComponent("DeliveryAddressForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryAddressStore.deliveryAddresses, (deliveryAddress, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: deliveryAddress.id,
        class: "delivery-addresses-list__item"
      }, [
        _createVNode(_component_DeliveryAddressItem, {
          "address-number": index + 1,
          "with-zones": _ctx.withZones,
          "delivery-address": deliveryAddress,
          "is-selected": deliveryAddress.id === _ctx.selectedId,
          onSelect: ($event: any) => (_ctx.$emit('wasSelected', deliveryAddress)),
          onWasRemoved: _cache[0] || (_cache[0] = (id) => _ctx.$emit('wasRemoved', id)),
          onWasUpdated: _cache[1] || (_cache[1] = (address) => _ctx.$emit('wasUpdated', address))
        }, null, 8, ["address-number", "with-zones", "delivery-address", "is-selected", "onSelect"])
      ]))
    }), 128)),
    (_ctx.isFormOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_DeliveryAddressForm, {
            "delivery-address": _ctx.newDeliveryAddress,
            "can-close": Boolean(_ctx.deliveryAddressStore.deliveryAddresses.length),
            onSelect: _cache[2] || (_cache[2] = (deliveryAddress) => _ctx.$emit('wasSelected', deliveryAddress)),
            onWasUpdated: _cache[3] || (_cache[3] = (address) => _ctx.$emit('wasUpdated', address)),
            onWasRemoved: _cache[4] || (_cache[4] = (id) => _ctx.$emit('wasRemoved', id)),
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleForm(false)))
          }, null, 8, ["delivery-address", "can-close"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isFormOpened)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "delivery-addresses-list__add-btn base-btn base-btn--default",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleForm(true)))
        }, " Добавить новый адрес "))
      : _createCommentVNode("", true)
  ]))
}