
import { defineComponent, PropType } from 'vue';
import { MoFooter } from '@mo/uikit';

import { COPYRIGHT_START_YEAR } from '@/constants';

import ILegalDocument, { DOCUMENT_TYPE } from '@/models/legalDocument';

import useCompany from '@/stores/company';

const DS1_LOGIN = 'sovremennik-delivery';
const COUNT_OF_FIELDS_FOR_DOCUMENTS = 7;

export default defineComponent({
  name: 'Footer',

  components: {
    MoFooter,
  },

  props: {
    legalDocuments: {
      type: Array as PropType<ILegalDocument[]>,
      default: () => [],
    },
    coverUrl: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    return {
      DOCUMENT_TYPE,
      DS1_LOGIN,
      styleObject: { backgroundImage: `url(${props.coverUrl})` },
      companyStore: useCompany(),
    };
  },

  data(): {
    copyrightStartYear: number;
    } {
    return {
      copyrightStartYear: COPYRIGHT_START_YEAR,
    };
  },

  computed: {
    displayedDocuments(): ILegalDocument[] {
      return this.legalDocuments
        .filter(({ isDisplayInFooter }) => isDisplayInFooter)
        .slice(0, COUNT_OF_FIELDS_FOR_DOCUMENTS);
    },

    countOfEmptyFields() {
      return COUNT_OF_FIELDS_FOR_DOCUMENTS - this.displayedDocuments.length;
    },
  },
});
