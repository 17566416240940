
import { defineComponent } from 'vue';

import useOrder from '@/stores/order';
import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';

import OrderWarning from '../OrderWarning/OrderWarning.vue';

export default defineComponent({
  name: 'MobileOrderPreviewLayout',

  components: {
    OrderWarning,
  },

  setup() {
    return {
      authStore: useAuth(),
      orderStore: useOrder(),
      companyStore: useCompany(),
    };
  },

  methods: {
    goToCart() {
      if (!this.authStore.activeUser) {
        this.authStore.toggleAuthWindow(true);
      } else {
        this.$router.push({ name: 'c-cart' });
      }
    },
  },
});
