<template>
  <section class="not-found">
    <img src="./images/error-not-found.png">
  </section>
</template>

<style lang="scss" scoped>
.not-found {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
}
</style>
