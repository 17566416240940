/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { defineStore } from 'pinia';
import { orderBy, remove } from 'lodash';

import IUserCustomer from '@/models/userCustomer';

import useCompany from '@/stores/company';

import jsonApi from '@/libs/jsonapi';

const stub: IUserCustomer = {
  id: '',
  fullName: '',
  phone: '',
  isDefault: true,
};

type IUserCustomerState = {
  userCustomers: IUserCustomer[],
};

const useUserCustomer = defineStore('userCustomer', {
  state(): IUserCustomerState {
    return {
      userCustomers: [],
    };
  },

  getters: {
    sortedUserCustomers(state: IUserCustomerState) {
      return orderBy(state.userCustomers, 'isDefault', 'desc');
    },
  },

  actions: {
    getEmpty(): IUserCustomer {
      return {
        ...stub,
      };
    },

    async setDefault(id: string) {
      const userCustomer = this.userCustomers.find((uc) => uc.id === id);

      if (!userCustomer) {
        throw new Error('Ошибка установки получателя по-умолчанию');
      }

      await this.update({
        ...userCustomer,
        isDefault: true,
      });

      this.userCustomers.forEach((item) => {
        /* eslint-disable-next-line no-param-reassign */
        item.isDefault = item.id === id;
      });
    },

    async update(userCustomer: IUserCustomer): Promise<IUserCustomer> {
      const updatedUserCustomer = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .one('user_customer', userCustomer.id)
          .patch<IUserCustomer>(userCustomer)
      ).data;

      const index = this.userCustomers.findIndex((uc) => uc.id === updatedUserCustomer.id);
      this.userCustomers[index] = updatedUserCustomer;

      return updatedUserCustomer;
    },

    async create(data: Omit<IUserCustomer, 'id'>): Promise<IUserCustomer> {
      const newUserCustomer = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('user_customer')
          .post<IUserCustomer>(data)
      ).data;

      this.userCustomers.push(newUserCustomer);

      for (let i = 0; i < this.userCustomers.length; i += 1) {
        this.userCustomers[i].isDefault = this.userCustomers[i].id === newUserCustomer.id;
      }

      return newUserCustomer;
    },

    async remove(id: string): Promise<void> {
      await jsonApi.one('company', useCompany().activeCompany!.login)
        .one('user_customer', id)
        .destroy();

      remove(this.userCustomers, (uc) => uc.id === id);
    },

    async getAll(): Promise<IUserCustomer[]> {
      const userCustomers = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('user_customer')
          .get<IUserCustomer[]>()
      ).data;

      this.userCustomers = userCustomers;

      return userCustomers;
    },
  },
});

export default useUserCustomer;
