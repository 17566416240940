import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { SCOPE_TYPE } from '@/types';

import ICompany from '@/models/company';
import ISeoTag from '@/models/seoTag';

import useCompany from '@/stores/company';
import useAuth from '@/stores/auth';
import useOrder from '@/stores/order';

import AggregatorRoutes from './aggregator';
import CompanyRoutes from './company';
import ParentCompanyRoutes from './parent-company';

import YMetrika from '@/libs/yandex-metrika';

declare module 'vue-router' {
  type entityType = 'parentCompany' | 'user' | 'order';

  interface RouteMeta {
    fetch?: Partial<Record<entityType, true>>;
    requireAuth?: boolean;
    title?: string;
    scope?: SCOPE_TYPE.AGGREGATOR | SCOPE_TYPE.PARENT_COMPANY | SCOPE_TYPE.COMPANY;
  }
}

const routes: Array<RouteRecordRaw> = [
  // Роутер контекста агрегатора
  ...AggregatorRoutes,

  // Роутер контекста родительской компании
  ...ParentCompanyRoutes,

  // Роутер контекста обычной или дочерней компании
  ...CompanyRoutes,
];

const router = createRouter({
  scrollBehavior(to) {
    if (to.params.isScrollDisabled) {
      // eslint-disable-next-line no-param-reassign
      delete to.params.isScrollDisabled;

      return {};
    }

    if (to.query.append === 'true') {
      return {};
    }

    return {
      top: 0,
      behavior: 'smooth',
    };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const COMPANY_LOGIN_COOKIE_NAME = 'company_login=';

// В приложении есть 3 scope.
// Агрегатор - без префикса,
// Родительская компания - префикс /p/
// Обычная либо дочерняя компания - префикс /c/
// Проверяем, что роут принадлежит одному из scope
router.beforeEach(async (to, from, next) => {
  const baseRoute = to.matched[0];
  const { scope } = baseRoute.meta;

  if (!scope) {
    throw Error('Не установлен scope для базового роута');
  }

  next();
});

function getCompanyLoginFromCookie() {
  const { cookie } = document;

  if (!cookie) {
    return '';
  }

  const loginCookie = cookie
    .split(';')
    .map((c) => c.trim())
    .find((c) => c.startsWith(COMPANY_LOGIN_COOKIE_NAME));

  if (!loginCookie) {
    return '';
  }

  return loginCookie.slice(COMPANY_LOGIN_COOKIE_NAME.length);
}

function detectCorrectScope(company?: ICompany): SCOPE_TYPE {
  if (!company) {
    return SCOPE_TYPE.AGGREGATOR;
  }

  if (company.isMain) {
    return SCOPE_TYPE.PARENT_COMPANY;
  }

  return SCOPE_TYPE.COMPANY;
}

function getScopeBaseRoute(correctScope: SCOPE_TYPE): string {
  let route = '';

  switch (correctScope) {
    case SCOPE_TYPE.AGGREGATOR:
      route = 'aggregator';

      break;

    case SCOPE_TYPE.COMPANY:
      route = 'company';

      break;

    case SCOPE_TYPE.PARENT_COMPANY:
      route = 'parent-company';

      break;

    default:
      throw Error('Ошибка определения базового роута');
  }

  return route;
}

function injectCustomScript(customScript: string) {
  const buffer = document.createElement('div');
  buffer.innerHTML = customScript;

  Array.from(buffer.children).forEach((element) => {
    const insertedElement = document.createElement(element.nodeName);
    insertedElement.textContent = element.textContent;

    for (let attribute of element.attributes) {
      insertedElement.setAttribute(attribute.nodeName, attribute.value);
    }

    document.body.appendChild(insertedElement);
  });
}

function injectMetaTeg(seoTags: ISeoTag[]) {
  const head = document.getElementsByTagName('head')[0];

  seoTags.forEach((seoTag) => {
    if (!seoTag.tagValue) {
      return;
    }

    const meta = document.createElement('meta');
    meta.name = seoTag.tagName;
    meta.content = seoTag.tagValue;
    head.appendChild(meta);
  });
}

// Чтобы не делать дополнительный запрос, мы получаем login партнера в cookie
// На основе login проверяем валидность загружаемого роута (соответствие scope и компании)
// Если роуты не совпадают, то выполняем редирект
// Например, чтобы можно было открывать компанию по домену без префикса /p/ и /c/
router.beforeEach(async (to, from, next) => {
  const companyLogin = getCompanyLoginFromCookie();
  const companyStore = useCompany();
  const baseRoute = to.matched[0];
  const { scope } = baseRoute.meta;

  if (companyLogin && companyStore.activeCompany?.login !== companyLogin) {
    await companyStore.fetchActiveCompany(companyLogin, {
      include: 'domain,children,parent,banners,custom_pages,legal_documents,restaurants,seo_tags',
    });

    if (companyStore.activeCompany?.yandexMetricKey) {
      YMetrika.initialize(companyStore.activeCompany?.yandexMetricKey);
    }

    if (companyStore.activeCompany?.customScript) {
      injectCustomScript(companyStore.activeCompany?.customScript);
    }

    if (companyStore.activeCompany?.seoTags?.length) {
      injectMetaTeg(companyStore.activeCompany?.seoTags);
    }
  }

  const correctScope = detectCorrectScope(companyStore.activeCompany);

  if (scope !== correctScope) {
    next({
      name: getScopeBaseRoute(correctScope),
    });
  } else {
    next();
  }
});

// Проверка авторизации
router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((route) => route.meta.requireAuth);

  if (requireAuth) {
    const authStore = useAuth();

    await authStore.fetchActiveUser('mo_base_user,favorite_products');

    if (!authStore.activeUser) {
      document.location.assign('/');
    }
  }

  next();
});

// Установка title
router.beforeEach(async (to, from, next) => {
  const companyStore = useCompany();

  const baseTitle = companyStore.activeCompany?.name || 'Мо.Доставка';

  document.title = to.meta.title ? [baseTitle, to.meta.title].join(' | ') : baseTitle;

  next();
});

// Загрузка требуемых ресурсов, описанных в meta
router.beforeEach(async (to, from, next) => {
  const authStore = useAuth();
  const companyStore = useCompany();
  const orderStore = useOrder();

  const baseRoute = to.matched[0];
  const { fetch } = baseRoute.meta;
  const promises = [];

  if (
    fetch?.parentCompany &&
    companyStore.activeCompany?.parent?.login !== companyStore.activeParentCompany?.login
  ) {
    promises.push(companyStore.fetchActiveParentCompany({ include: 'domain' }));
  }

  if (fetch?.user && authStore.activeUser === undefined) {
    promises.push(authStore.fetchActiveUser('mo_base_user,favorite_products'));
  }

  if (fetch?.order && orderStore.activeOrder === undefined) {
    promises.push(orderStore.fetchActiveOrder());
  }

  await Promise.all(promises);

  next();
});

// Отправка метрики
router.beforeEach((to, from, next) => {
  YMetrika.hit(window.location.href);

  next();
});

export default router;
