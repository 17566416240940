export default function replaceFirstEight({ target }: { target: HTMLInputElement }): void {
  const EIGHT = '8';
  const SEVEN = '7';

  if (target?.value !== EIGHT) {
    return;
  }

  /* eslint-disable no-param-reassign */
  target.value = SEVEN;
}
