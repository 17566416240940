
import { defineComponent, PropType } from 'vue';

import { QUERY_PARAMS } from '@/types';

import IProduct from '@/models/product';
import IProductOption from '@/models/productOption';

import useOrder from '@/stores/order';
import useFastView from '@/stores/fastView';

import getQueryParams from '@/helpers/queryParams';
import InteractiveProductItem from '@/components/shared/Products/InteractiveProductItem/InteractiveProductItem.vue';

const getOffset = (elem: HTMLElement) => {
  const rect = elem.getBoundingClientRect();

  return {
    left: rect.x,
    top: rect.top + window.scrollY,
  };
};

export default defineComponent({
  name: 'ProductsList',

  components: {
    InteractiveProductItem,
  },

  props: {
    products: {
      type: Array as PropType<IProduct[]>,
      required: true,
    },
  },

  emits: ['toggleFavorite'],

  setup() {
    return {
      orderStore: useOrder(),
      fastViewStore: useFastView(),
    };
  },

  methods: {
    addProductToOrder(product: IProduct, option: IProductOption) {
      this.orderStore.addProduct(product, option);

      this.createAddToCartAnimation(product.id);
    },

    increaseOrderItemQuantity(orderItemId: string) {
      this.orderStore.increaseItemQuantity(orderItemId);
    },

    decreaseOrderItemQuantity(orderItemId: string) {
      this.orderStore.decreaseItemQuantity(orderItemId);
    },

    createAddToCartAnimation(id: string) {
      const $body = document.body;
      const $cart = document.getElementById('cart');
      const $product = document.getElementById(`product-${id}`);

      if (!$product || !$cart) {
        return;
      }

      const $productClone = $product.cloneNode(true) as HTMLElement;

      $productClone.classList.add('product-clone');
      $productClone.addEventListener('transitionend', () => {
        $productClone.remove();
      });

      const productCoords = getOffset($product);
      const cartCoords = getOffset($cart);

      $productClone.style.cssText = `
        left: ${productCoords.left}px;
        top: ${productCoords.top}px;
        width: ${$product.offsetWidth}px;
      `;

      $body.appendChild($productClone);

      $productClone.style.cssText = `
        transform: scale(0.05) rotate(40deg);
        opacity: 0.9;
        left: calc(${cartCoords.left}px - ${$product.offsetWidth / 2}px);
        top: calc(${cartCoords.top}px - ${$product.offsetHeight / 2}px);
      `;
    },

    openFastView(product: IProduct, option: IProductOption) {
      const query = getQueryParams(this.$route.query, {
        [QUERY_PARAMS.openedProductId]: product.id,
      });

      const params = { ...this.$route.params, isScrollDisabled: 'true' };

      this.$router.push({
        name: 'c-catalog',
        params,
        query,
      });

      this.fastViewStore.open({ product, option });
    },
  },
});
