
import { defineComponent } from 'vue';

import useAuth from '@/stores/auth';

import { IMenuItem } from '@/types';

import MainMenu from '@/components/shared/MainMenu/MainMenu.vue';

export default defineComponent({
  name: 'UserView',

  components: {
    MainMenu,
  },

  data(): {
    menuItems: IMenuItem<string>[];
    isMounted: boolean;
    } {
    const authStore = useAuth();

    const menuItems = [
      {
        title: 'Заказы',
        route: 'c-orders',
      },
    ];

    if (authStore.bonusInfo) {
      menuItems.push({
        title: 'Лояльность и акции',
        route: 'c-loyalty',
      });
    }

    menuItems.push({
      title: 'Профиль',
      route: 'c-profile',
    });

    return {
      menuItems,
      isMounted: false,
    };
  },

  mounted() {
    this.isMounted = true;
  },
});
