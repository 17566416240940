import JsonApi from 'devour-client';

import errorMiddleware from '@/libs/middlewares/errorMiddleware';
import toSnakeCaseMiddleware from '@/libs/middlewares/toSnakeCaseMiddleware';
import toCamelCaseMiddleware from '@/libs/middlewares/toCamelCaseMiddleware';
import convertObjectKeysToSnakeCase from '@/helpers/convertObjectKeysToSnakeCase';
import versionControlMiddleware from '@/libs/middlewares/versionControlMiddleware';

enum jsonApiMiddlewares {
  post = 'POST',
  errors = 'errors',
  response = 'response',
}

class JsonApiCustom extends JsonApi {
  define<T>(modelName: string, attributes: Attributes<T>, options?: Record<string, unknown>): void {
    const convertedDataItem = convertObjectKeysToSnakeCase(attributes);

    super.define(modelName, convertedDataItem, options);
  }

  customSerialize<T>(ctx: any, modelName: string, attributes: Attributes<T> | undefined): any {
    const convertedAttributes = convertObjectKeysToSnakeCase(attributes || {});

    return this.serialize.resource.call(
      ctx,
      modelName,
      convertedAttributes,
    );
  }
}

const jsonApi = new JsonApiCustom({
  apiUrl: '/api/v1',
});

jsonApi.headers['content-type'] = 'application/vnd.api+json';

jsonApi.replaceMiddleware(jsonApiMiddlewares.errors, errorMiddleware);
jsonApi.insertMiddlewareBefore(jsonApiMiddlewares.post, toSnakeCaseMiddleware);
jsonApi.insertMiddlewareAfter(jsonApiMiddlewares.response, toCamelCaseMiddleware);
jsonApi.insertMiddlewareBefore(jsonApiMiddlewares.response, versionControlMiddleware);

export default jsonApi;
