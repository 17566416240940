import jsonApi from '@/libs/jsonapi';

enum DELIVERY_TYPE {
  COURIER = 'courier',
  SELF_PICKUP = 'self_pickup',
}

const DELIVERY_TITLES = {
  [DELIVERY_TYPE.COURIER]: 'Доставка',
  [DELIVERY_TYPE.SELF_PICKUP]: 'Самовывоз',
};

type DeliveryType = DELIVERY_TYPE.COURIER | DELIVERY_TYPE.SELF_PICKUP;

interface IDeliveryType {
  title: string;
  description: string;
  deliveryTypeId: DeliveryType;
  isEnabled: boolean;
  isAvailable: boolean;
  terms: string;
}

jsonApi.define<IDeliveryType>(
  'company_delivery_type',
  {
    title: '',
    description: '',
    deliveryTypeId: DELIVERY_TYPE.SELF_PICKUP,
    isEnabled: false,
    isAvailable: false,
    terms: '',
  },
  {
    collectionPath: 'delivery-types',
  },
);

export {
  IDeliveryType, DeliveryType, DELIVERY_TYPE, DELIVERY_TITLES,
};
