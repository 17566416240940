const ACTIONS = {
  hit: 'hit',
  init: 'init',
  reachGoal: 'reachGoal',
};

class YandexMetric {
  key = '';

  initialize(key: string) {
    this.key = key;

    this.initializeVariable();
    this.initializeWebvisor();
    this.initializeMetric();
  }

  initializeWebvisor() {
    const noscript = document.getElementsByTagName('noscript')[0];
    const webvisor = document.createElement('div');

    webvisor.innerHTML = `<img src="https://mc.yandex.ru/watch/${this.key}"
      style="position:absolute; left:-9999px;" alt="" />`;

    noscript.appendChild(webvisor);
  }

  /* eslint-disable */
  initializeVariable() {
    // @ts-ignore
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    // @ts-ignore
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  }

  initializeMetric() {
    window.ym(this.key, ACTIONS.init, {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  }

  hit(url: string) {
    if (!this.key) {
      return;
    }

    window.ym(this.key, ACTIONS.hit, url);
  }

  goal(target: string) {
    if (!this.key) {
      return;
    }

    window.ym(this.key, ACTIONS.reachGoal, target);
  }
}

export default new YandexMetric();
