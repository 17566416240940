
import { defineComponent } from 'vue';
import VueSelect from 'vue-select';

import IPickupAddress from '@/models/pickupAddress';

import useOrder from '@/stores/order';

export default defineComponent({
  name: 'PickupAddresses',

  components: {
    VueSelect,
  },

  emits: ['wasSelected'],

  data() {
    const orderStore = useOrder();

    orderStore.getPickupAddresses().then((addresses) => {
      if (this.selectedAddress) {
        return;
      }

      this.selectedAddress = this.getSelectedAddress(addresses);

      this.select(this.selectedAddress);
    });

    return {
      orderStore,
      selectedAddress: orderStore.activeOrder?.companyPickupAddress,
    };
  },

  methods: {
    async select(address: IPickupAddress) {
      await this.orderStore.update({
        companyPickupAddress: address,
      });

      this.$emit('wasSelected');
    },

    getSelectedAddress(
      pickupAddresses: IPickupAddress[],
    ): IPickupAddress | undefined {
      let selectedAddress = pickupAddresses.find(({ isDefault }) => isDefault);

      if (!selectedAddress && pickupAddresses.length === 1) {
        [selectedAddress] = pickupAddresses;
      }

      return selectedAddress;
    },
  },
});
