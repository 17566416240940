import { defineStore } from 'pinia';

import IBonusTransaction from '@/models/bonusTransaction';

import useCompany from '@/stores/company';

import jsonApi from '@/libs/jsonapi';

type IParam = {
  page: {
    number: number;
    size: number;
  };

  filters?: {
    type: string | undefined;
  };

  append?: boolean;
};

const useBonusTransaction = defineStore('bonusTransaction', {
  state(): {
    transactions: IBonusTransaction[];
    totalBonusTransactions: number;
    pageSize: number;
    totalPages?: number;
    } {
    return {
      transactions: [],
      totalBonusTransactions: 0,
      pageSize: 5,
      totalPages: 0,
    };
  },

  actions: {
    async getAll(params: IParam): Promise<void> {
      const { data, meta } = await jsonApi
        .one('company', useCompany().activeCompany!.login)
        .all('bonus_transaction')
        .get<IBonusTransaction[], { total: number }>(params);

      if (params.append) {
        this.transactions.push(...data);
      } else {
        this.transactions = data;
      }

      this.totalBonusTransactions = meta.total;
      this.totalPages = Math.ceil(this.totalBonusTransactions / this.pageSize);
    },
  },
});

export default useBonusTransaction;
