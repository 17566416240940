const DEVICE_TYPES = {
  desktop: 'desktop_browser',
  mobile: 'mobile_browser',
};

const userAgentMobileMask = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;

export default function getDeviceType(userAgent: string): string {
  return userAgentMobileMask.test(userAgent)
    ? DEVICE_TYPES.mobile
    : DEVICE_TYPES.desktop;
}
