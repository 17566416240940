import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-057c792e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-customers-list" }
const _hoisted_2 = {
  key: 0,
  class: "user-customers-list__form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCustomerItem = _resolveComponent("UserCustomerItem")!
  const _component_UserCustomerForm = _resolveComponent("UserCustomerForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userCustomerStore.userCustomers, (userCustomer) => {
      return (_openBlock(), _createElementBlock("div", {
        key: userCustomer.id,
        class: "user-customers-list__item"
      }, [
        _createVNode(_component_UserCustomerItem, {
          "is-selected": userCustomer.id === _ctx.selectedId,
          "user-customer": userCustomer,
          onSelect: ($event: any) => (_ctx.$emit('wasSelected', userCustomer)),
          onWasRemoved: _cache[0] || (_cache[0] = (id) => _ctx.$emit('wasRemoved', id))
        }, null, 8, ["is-selected", "user-customer", "onSelect"])
      ]))
    }), 128)),
    (_ctx.isFormOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_UserCustomerForm, {
            "user-customer": _ctx.newUserCustomer,
            "is-autocomplete": _ctx.isAutocomplete,
            "can-close": Boolean(_ctx.userCustomerStore.userCustomers.length),
            onWasCreated: _cache[1] || (_cache[1] = (uc) => _ctx.$emit('wasCreated', uc)),
            onWasRemoved: _cache[2] || (_cache[2] = (id) => _ctx.$emit('wasRemoved', id)),
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleForm(false)))
          }, null, 8, ["user-customer", "is-autocomplete", "can-close"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isFormOpened)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "user-customers-list__add-btn base-btn base-btn--default",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleForm(true)))
        }, " Добавить нового получателя "))
      : _createCommentVNode("", true)
  ]))
}