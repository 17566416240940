
import { defineComponent } from 'vue';

import { CARD_TYPE_TITLES } from '@/constants';

import { QUERY_PARAMS } from '@/types';

import IProduct from '@/models/product';
import IProductOption from '@/models/productOption';

import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';
import useOrder from '@/stores/order';
import useFastView from '@/stores/fastView';

import OrderWarning from '../../OrderWarning/OrderWarning.vue';

import OrderItem from './Item/OrderItem.vue';

import getQueryParams from '@/helpers/queryParams';
import pluralize from '@/helpers/pluralize';

export default defineComponent({
  name: 'FullOrder',

  components: {
    OrderItem,
    OrderWarning,
  },

  setup() {
    return {
      authStore: useAuth(),
      companyStore: useCompany(),
      orderStore: useOrder(),
      pluralize,
      fastViewStore: useFastView(),
      CARD_TYPE_TITLES,
    };
  },

  computed: {
    isVisibleDeliveryMessage(): boolean {
      return Boolean(this.authStore?.activeUser) &&
        this.orderStore.activeOrder?.deliveryTypeId !== 'self_pickup';
    },

    restaurantCount(): number {
      const restaurants = new Set();

      if (!this.orderStore.activeOrder?.items) {
        return 0;
      }

      this.orderStore.activeOrder.items.forEach(({ restaurant }) => {
        if (restaurant) {
          restaurants.add(restaurant.id);
        }
      });

      return restaurants.size;
    },
  },

  methods: {
    goToCart() {
      if (!this.authStore.activeUser) {
        this.authStore.toggleAuthWindow(true, 'c-cart');
      } else {
        this.$router.push({ name: 'c-cart' });
      }
    },

    openFastView(product: IProduct, option: IProductOption) {
      const query = getQueryParams(this.$route.query, {
        [QUERY_PARAMS.openedProductId]: product.id,
      });

      const params = { ...this.$route.params, isScrollDisabled: 'true' };

      this.$router.push({
        name: 'c-catalog',
        params,
        query,
      });

      this.fastViewStore.open({ product, option });
    },
  },
});
