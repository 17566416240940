
import { defineComponent } from 'vue';
import { random } from 'lodash';

const MAX_STEP = 6;
const START_POSITION = 15;
const TRANSITION_DURATION = 0.5;
const TRANSITION_PROPERTY = `width ${TRANSITION_DURATION}s`;

export default defineComponent({
  name: 'ProgressPreloader',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    timer: number,
    loaderStyle: {
      [x: number]: string,
      width?: string,
      transition?: string,
    },
    position: number,
    } {
    return {
      timer: 0,
      loaderStyle: {},
      position: 0,
    };
  },

  watch: {
    isLoading: {
      handler(value) {
        if (value) {
          this.start();
        } else {
          this.stop();
        }
      },
    },
  },

  mounted() {
    this.loaderStyle = document.getElementById('progress-preloader')?.style || {};
  },

  methods: {
    start() {
      this.loaderStyle.transition = TRANSITION_PROPERTY;
      this.position = START_POSITION;

      this.timer = setInterval(() => {
        if (this.position >= 100) {
          clearInterval(this.timer);
        }
        if (this.position < 60) {
          this.position += random(MAX_STEP);
        } else if (this.position < 75) {
          this.position += random(MAX_STEP / 2);
        } else if (this.position <= 100) {
          this.position += random(MAX_STEP / 3);
        }

        this.loaderStyle.width = `${this.position}%`;
      }, 200);
    },

    stop() {
      clearInterval(this.timer);

      this.loaderStyle.width = '100%';

      setTimeout(() => {
        this.loaderStyle.transition = '';
        this.loaderStyle.width = '0%';
      }, TRANSITION_DURATION * 1000);
    },
  },
});
