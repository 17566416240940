
import { defineComponent } from 'vue';
import vClickOutside from 'click-outside-vue3';

export default defineComponent({
  name: 'SearchPanel',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    searchValue: {
      type: String,
      default: '',
    },
  },

  emits: ['input', 'search'],

  data() {
    return {
      value: '',
      isOpenPanel: false,
    };
  },

  watch: {
    searchValue: {
      handler(value) {
        if (this.value) {
          return;
        }

        this.value = value;
      },
    },
  },

  methods: {
    togglePanel(state = false) {
      this.isOpenPanel = state;
    },
  },
});
