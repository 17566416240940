
import { defineComponent, PropType, onBeforeUpdate } from 'vue';

import { QUERY_PARAMS } from '@/types';

import IOrderItem from '@/models/orderItem';
import IProduct from '@/models/product';
import IProductOption from '@/models/productOption';

import useFastView from '@/stores/fastView';

export default defineComponent({
  name: 'OrderItemProductList',

  props: {
    items: {
      type: Array as PropType<IOrderItem[]>,
      required: true,
    },
    windowWidth: {
      type: Number,
      required: true,
    },
  },

  setup() {
    let itemsRefs: HTMLElement[] = [];

    const setItemRef = (el: HTMLElement) => {
      itemsRefs.push(el);
    };

    onBeforeUpdate(() => {
      itemsRefs = [];
    });

    return {
      itemsRefs,
      setItemRef,
      fastViewStore: useFastView(),
    };
  },

  data(): {
      isFullOrder: boolean,
      isToggler: boolean,
      yCordFirstLine: number,
      selectedOrderItem?: IOrderItem,
      } {
    return {
      isFullOrder: false,
      isToggler: false,
      yCordFirstLine: 0,
      selectedOrderItem: undefined,
    };
  },

  computed: {
    togglerTitle(): string {
      return this.isFullOrder
        ? 'Свернуть'
        : 'Весь заказ';
    },
  },

  watch: {
    windowWidth: {
      handler() {
        this.setToggler();
      },
    },
  },

  mounted() {
    this.setToggler();
  },

  methods: {
    setToggler() {
      this.yCordFirstLine = this.itemsRefs[0]?.getBoundingClientRect().y;

      this.isToggler = this.itemsRefs.some(
        (item: HTMLElement) => item.getBoundingClientRect().y !== this.yCordFirstLine,
      );
    },

    toggleFullnessOrder() {
      this.isFullOrder = !this.isFullOrder;
    },

    openFastView(product: IProduct, option: IProductOption, withNotify: boolean) {
      const query = { ...this.$route.query, [QUERY_PARAMS.openedProductId]: product.id };
      const params = { ...this.$route.params, isScrollDisabled: 'true' };

      this.$router.push({
        params,
        query,
      });

      this.fastViewStore.open({ product, option }, withNotify);
    },
  },
});
