import jsonApi from '@/libs/jsonapi';

type IDeliveryZone = {
  name: string;
  deliveryPrice: string;
  minOrderPrice: string;
  deliveryTimeMin: number;
  deliveryTimeMax: number;
  color: string;
  coordinates: number[][];
};

jsonApi.define<IDeliveryZone>('delivery_zone', {
  name: '',
  deliveryPrice: '',
  minOrderPrice: '',
  deliveryTimeMin: 0,
  deliveryTimeMax: 0,
  color: '',
  coordinates: [],
});

export { IDeliveryZone as default };
