import jsonApi from '@/libs/jsonapi';
import IDeliveryZone from './deliveryZone';

type IDeliveryAddress = {
  id: string,
  name: string,
  city: string,
  street: string,
  building: string,
  flat?: string,
  entrance?: string,
  floor?: string,
  isDeliveryAvailable: boolean,
  isIntercomAvailable: boolean,
  isDefault: boolean,
  fullName: string,
  geozoneId?: string,

  deliveryZone?: IDeliveryZone,
}

jsonApi.define<IDeliveryAddress>(
  'company_user_delivery_address',
  {
    flat: '',
    floor: '',
    entrance: '',
    city: '',
    street: '',
    building: '',
    name: '',
    isDefault: false,
    isDeliveryAvailable: true,
    isIntercomAvailable: false,
    fullName: '',
    geozoneId: '',

    deliveryZone: {
      jsonApi: 'hasOne',
      type: 'delivery_zone',
    },
  },
  {
    collectionPath: 'user/delivery-addresses',
  },
);

export default IDeliveryAddress;
