
import { defineComponent } from 'vue';

import useDeliveryZone from '@/stores/deliveryZone';

import CustomYandexMap from '@/components/company/DeliveryZones/CustomYandexMap/CustomYandexMap.vue';
import Info from '@/components/company/DeliveryZones/Info/Info.vue';

export default defineComponent({
  components: {
    Info,
    CustomYandexMap,
  },

  setup() {
    const deliveryZoneStore = useDeliveryZone();

    deliveryZoneStore.fetchDeliveryZones();

    return {
      deliveryZoneStore,
    };
  },

  data(): {
    selectedZoneId: string | null,
    } {
    return {
      selectedZoneId: null,
    };
  },

  methods: {
    selectZone(id: string) {
      this.selectedZoneId = id;
    },
  },
});
