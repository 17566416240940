<template>
  <div class="navigation-panel">
    <div class="navigation-panel__order-count order-count">
      <div class="order-count__number-box">
        <span class="order-count__number">
          {{ orderStore.totalItemsQuantity }}
        </span>
      </div>

      <div class="order-count__title">
        {{
          pluralize(orderStore.totalItemsQuantity, [
            'товар',
            'товара',
            'товаров',
          ])
        }}
        <br />
        в корзине
      </div>
    </div>

    <ul class="navigation-panel__list navigation-panel-list">
      <li>
        <router-link :to="{ name: 'c-cart' }" class="menu-card">
          <div
            class="
              menu-card__icon-box
              menu-card-icon-box menu-card-icon-box--rounded-corners
            "
          >
            <div class="menu-card-icon-box__icon">
              <div class="icon icon--cart" />
            </div>

            <div
              v-if="orderStore.totalItemsQuantity"
              class="menu-card-icon-box__number"
            >
              {{ orderStore.totalItemsQuantity }}
            </div>
          </div>

          <div class="menu-card__title">
            Корзина
          </div>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'c-orders-in-delivery' }"
          :class="['menu-card', {
            'router-link-active': $route.name === 'c-orders-in-delivery-payment',
          }]"
        >
          <div
            class="
              menu-card__icon-box
              menu-card-icon-box menu-card-icon-box--smoothed-corners
            "
          >
            <div class="menu-card-icon-box__icon">
              <div class="icon icon--car" />
            </div>
          </div>

          <div class="menu-card__title">
            Доставка
          </div>
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: 'c-orders-history' }" class="menu-card">
          <div
            class="
              menu-card__icon-box
              menu-card-icon-box menu-card-icon-box--smoothed-corners
            "
          >
            <div class="menu-card-icon-box__icon">
              <div class="icon icon--pen" />
            </div>
          </div>

          <div class="menu-card__title">
            История покупок
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import useOrder from '@/stores/order';

import pluralize from '@/helpers/pluralize';

export default defineComponent({
  setup() {
    return {
      orderStore: useOrder(),
      pluralize,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.navigation-panel {
  display: flex;
  justify-content: center;

  &,
  * {
    box-sizing: border-box;
  }

  .order-count,
  .menu-card {
    width: 56px;
  }

  .order-count {
    font-family: Tahoma;
    opacity: 0.5;

    &__number-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      margin-bottom: 7px;
    }

    &__number {
      font-size: 30px;
      -webkit-text-stroke: 2px black;
      -webkit-text-fill-color: white;
    }

    &__title {
      text-align: center;
      font-size: 12px;
      color: black;
    }
  }

  .navigation-panel-list {
    display: flex;
    list-style: none;
  }

  .menu-card {
    height: 100%;
    text-decoration: none;
    color: black;
    padding-bottom: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.08s ease-in;

    &--disabled {
      pointer-events: none;
      opacity: 0.1;
    }

    &:hover,
    &.router-link-active {
      opacity: 1;
    }

    &.router-link-active {
      &:before {
        width: 100%;
        height: 1px;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        background-color: $light-black;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - 6px);
        bottom: 1px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent $light-black transparent;
      }
    }

    &__title {
      font-size: 12px;
      text-align: center;
      font-family: Tahoma;
      flex: 1;
    }
  }

  .menu-card-icon-box {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 7px;
    border: 1px solid $hard-grey;

    &--smoothed-corners {
      border-radius: 6px;
    }

    &--rounded-corners {
      border-radius: 50%;
    }

    &__number {
      position: absolute;
      right: -10px;
      top: -10px;
      min-height: 25px;
      min-width: 25px;
      display: flex;
      flex: 1 0 25px;
      align-items: center;
      justify-content: center;
      border: 1px solid $hard-grey;
      border-radius: 1000px;
      font-size: 14px;
      font-family: Tahoma;
      background-color: #fff;
      padding: 4px 9px;
    }
  }

  .icon {
    display: flex;

    &--car {
      background-image: url('./images/car.svg');
      width: 34px;
      height: 32px;
    }

    &--cart {
      background-image: url('./images/cart.svg');
      width: 20px;
      height: 17px;
    }

    &--pen {
      background-image: url('./images/pen.svg');
      width: 27px;
      height: 26px;
    }
  }
}

@media (min-width: 481px) {
  .order-count,
  .menu-card {
    margin: 0 16px;
  }
}

@media (max-width: 480px) {
  .navigation-panel {
    .order-count,
    .menu-card {
      margin: 0 7px;
    }

    .menu-card {
      padding-bottom: 8px;
    }

    .menu-card-icon-box {
      &__number {
        right: -8px;
        top: -8px;
      }
    }
  }
}
</style>
