import { RouteRecordRaw } from 'vue-router';

import { SCOPE_TYPE } from '@/types';

import CompanyRootView from '@/views/Company/Root.vue';
import CatalogView from '@/views/Company/Catalog/Catalog.vue';
import UserView from '@/views/Company/User/User.vue';
import OrdersView from '@/views/Company/User/Orders/Orders.vue';
import CartView from '@/views/Company/User/Orders/Cart/Cart.vue';
import ProfileView from '@/views/Company/User/Profile/Profile.vue';
import LoyaltyView from '@/views/Company/User/Loyalty/Loyalty.vue';
import ErrorPaymentView from '@/views/Company/User/Orders/Order/ErrorPayment.vue';
import SuccessPaymentView from '@/views/Company/User/Orders/Order/SuccessPayment.vue';
import OrdersInDeliveryView from '@/views/Company/User/Orders/InDelivery/InDelivery.vue';
import PaymentInDeliveryView from '@/views/Company/User/Orders/InDelivery/Payment/Payment.vue';
import OrdersHistoryView from '@/views/Company/User/Orders/History/History.vue';
import CustomPage from '@/views/Company/CustomPage/CustomPage.vue';
import InfoView from '@/views/Company/Info/Info.vue';
import DeliveryZones from '@/views/Company/Info/DeliveryZones/DeliveryZones.vue';
import NotFound from '@/components/shared/ErrorPages/NotFound/NotFound.vue';

const CompanyRoutes: Array<RouteRecordRaw> = [
  {
    path: '/c/',
    name: 'company',
    meta: {
      scope: SCOPE_TYPE.COMPANY,
      fetch: {
        user: true,
        parentCompany: true,
        order: true,
      },
    },
    component: CompanyRootView,

    redirect: () => ({
      name: 'c-catalog',
    }),

    children: [
      // Каталог
      {
        name: 'c-catalog',
        path: 'catalog',
        component: CatalogView,
        meta: {
          title: 'Каталог',
        },
      },

      {
        name: 'c-user',
        path: 'user',
        component: UserView,
        meta: {
          requireAuth: true,
        },

        children: [
          {
            name: 'c-orders',
            path: 'orders',
            component: OrdersView,

            redirect: () => ({
              name: 'c-cart',
            }),

            children: [
              {
                name: 'c-cart',
                path: 'cart',
                component: CartView,
                meta: {
                  title: 'Корзина',
                },
              },

              {
                name: 'c-orders-in-delivery',
                path: 'in-delivery',
                component: OrdersInDeliveryView,
                meta: {
                  title: 'Доставка',
                },
              },

              {
                name: 'c-orders-in-delivery-payment',
                path: ':orderId/payment',
                component: PaymentInDeliveryView,
                props: true,
              },

              {
                name: 'c-orders-history',
                path: 'history',
                component: OrdersHistoryView,
                meta: {
                  title: 'История',
                },
              },

              {
                name: 'c-success-payment',
                path: ':orderId/success',
                props: true,
                component: SuccessPaymentView,
              },

              {
                name: 'c-error-payment',
                path: ':orderId/error',
                props: true,
                component: ErrorPaymentView,
              },
            ],
          },

          {
            name: 'c-profile',
            path: 'profile',
            component: ProfileView,
          },

          {
            name: 'c-loyalty',
            path: 'loyalty',
            component: LoyaltyView,
          },
        ],
      },

      {
        name: 'c-custom-pages',
        path: 'custom-pages/:customPage',
        component: CustomPage,
      },

      {
        name: 'c-info',
        path: '',
        component: InfoView,
        children: [
          {
            name: 'c-delivery-zones',
            path: 'delivery-zones',
            component: DeliveryZones,
          },
        ],
      },
    ],
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    meta: {
      scope: SCOPE_TYPE.COMPANY,
    },
    component: NotFound,
  },
];

export default CompanyRoutes;
