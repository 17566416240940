import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14ceeff5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "parent-company-header" }
const _hoisted_2 = { class: "parent-company-header__companies-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompaniesDropDown = _resolveComponent("CompaniesDropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.companyStore.activeCompany)
        ? (_openBlock(), _createBlock(_component_CompaniesDropDown, {
            key: 0,
            "selected-company-id": _ctx.companyStore.activeCompany.id,
            "parent-company": _ctx.companyStore.activeCompany
          }, null, 8, ["selected-company-id", "parent-company"]))
        : _createCommentVNode("", true)
    ])
  ]))
}