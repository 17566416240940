const monthsTitles = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

export type IDayDate = {
  title: string;
  date: Date;
  workShedule?: number[];
}

export function getArrayDatesWithTitle(startDate: Date, endDate: Date): IDayDate[] {
  const dates = [];

  for (
    const date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push({
      title: `${date.getDate()} ${monthsTitles[date.getMonth()]}`,
      date: new Date(date),
    });
  }

  return dates;
}

export function daysInMonth(month: number): number {
  return new Date((new Date()).getFullYear(), month, 0).getDate();
}

// Метод возвращает текущий номер дня недели, где 0 - Пн ... 6 - Вс
export function getDay(date: Date): number {
  const dayOfWeek = date.getDay();

  return dayOfWeek
    ? dayOfWeek - 1
    : 6;
}

// Метод ищет заданное количество рабочих дней за определенное количество дней
export function getWorkingDaysWithTitle(
  daysCount: number,
  workShedule: number[][],
  startDate: Date,
  range?: number,
): IDayDate[] {
  const workedDays = [];
  const currentDay = getDay(startDate);
  const searchRange = range || 21;

  const getNextDate = (date: Date) => new Date(date.setDate(date.getDate() + 1));
  const getDateTitle = (date: Date) => `${date.getDate()} ${monthsTitles[date.getMonth()]}`;

  for (
    let weekCount = 0, day = currentDay, date = startDate;
    day < currentDay + searchRange;
    day += 1, date = getNextDate(date), weekCount = Math.trunc(day / 7)
  ) {
    const shedule = workShedule[day - 7 * weekCount];

    if (shedule[0]) {
      workedDays.push({
        date: new Date(date),
        title: getDateTitle(date),
        workShedule: shedule,
      });
    }

    if (workedDays.length === daysCount) {
      break;
    }
  }

  return workedDays;
}
