import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f1ba807"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "products-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InteractiveProductItem = _resolveComponent("InteractiveProductItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
      return (_openBlock(), _createBlock(_component_InteractiveProductItem, {
        key: product.id,
        product: product,
        onAddToOrder: (option) => _ctx.addProductToOrder(product, option),
        onToggleFavorite: ($event: any) => (_ctx.$emit('toggleFavorite', product)),
        onToggleFastView: (option) => _ctx.openFastView(product, option),
        onIncreaseQuantity: _cache[0] || (_cache[0] = 
        (option) => _ctx.increaseOrderItemQuantity(
          _ctx.orderStore.orderItemsIdsByOptionsIds[option.id]
        )
      ),
        onDecreaseQuantity: _cache[1] || (_cache[1] = 
        (option) => _ctx.decreaseOrderItemQuantity(
          _ctx.orderStore.orderItemsIdsByOptionsIds[option.id]
        )
      )
      }, null, 8, ["product", "onAddToOrder", "onToggleFavorite", "onToggleFastView"]))
    }), 128))
  ]))
}