import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b17b977"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "parent-company-root-view" }
const _hoisted_2 = { class: "parent-company-root-view__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParentCompanyHeader = _resolveComponent("ParentCompanyHeader")!
  const _component_Header = _resolveComponent("Header")!
  const _component_HeaderDivider = _resolveComponent("HeaderDivider")!
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_Cover = _resolveComponent("Cover")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Cover, null, {
      default: _withCtx(() => [
        _createVNode(_component_Header, {
          "home-route": { name: 'parent-company' },
          "logo-url": _ctx.companyStore.activeCompany?.logoUrl,
          title: _ctx.companyStore.activeCompany?.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ParentCompanyHeader)
          ]),
          _: 1
        }, 8, ["logo-url", "title"]),
        _createVNode(_component_HeaderDivider),
        _createVNode(_component_MainMenu, { items: _ctx.menuItems }, null, 8, ["items"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer)
  ]))
}