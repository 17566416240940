
import { defineComponent } from 'vue';

import useOrder from '@/stores/order';

import EmptyOrder from './EmptyOrder/EmptyOrder.vue';
import FullOrder from './FullOrder/FullOrder.vue';

export default defineComponent({
  components: {
    EmptyOrder,
    FullOrder,
  },

  setup() {
    return {
      orderStore: useOrder(),
    };
  },
});
