export default function setFocusAtEnd(elem: {[x: string]: any}): void {
  const elemLen = elem.value.length;

  if (elem.selectionStart || elem.selectionStart === 0) {
    /* eslint-disable no-param-reassign */

    elem.selectionStart = elemLen + 1;
    elem.selectionEnd = elemLen + 1;

    /* eslint-enable no-param-reassign */

    elem.focus();
  }
}
