
import { defineComponent, PropType } from 'vue';

interface ILevelItem {
  percent: string;
  isActive: boolean;
}

export default defineComponent({
  name: 'LevelsList',

  props: {
    items: {
      type: Array as PropType<ILevelItem[]>,
    },
  },
});
