/* eslint-disable import/prefer-default-export */
const COPYRIGHT_START_YEAR = 2021;

const REG_EXP_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

enum CATALOG_MENU_ITEM_TYPE {
  kinds = 'kinds',
  groups = 'groups',
  kitchens = 'kitchens',
}

const CATALOG_MENU_ITEM_TITLE: {
  [value in CATALOG_MENU_ITEM_TYPE]: string
} = {
  kinds: 'Цены и особенности',
  kitchens: 'Выбор кухни',
  groups: 'Виды блюд',
};

const DAYS_OF_THE_WEEK = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

const MOBILE_BREAKPOINT = 480;
const TABLET_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1268;

const VERSION_WARNING_MESSAGE = 'Версия сайта была обновлена. Пожалуйста, обновите страницу!';

const INFO_PAGES = [
  {
    id: 'delivery_zones',
    title: 'Зоны доставки',
    route: 'c-delivery-zones',
  },
];

const NONBREAKING_SPACE = '\u00A0';
const CATALOG_PAGE = {
  id: 'catalog',
  name: `Блюда и${NONBREAKING_SPACE}товары`,
  route: 'c-catalog',
};

const DELIVERY_ZONE_OPACITY = {
  selected: 0.8,
  unselected: 0.5,
};

const CARD_TYPE_TITLES = {
  mo_card: 'Cкидка по Мо.Карте',
  personal_guest: 'Cкидка по КПГ',
};

const INVALID_ORDER_ERRORS = {
  codes: ['1015', '1024'],
  messages: {
    1015: 'У одной или нескольких позиций'
      + ' в вашей корзине изменилась стоимость. Пожалуйста,'
      + ' обновите страницу и повторите оформление заказа.',
    1024: 'Цены товаров в корзине отличаются от цен товаров в каталоге.',
  },
};

const PAYMENT_ERRORS = {
  timeOut: 'Срок оплаты истек',
  serverError: 'Произошла ошибка при попытке оплаты, пожалуйста, попробуйте снова',
};

export {
  INFO_PAGES,
  CATALOG_PAGE,
  COPYRIGHT_START_YEAR,
  CATALOG_MENU_ITEM_TYPE,
  CATALOG_MENU_ITEM_TITLE,
  DAYS_OF_THE_WEEK,
  MOBILE_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  VERSION_WARNING_MESSAGE,
  DELIVERY_ZONE_OPACITY,
  CARD_TYPE_TITLES,
  INVALID_ORDER_ERRORS,
  PAYMENT_ERRORS,
  REG_EXP_EMAIL,
};
