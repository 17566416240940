
/* eslint-disable camelcase */
import { defineComponent } from 'vue';
import { LocationQueryRaw, LocationQuery } from 'vue-router';
import {
  remove,
  debounce,
  find,
  head,
} from 'lodash';
import { MoSearchField } from '@mo/uikit';

import {
  CATALOG_MENU_ITEM_TYPE,
  CATALOG_MENU_ITEM_TITLE,
} from '@/constants';

import { ICatalogMenuItem, QUERY_PARAMS } from '@/types';

import IProduct, { TProductSort } from '@/models/product';
import { IProductGroupsQueryParam } from '@/models/group';
import IKind from '@/models/kind';
import IKitchen from '@/models/kitchen';

import { useCatalog } from '@/stores/catalog';
import useOrder from '@/stores/order';
import useCompany from '@/stores/company';
import useAuth from '@/stores/auth';
import useFavorite from '@/stores/favorite';

import RestaurantsDropDown from '@/components/shared/RestaurantsDropDown/RestaurantsDropDown.vue';
import getQueryParams from '@/helpers/queryParams';
import Cover from '@/components/shared/Cover/Cover.vue';
import MobileOrderPreviewLayout from '@/components/company/Order/PreviewLayout/Mobile/PreviewLayout.vue';
import DesktopOrderPreviewLayout from '@/components/company/Order/PreviewLayout/Desktop/PreviewLayout.vue';
import Pagination from '@/components/shared/Pagination/Pagination.vue';
import ProductTagsMenu from '@/components/company/ProductTagsMenu/ProductTagsMenu.vue';
import FiltersList from '@/components/company/Filters/FiltersList/FiltersList.vue';
import KitchensList from '@/components/company/Filters/KitchensList/KitchensList.vue';
import ProductList from '@/components/shared/Products/ProductsList/ProductsList.vue';
import SortingPanel from '@/components/shared/SortingPanel/SortingPanel.vue';
import MainMenu from '@/components/shared/MainMenu/MainMenu.vue';
import Banners from '@/components/shared/Banners/Banners.vue';
import SearchPanel from '@/components/shared/SearchPanel/SearchPanel.vue';

enum TAG_TYPE {
  kinds = 'filters',
  groups = 'product_groups',
  kitchens = 'kitchens',
}

interface ISort {
  id: string;
  name: string;
}

const SORTS = [
  {
    title: 'По популярности',
  },
  {
    title: 'Сначала дешевые',
    value: 'price',
  },
  {
    title: 'Сначала дорогие',
    value: '-price',
  },
];
const MENU_ITEMS_ORDERS: {[x: string]: number} = {
  groups: 1,
  kitchens: 2,
  kinds: 3,
};

export default defineComponent({
  name: 'CatalogView',

  components: {
    DesktopOrderPreviewLayout,
    Pagination,
    FiltersList,
    KitchensList,
    ProductList,
    MobileOrderPreviewLayout,
    ProductTagsMenu,
    Cover,
    Banners,
    MainMenu,
    MoSearchField,
    SortingPanel,
    SearchPanel,
    RestaurantsDropDown,
  },

  setup() {
    const orderStore = useOrder();

    orderStore.getDeliveryTypes();

    return {
      catalogStore: useCatalog(),
      favoriteStore: useFavorite(),
      companyStore: useCompany(),
      authStore: useAuth(),
      orderStore,
      TAG_TYPE,
      SORTS,
      CATALOG_MENU_ITEM_TYPE,
      CATALOG_MENU_ITEM_TITLE,
    };
  },

  data(): {
    isMounted: boolean;
    searchValue: string;
    menuItems: ICatalogMenuItem[];
    debouncedSearchProduct: () => void;
    selectedProduct?: IProduct,
    } {
    return {
      isMounted: false,
      searchValue: '',
      menuItems: [
        {
          type: CATALOG_MENU_ITEM_TYPE.groups,
          title: CATALOG_MENU_ITEM_TITLE[CATALOG_MENU_ITEM_TYPE.groups],
          action: 'selectItem',
          isActive: true,
        },
      ],
      debouncedSearchProduct: debounce(this.searchProduct as () => void, 400),
      selectedProduct: undefined,
    };
  },

  computed: {
    currentFilter(): {[x: string]: any} {
      if (this.catalogStore.activeMenuItemType === CATALOG_MENU_ITEM_TYPE.groups) {
        return ({
          list: this.catalogStore.groups,
          selectedList: this.catalogStore.selectedGroupsIds,
          select: this.selectGroup,
        });
      }

      if (this.catalogStore.activeMenuItemType === CATALOG_MENU_ITEM_TYPE.kitchens) {
        return ({
          isKitchen: true,
          list: this.catalogStore.kitchens,
          selectedList: this.catalogStore.selectedKitchensIds,
          select: this.selectKitchen,
        });
      }

      if (this.catalogStore.activeMenuItemType === CATALOG_MENU_ITEM_TYPE.kinds) {
        return ({
          list: this.catalogStore.kinds,
          selectedList: this.catalogStore.selectedKindsIds,
          select: this.selectKind,
        });
      }

      return {};
    },

    selectedProductGroups(): ISort[] {
      const selectedGroupsId = this.catalogStore.selectedGroupsIds.map(({ id }) => id);

      const groups = this.getGroupsFiltersById(
        selectedGroupsId,
        this.catalogStore.groups,
      );

      return groups.sort(
        (firstGroup, secondGroup) => (
          +firstGroup.id >= +secondGroup.id
            ? 1
            : -1
        ),
      );
    },

    selectedKinds(): IKind[] {
      return this.getGroupsFiltersById(
        this.catalogStore.selectedKindsIds,
        this.catalogStore.kinds,
      );
    },

    selectedKitchens(): IKind[] {
      return this.getGroupsFiltersById(
        this.catalogStore.selectedKitchensIds,
        this.catalogStore.kitchens,
      );
    },

    isOpenTagsMenu(): boolean {
      return (
        this.catalogStore.haveSelectedItems || this.catalogStore.isMenuPanelOpened
      );
    },
  },

  watch: {
    '$route.query': {
      handler(query: LocationQuery) {
        this.catalogStore.deserializeQueryParams(query);
        this.catalogStore.fetch(query);
      },
      immediate: true,
    },

    'catalogStore.kinds': {
      handler(items: IKind[]) {
        this.filtersHandler(items, CATALOG_MENU_ITEM_TYPE.kinds);
      },
      immediate: true,
    },

    'catalogStore.kitchens': {
      handler(items: IKitchen[]) {
        this.filtersHandler(items, CATALOG_MENU_ITEM_TYPE.kitchens);
      },
      immediate: true,
    },

    'menuItems.length': {
      handler() {
        this.menuItems.sort((a, b) => {
          if (!a.type || !b.type) {
            return -1;
          }

          return MENU_ITEMS_ORDERS[a.type] - MENU_ITEMS_ORDERS[b.type];
        });
      },
      immediate: true,
    },
  },

  mounted() {
    this.isMounted = true;

    if (this.$route.query.name) {
      this.searchValue = this.$route.query.name as string;
    }

    this.catalogStore.selectMenuItem(CATALOG_MENU_ITEM_TYPE.groups);
    this.catalogStore.openMenuPanel();
  },

  methods: {
    filtersHandler(
      items: IKitchen[] | IKind[],
      itemsType: CATALOG_MENU_ITEM_TYPE.kitchens | CATALOG_MENU_ITEM_TYPE.kinds,
    ) {
      const indexMenuItem = this.menuItems.findIndex(({ type }) => type === itemsType);
      const isIncluded = indexMenuItem !== -1;

      if (isIncluded && !items.length) {
        this.menuItems.splice(indexMenuItem, 1);
      }

      if (!isIncluded && items.length) {
        this.menuItems.push({
          type: itemsType,
          title: CATALOG_MENU_ITEM_TITLE[itemsType],
          action: 'selectItem',
          isActive: false,
        });
      }
    },

    toggleFavorite(product: IProduct) {
      if (!this.authStore.activeUser) {
        this.authStore.toggleAuthWindow(true);

        return;
      }

      this.favoriteStore.toggleFavoriteProduct(product.id);
    },

    selectGroup(groupId: string) {
      const productGroups:
        IProductGroupsQueryParam[] = this.catalogStore.selectedGroupsIds;

      const groupIndex = productGroups.findIndex(({ id }) => id === groupId);

      if (groupIndex !== -1) {
        productGroups.splice(groupIndex, 1);
      } else {
        productGroups.unshift({
          id: groupId,
        });

        this.catalogStore.toggleRolledTagMenu();
      }

      this.pushGroupsQuery(productGroups);
    },

    selectSubGroups(groupId: string, filterId: string) {
      const productGroups:
        IProductGroupsQueryParam[] = this.catalogStore.selectedGroupsIds;
      const productGroupFilters = productGroups.find(({ id }) => id === groupId);

      if (!productGroupFilters) {
        return;
      }

      if (productGroupFilters.filter_ids?.includes(filterId)) {
        remove(productGroupFilters.filter_ids, (id) => id === filterId);

        if (!productGroupFilters.filter_ids.length) {
          productGroupFilters.filter_ids = undefined;
        }
      } else {
        productGroupFilters.filter_ids = [
          ...(productGroupFilters.filter_ids || []),
          filterId,
        ];
      }

      const groupIndex = productGroups.findIndex(({ id }) => id === groupId);

      productGroups.splice(groupIndex, 1);
      productGroups.unshift(productGroupFilters);

      this.pushGroupsQuery(productGroups);
    },

    pushGroupsQuery(
      productGroups: IProductGroupsQueryParam[],
    ) {
      const query = getQueryParams(
        this.$route.query,
        {
          [QUERY_PARAMS.groupsIds]: productGroups.length ? JSON.stringify(productGroups) : '',
          [QUERY_PARAMS.page]: '',
          [QUERY_PARAMS.append]: '',
          [QUERY_PARAMS.isFavorite]: '',
        },
      );

      this.pushToRouter(query);
    },

    deselectTag(id: string, itemType: TAG_TYPE) {
      if (itemType === TAG_TYPE.groups) {
        this.selectGroup(id);
      }
      if (itemType === TAG_TYPE.kinds) {
        this.selectKind(id);
      }
      if (itemType === TAG_TYPE.kitchens) {
        this.selectKitchen(id);
      }
    },

    resetTags() {
      this.catalogStore.toggleRolledTagMenu(false);

      this.pushToRouter({
        name: this.$route.query.name as string,
        sort: this.$route.query.sort as string,
      });
    },

    toggleActiveMenuItem() {
      const selectedMenuItem = find(this.menuItems, 'isActive') || head(this.menuItems);

      if (selectedMenuItem) {
        this.selectItem(selectedMenuItem);
      }
    },

    selectKind(kindId: string) {
      const kindsIds = [...this.catalogStore.selectedKindsIds];

      if (kindsIds.includes(String(kindId))) {
        remove(kindsIds, (id) => id === String(kindId));
      } else {
        kindsIds.push(String(kindId));
      }

      const query = getQueryParams(
        this.$route.query,
        {
          [QUERY_PARAMS.kindsIds]: kindsIds.join(),
          [QUERY_PARAMS.page]: '',
          [QUERY_PARAMS.append]: '',
          [QUERY_PARAMS.isFavorite]: '',
        },
      );

      this.pushToRouter(query);
    },

    selectKitchen(kitchenId: string) {
      const kitchensIds = [...this.catalogStore.selectedKitchensIds];

      if (kitchensIds.includes(String(kitchenId))) {
        remove(kitchensIds, (id) => id === String(kitchenId));
      } else {
        kitchensIds.push(String(kitchenId));
      }

      const query = getQueryParams(
        this.$route.query,
        {
          [QUERY_PARAMS.kitchensIds]: kitchensIds.join(),
          [QUERY_PARAMS.page]: '',
          [QUERY_PARAMS.append]: '',
          [QUERY_PARAMS.isFavorite]: '',
        },
      );

      this.pushToRouter(query);
    },

    selectRestaurant(restaurantId: string | undefined) {
      if (restaurantId === this.$route.query.restaurant_id as string) {
        return;
      }

      const query = getQueryParams(
        {},
        {
          [QUERY_PARAMS.restaurantId]: restaurantId,
        },
      );

      this.pushToRouter(query);

      if (this.catalogStore.activeMenuItemType !== CATALOG_MENU_ITEM_TYPE.groups) {
        this.selectItem({ type: CATALOG_MENU_ITEM_TYPE.groups } as ICatalogMenuItem);
      }
    },

    getGroupsFiltersById(selectedValues: string[], allValues: ISort[]) {
      const sortedValues: ISort[] = [];

      if (!allValues.length || !selectedValues) {
        return sortedValues;
      }

      const valuesById = allValues.reduce(
        (acc: Record<string, ISort>, currentGroup: ISort) => {
          acc[currentGroup.id] = currentGroup;

          return acc;
        },
        {},
      );

      selectedValues.forEach((item) => {
        sortedValues.push(valuesById[item]);
      });

      return sortedValues;
    },

    closeFiltersPanel() {
      const menuItem = this.menuItems
        .find(({ type }) => type === this.catalogStore.activeMenuItemType);

      if (menuItem) {
        this.selectItem(menuItem);
      }
    },

    selectItem(item: ICatalogMenuItem) {
      this.menuItems.forEach((menuItem) => {
        // eslint-disable-next-line
        menuItem.isActive = menuItem.type === item.type && !menuItem.isActive;
      });

      if (
        this.catalogStore.isMenuPanelOpened
        && this.catalogStore.activeMenuItemType === item.type
      ) {
        this.catalogStore.closeMenuPanel();

        return;
      }

      if (item.type) {
        this.catalogStore.selectMenuItem(item.type);
      }

      this.catalogStore.openMenuPanel();
    },

    selectPage(props: { number: number; size: number, append: true }) {
      const query = getQueryParams(
        this.$route.query,
        {
          [QUERY_PARAMS.page]: props.number > 1 ? String(props.number) : '',
          [QUERY_PARAMS.append]: props.append ? String(props.append) : '',
        },
      );

      this.pushToRouter(query, !props.append);
    },

    searchProduct(searchValue: string) {
      const query = getQueryParams(
        this.$route.query,
        {
          [QUERY_PARAMS.name]: searchValue,
          [QUERY_PARAMS.page]: '',
          [QUERY_PARAMS.append]: '',
        },
      );

      this.pushToRouter(query);
    },

    selectSorting(sortField: TProductSort) {
      const query = getQueryParams(
        this.$route.query,
        {
          [QUERY_PARAMS.sort]: sortField,
          [QUERY_PARAMS.page]: '',
          [QUERY_PARAMS.append]: '',
        },
      );

      this.pushToRouter(query);
    },

    pushToRouter(query: LocationQueryRaw, withScroll?: boolean) {
      const params = { ...this.$route.params };

      if (!withScroll) {
        params.isScrollDisabled = 'true';
      }

      this.$router.push({
        name: 'c-catalog',
        params,
        query,
      });
    },
  },
});
