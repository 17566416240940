
import { defineComponent } from 'vue';

import useDeliveryAddress from '@/stores/deliveryAddress';

import DeliveryAddressItem from '../Item/Item.vue';
import DeliveryAddressForm from '../Form/Form.vue';

export default defineComponent({
  name: 'DeliveryAddressesList',

  components: {
    DeliveryAddressItem,
    DeliveryAddressForm,
  },

  props: {
    selectedId: {
      type: String,
      default: undefined,
    },
    isFormOpenedWhenEmpty: {
      type: Boolean,
      default: false,
    },
    withZones: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['wasUpdated', 'wasRemoved', 'wasSelected', 'wasLoaded'],

  data() {
    const deliveryAddressStore = useDeliveryAddress();

    const params: { include?: string; } = {};

    if (this.withZones) {
      params.include = 'delivery_zone';
    }

    deliveryAddressStore.getAll(params).then((addresses) => {
      if (this.isFormOpenedWhenEmpty && !addresses.length) {
        this.toggleForm(true);
      }

      this.$emit('wasLoaded', addresses);
    });

    return {
      deliveryAddressStore,
      newDeliveryAddress: deliveryAddressStore.getEmpty(),
      isFormOpened: false,
    };
  },

  methods: {
    toggleForm(state: boolean) {
      this.isFormOpened = state;
    },
  },
});
