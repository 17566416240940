
import { defineComponent } from 'vue';

import useCompany from '@/stores/company';

import CompaniesDropDown from '@/components/shared/Companies/CompaniesDropDown/CompaniesDropDown.vue';

export default defineComponent({
  name: 'ParentCompanyHeader',

  components: {
    CompaniesDropDown,
  },

  setup() {
    return {
      companyStore: useCompany(),
    };
  },

  data() {
    return {
      isProfileMenuOpen: false,
    };
  },
});
