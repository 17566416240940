
import { defineComponent, PropType } from 'vue';
import vClickOutside from 'click-outside-vue3';

import IRestaurant from '@/models/restaurant';

import { useCatalog } from '@/stores/catalog';

import DropDownMenu from '@/components/shared/DropDownMenu/DropDownMenu.vue';

const DEFAULT_TITLE = 'Бренды и рестораны союза';

interface IDropDownComponent {
  hide: () => void,
}

export default defineComponent({
  name: 'RestaurantsDropDown',

  components: {
    DropDownMenu,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    restaurants: {
      type: Array as PropType<IRestaurant[]>,
      default: () => ([]),
    },
    isImageTitle: {
      type: Boolean,
      default: false,
    },
    isFiltersOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['select'],

  setup() {
    return {
      catalogStore: useCatalog(),
    };
  },

  data(): {
    isOpened: boolean,
    DEFAULT_TITLE: string,
    } {
    return {
      isOpened: false,
      DEFAULT_TITLE,
    };
  },

  computed: {
    selectedRestaurant(): IRestaurant | undefined {
      return this.restaurants.find(({ id }) => id === this.catalogStore.selectedRestaurantId);
    },
  },

  methods: {
    toggle(state: boolean) {
      this.isOpened = state;
    },

    select(id: string) {
      (this.$refs.dropDownMenu as IDropDownComponent).hide();

      this.$emit('select', id);
    },
  },
});
