
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import { string } from 'yup';
import { VueRecaptcha } from 'vue3-recaptcha-v2';

import useCompany from '@/stores/company';

const ROUTE_BY_NAME: {
  [x: string]: string,
} = {
  'c-cart': '/c/user/orders/cart',
};

export default defineComponent({
  name: 'PhoneForm',

  components: {
    VueRecaptcha,
  },

  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    redirectRoute: {
      type: String,
      default: '',
    },
    captchaError: {
      type: String,
      default: '',
    },
    isVisibleCaptcha: {
      type: Boolean,
      default: false,
    },
    currentPhone: {
      type: String,
      default: '',
    },
  },

  emits: ['send', 'clearError'],

  setup() {
    const {
      validate,
      value: phone,
      meta,
    } = useField<string>(
      'phone',
      string()
        .required()
        .matches(/\+\d{11}/, 'Номер телефона должен содержать 11 цифр.'),
    );

    return {
      validate,
      phone,
      meta,
      mask: '+7 (###) ###-##-##',
      companyStore: useCompany(),
    };
  },

  data(): {
    captcha: string | null,
    currPhone: string,
    } {
    return {
      captcha: null,
      currPhone: this.currentPhone,
    };
  },

  computed: {
    moAuthUrl(): string {
      const backlink = this.redirectRoute
        ? `${window.location.origin}${ROUTE_BY_NAME[this.redirectRoute]}`
        : window.location.href;

      return `${window.location.origin}`
             + `/api/auth/${this.companyStore.activeCompany?.login}/oauth2/sign-in`
             + `?back_url=${backlink}`;
    },
  },

  methods: {
    send() {
      if (!this.meta.valid || (this.isVisibleCaptcha && !this.captcha)) {
        return;
      }

      this.$emit('send', this.phone, this.captcha);
    },

    callbackVerify(key: string) {
      this.captcha = key;
    },

    callbackExpired() {
      this.captcha = null;

      this.$emit('clearError');
    },
  },
});
