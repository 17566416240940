import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, createVNode as _createVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6270a8da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-page" }
const _hoisted_2 = ["srcdoc"]
const _hoisted_3 = {
  key: 1,
  class: "preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_MoPreloader = _resolveComponent("MoPreloader")!
  const _component_MainMenu = _resolveComponent("MainMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.customPageStore.currentPageTemplate)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 0,
                ref: "frame",
                class: "custom-page__frame",
                scrolling: "no",
                style: {"border":"0","width":"100%","height":"0%"},
                srcdoc: _ctx.customPageStore.currentPageTemplate,
                onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resizeFrameHeight && _ctx.resizeFrameHeight(...args)))
              }, null, 40, _hoisted_2))
            : (_openBlock(), _createBlock(_component_NotFound, { key: 1 }))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_MoPreloader, { "is-multiple": true })
        ])),
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 2,
          to: "#company-header-menu"
        }, [
          _createVNode(_component_MainMenu, {
            items: [_ctx.currentMenuItem]
          }, null, 8, ["items"])
        ]))
      : _createCommentVNode("", true)
  ]))
}