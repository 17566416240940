import jsonApi from '@/libs/jsonapi';

interface IUserCustomer {
  id: string,
  fullName: string,
  phone: string,
  isDefault: boolean,
}

jsonApi.define<IUserCustomer>(
  'user_customer',
  {
    fullName: '',
    phone: '',
    isDefault: false,
  },
  {
    collectionPath: 'user/customers',
  },
);

export default IUserCustomer;
