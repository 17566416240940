import axios from 'axios';
import { useToast } from 'vue-toastification';

const toast = useToast();
const instance = axios.create({
  baseURL: '/api',
});

instance.interceptors.response.use(
  ({ data }) => data,
  (payload) => {
    const status = payload?.response?.status;

    if (status === 500) {
      toast.error('Произошла ошибка. Обновите страницу и попробуйте снова');

      return Promise.reject(payload);
    }

    return Promise.reject(payload?.response?.data?.errors[0] || payload);
  },
);

export default instance;
