import { defineStore } from 'pinia';

import IKitchen from '@/models/kitchen';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';

const useKitchen = defineStore('kitchen', {
  actions: {
    async getAll(params: Partial<{
      filters: Partial<{
        'restaurant_id': string,
      }>,
    }> = {}): Promise<IKitchen[]> {
      const response = await jsonApi.one('company', useCompany().activeCompany!.login)
        .all('kitchen')
        .get<IKitchen[]>(params);

      return response.data;
    },
  },
});

export default useKitchen;
