
import { defineComponent } from 'vue';
import vClickOutside from 'click-outside-vue3';

import Cover from '@/components/shared/Cover/Cover.vue';

export default defineComponent({
  name: 'HeaderDropDownMenu',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    Cover,
  },

  emits: ['toggle', 'hide'],

  data(): {
    isOpened: boolean,
    } {
    return {
      isOpened: false,
    };
  },

  watch: {
    '$route.path': 'hide',
  },

  methods: {
    toggle() {
      this.isOpened = !this.isOpened;

      this.$emit('toggle', this.isOpened);
    },

    hide() {
      this.isOpened = false;

      this.$emit('hide');
    },
  },
});
