import IBonusLevel from './bonusLevel';

import jsonApi from '@/libs/jsonapi';

interface IBonusInfo {
  available: string,
  percent: string,
  balance: string,
  maxWriteOffPercent: string,
  spent: string,
  nextLevel: string,
  remainingToNext: string,

  levels: IBonusLevel[],
}

jsonApi.define<IBonusInfo>('bonus_info', {
  available: '',
  percent: '',
  balance: '',
  maxWriteOffPercent: '',
  spent: '',
  nextLevel: '',
  remainingToNext: '',

  levels: {
    jsonApi: 'hasMany',
    type: 'level',
  },
});

export { IBonusInfo as default };
