import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5285494c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product" }
const _hoisted_2 = { class: "product__preview-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "product__cart-count"
}
const _hoisted_5 = { class: "product__info" }
const _hoisted_6 = { class: "product__name" }
const _hoisted_7 = { class: "product__description" }
const _hoisted_8 = { class: "product__prices" }
const _hoisted_9 = { class: "current-price rouble-symbol" }
const _hoisted_10 = {
  key: 0,
  class: "old-price rouble-symbol"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _ctx.image }, null, 8, _hoisted_3),
      (_ctx.quantity)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.quantity), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.weight)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.weight) + " г ", 1)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.weight && _ctx.description)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(" - ")
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.$filters.replacedHtmlTags(_ctx.description)), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$filters.formattedNumber(_ctx.price)), 1),
        (_ctx.priceWithoutDiscount !== _ctx.price)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$filters.formattedNumber(_ctx.priceWithoutDiscount)), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}