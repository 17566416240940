
import { defineComponent, ref } from 'vue';
import { Dropdown } from 'v-tooltip';

import { DELIVERY_TYPE, DELIVERY_TITLES } from '@/models/deliveryType';
import { IOrder, ORDER_STATUS, ORDER_TITLE_STATUS } from '@/models/order';

import useOrder from '@/stores/order';

import EmptyLayout from '@/components/company/Order/EmptyLayout/EmptyLayout.vue';
import ProductItem from '@/components/shared/Products/ProductItem/ProductItem.vue';
import { formatDate } from '@/helpers/date';

export default defineComponent({
  name: 'OrdersInDeliveryView',

  components: {
    Dropdown,
    ProductItem,
    EmptyLayout,
  },

  setup() {
    const orderStore = useOrder();
    const orders = ref<IOrder[]>([]);
    const wasLoaded = ref<boolean>(false);

    orderStore
      .fetch({
        'filters[status]': [
          ORDER_STATUS.ACCEPTED,
          ORDER_STATUS.COOKING,
          ORDER_STATUS.IN_DELIVERY,
        ].join(','),
        include: 'items,items.product',
      })
      .then((rOrders: IOrder[]) => {
        orders.value = rOrders;
        wasLoaded.value = true;
      });

    return {
      orders,
      ORDER_STATUS,
      ORDER_TITLE_STATUS,
      DELIVERY_TYPE,
      DELIVERY_TITLES,
      wasLoaded,
    };
  },

  methods: {
    isAvailableForPayment(order: IOrder): boolean {
      return !order.isPaid && [
        ORDER_STATUS.ACCEPTED, ORDER_STATUS.COOKING, ORDER_STATUS.IN_DELIVERY,
      ].includes(order.status as ORDER_STATUS);
    },

    getOrderDate(order: IOrder) {
      const format = 'dd.MM.uuuu HH:mm';

      return formatDate(new Date(order.deliveryAt || ''), format).split(' ').join(
        DELIVERY_TYPE.COURIER === order.deliveryTypeId ? ' к ' : ' с ',
      );
    },
  },
});
