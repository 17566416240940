
import { defineComponent } from 'vue';

import { DOCUMENT_TYPE } from '@/models/legalDocument';

import useAuth from '@/stores/auth';
import useBonusTransaction from '@/stores/bonusTransaction';
import useCompany from '@/stores/company';

import LevelsList from '@/components/shared/LevelsList/LevelsList.vue';
import HistoryScores from '@/components/shared/HistoryScores/HistoryScores.vue';
import SortingPanel from '@/components/shared/SortingPanel/SortingPanel.vue';

const PAGE_SIZE = 5;
const FILTERS = [
  {
    title: 'Все',
  },
  {
    title: 'Начисление',
    value: 'accrual',
  },
  {
    title: 'Списание',
    value: 'debit',
  },
];

export default defineComponent({
  name: 'LoyaltyView',

  components: {
    LevelsList,
    HistoryScores,
    SortingPanel,
  },

  setup() {
    return {
      authStore: useAuth(),
      bonusTransactionStore: useBonusTransaction(),
      companyStore: useCompany(),
      DOCUMENT_TYPE,
    };
  },

  data() {
    return {
      FILTERS,
      historyScores: {},
      page: 1,
      selectedFilter: FILTERS[0].value,
    };
  },

  created() {
    this.bonusTransactionStore.getAll({
      page: {
        number: this.page,
        size: PAGE_SIZE,
      },
    });
  },

  methods: {
    showMore() {
      this.page += 1;

      this.bonusTransactionStore.getAll({
        page: {
          number: this.page,
          size: PAGE_SIZE,
        },

        filters: {
          type: this.selectedFilter,
        },
        append: true,
      });
    },

    selectFilter(filter: string | undefined) {
      this.page = 1;
      this.selectedFilter = filter;

      this.bonusTransactionStore.getAll({
        page: {
          number: this.page,
          size: PAGE_SIZE,
        },

        filters: {
          type: this.selectedFilter,
        },
      });
    },
  },
});
