
import { defineComponent, PropType } from 'vue';

import getFormatTime from '@/helpers/getFormatTime';
import inRange from '@/helpers/inRange';

export default defineComponent({
  name: 'OrderWarning',

  props: {
    workSchedule: {
      type: Array as PropType<[number, number][]>,
      required: true,
    },
    priceForDelivery: {
      type: String,
      default: '',
    },
    isDelayedOrder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const { isWorks, startTimeTitle } = this.setWorkWarningSettings();

    return {
      isWorks,
      startTimeTitle,
    };
  },

  methods: {
    setWorkWarningSettings(): {
      isWorks: boolean,
      startTimeTitle?: string,
      } {
      const startTimeTitle = '';
      const currentDate = new Date();
      const [startTime, endTime] = this.getTimes(currentDate);

      let isWorks = true;

      if (!startTime || !endTime) {
        return ({
          isWorks,
        });
      }

      const timezoneOffset = currentDate.getTimezoneOffset();

      const startTimeUTC = startTime - timezoneOffset;
      const endTimeUTC = endTime - timezoneOffset;

      const currentMinutesCount = currentDate.getHours() * 60 + currentDate.getMinutes();

      isWorks = inRange(currentMinutesCount, startTimeUTC, endTimeUTC);

      return {
        isWorks,
        startTimeTitle: isWorks
          ? startTimeTitle
          : getFormatTime(startTimeUTC),
      };
    },

    getTimes(currentDate: Date): number[] {
      let dayOfWeek = currentDate.getDay();

      // getDay возвращает число дня недели, где 0 - воскресенье и тд
      // Переопределяем день, чтобы было 0 - понедельник ... 6 - воскресенье
      dayOfWeek = dayOfWeek
        ? dayOfWeek - 1
        : 6;

      const workSchedule = this.workSchedule || [];

      if (!workSchedule.length || !workSchedule[dayOfWeek].length) {
        return [];
      }

      return ([
        workSchedule[dayOfWeek][0],
        workSchedule[dayOfWeek][1],
      ]);
    },
  },
});
