
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductItem',

  props: {
    image: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    weight: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      required: true,
    },
    priceWithoutDiscount: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
});
