
import { defineComponent, PropType } from 'vue';
import { debounce, isNumber } from 'lodash';
import VueSelect from 'vue-select';

import { PAYMENT_TYPE } from '@/models/paymentType';
import IOrderPlacementOptions from '@/models/orderPlacementOptions';

import useCompany from '@/stores/company';
import useOrder from '@/stores/order';

import Warning from '@/components/shared/Warning/Warning.vue';
import ModalDialog from '@/components/shared/ModalDialog/ModalDialog.vue';
import { getWorkingDaysWithTitle, getArrayDatesWithTitle, IDayDate } from '@/helpers/daysInMonth';
import { addDays } from '@/helpers/date';

// Отступ начала приема заказов от начала дня (Каждые полчаса - единица)
const OFFSET_FROM_START = 3;
const OFFSET_FROM_START_IN_COOKING_DAY = 1;
const INDEX_FOR_SAVE_LAST_VALUE = 1;
const PHONE_CONFIRM_MESSAGE = 'Вы указали адрес, по которому еще '
  + 'не производилась доставка. Оператору необходимо его подтвердить.';

function formatDate(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export default defineComponent({
  name: 'DeliverySettingsForm',

  components: {
    VueSelect,
    Warning,
    ModalDialog,
  },

  props: {
    notes: {
      type: String,
      default: '',
    },

    deliveryAt: {
      type: String,
      default: '',
    },

    personCount: {
      type: Number,
      default: 0,
    },

    confirmByPhone: {
      type: Boolean,
      default: false,
    },

    isContactless: {
      type: Boolean,
      default: false,
    },

    orderPlacementOptions: {
      type: Object as PropType<IOrderPlacementOptions>,
      required: true,
    },

    cookingTime: {
      type: Number,
      default: null,
    },
  },

  setup() {
    return {
      orderStore: useOrder(),
    };
  },

  data(): {
    isDeliveryAtOpen: boolean;
    daysRest: IDayDate[];
    cookingDate: Date;
    selectedDay: IDayDate;
    selectedTime: string;
    times: string[];
    localNotes: string;
    isOpenPhoneConfirmWarning: boolean;
    phoneConfirmWarningMessage: string;
    debouncedChangeNotes: () => void;
    } {
    const cookingDate = this.cookingTime
      ? addDays(new Date(), Math.ceil(this.cookingTime / 24))
      : new Date();

    const lastVisibleDay = addDays(new Date(cookingDate), 2);

    const companyStore = useCompany();

    const daysRest = companyStore.activeCompany?.workSchedule?.length
      ? getWorkingDaysWithTitle(3, companyStore.activeCompany?.workSchedule, new Date(cookingDate))
      : getArrayDatesWithTitle(new Date(cookingDate), lastVisibleDay);

    const times = this.generateTimes();

    const { start, end } = this.getTimePositions(daysRest[0], cookingDate);
    const selectedTime = times.slice(start, end)[0];

    return {
      cookingDate,
      times,
      daysRest,
      selectedTime,
      isDeliveryAtOpen: Boolean(this.deliveryAt) || false,
      selectedDay: daysRest[0],
      localNotes: this.notes,
      isOpenPhoneConfirmWarning: false,
      phoneConfirmWarningMessage: PHONE_CONFIRM_MESSAGE,
      debouncedChangeNotes: debounce(this.changeNotes as () => void, 500),
    };
  },

  computed: {
    workingTimes(): string[] {
      const { start, end } = this.getTimePositions(this.selectedDay, this.cookingDate);

      return this.times.slice(start, end);
    },
  },

  mounted() {
    if (this.deliveryAt) {
      const deliveryAtDate = new Date(this.deliveryAt);

      if (deliveryAtDate < new Date()) {
        this.setDeliveryAt();

        return;
      }

      const selectedDate = this.daysRest.find(
        ({ date }) => formatDate(date) === formatDate(deliveryAtDate),
      );

      if (selectedDate) {
        this.selectedDay = selectedDate;
      }

      this.selectedTime = this.times[
        deliveryAtDate.getHours() * 2 + deliveryAtDate.getMinutes() / 30
      ];
    }

    if (!this.deliveryAt && this.cookingTime) {
      this.toggleDeliveryAt();
    }
  },

  methods: {
    generateTimes(): string[] {
      const times = [];

      for (let h = 0; h <= 23; h += 1) {
        const hour = h < 10 ? `0${h}` : h.toString(10);

        times.push(`${hour}:00`);
        times.push(`${hour}:30`);
      }

      return times;
    },

    getStartCutPositions(date: Date, offset: number) {
      return date.getHours() * 2 + date.getMinutes() / 30 + offset;
    },

    getTimePositions(selectedDay: IDayDate, cookingDate: Date) {
      const todayDate = new Date();
      const isToday = formatDate(selectedDay.date) === formatDate(todayDate);
      const isCookingDay = formatDate(selectedDay.date) === formatDate(cookingDate);

      let endCutPosition;
      let startCutPosition = 0;

      if (isCookingDay || isToday) {
        startCutPosition = this.getStartCutPositions(
          todayDate,
          isToday ? OFFSET_FROM_START : OFFSET_FROM_START_IN_COOKING_DAY,
        );
      }

      if (selectedDay.workShedule) {
        const [start, end] = selectedDay.workShedule;
        const timezoneOffset = Math.abs(todayDate.getTimezoneOffset());

        const startWorkPosition = ((start + timezoneOffset) / 60) * 2 + OFFSET_FROM_START;

        endCutPosition = ((end + timezoneOffset) / 60) * 2 + INDEX_FOR_SAVE_LAST_VALUE;
        startCutPosition =
          startWorkPosition > startCutPosition ? startWorkPosition : startCutPosition;
      }

      return {
        start: startCutPosition,
        end: endCutPosition,
      };
    },

    toggleDeliveryAt() {
      this.isDeliveryAtOpen = !this.isDeliveryAtOpen;

      if (this.isDeliveryAtOpen) {
        this.setDeliveryAt();
      } else {
        this.orderStore.update({
          deliveryAt: null,
        });
      }
    },

    increasePersonCount() {
      if (isNumber(this.orderStore.activeOrder!.personCount)) {
        this.orderStore.update({
          personCount: this.orderStore.activeOrder!.personCount + 1,
        });
      }
    },

    decreasePersonCount() {
      let personCount = 1;

      if (isNumber(this.orderStore.activeOrder!.personCount)) {
        personCount = this.orderStore.activeOrder!.personCount - 1;
      }

      this.orderStore.update({
        personCount: personCount <= 1 ? 1 : personCount,
      });
    },

    toggleConfirmByPhone() {
      this.orderStore.update({
        confirmByPhone: !this.orderStore.activeOrder!.confirmByPhone,
      });
    },

    validateConfirmByPhone(event: Event) {
      if (!this.orderStore.isRequirePhoneConfirmation) {
        return;
      }

      this.isOpenPhoneConfirmWarning = true;

      event.preventDefault();
    },

    toggleIsContactless() {
      const { activeOrder, contactlessPaymentTypes } = this.orderStore;
      const isContactless = !activeOrder!.isContactless;
      const fields: {
        isContactless: boolean;
        paymentTypeId?: PAYMENT_TYPE;
      } = {
        isContactless,
      };

      // Если выбираем чекбокс "Мне нужна бесконтактная доставка",
      // но была выбрана оплата контактная (При получении), то сбрасываем оплату в бесконтактную
      if (
        isContactless
        && !contactlessPaymentTypes.find(
          (pt) => pt.paymentTypeId === activeOrder?.paymentTypeId,
        )
      ) {
        fields.paymentTypeId = contactlessPaymentTypes[0].paymentTypeId;
      }

      this.orderStore.update(fields);
    },

    setDate() {
      [this.selectedTime] = this.workingTimes;

      this.setDeliveryAt();
    },

    setDeliveryAt() {
      const selectedDatetime = new Date(this.selectedDay.date);
      const times = this.selectedTime?.split(':');

      if (!times) {
        return;
      }

      const [hours, minutes] = times;

      selectedDatetime.setHours(parseInt(hours, 10));
      selectedDatetime.setMinutes(parseInt(minutes, 10));
      selectedDatetime.setSeconds(0);
      selectedDatetime.setUTCMilliseconds(0);

      this.orderStore.update({
        deliveryAt: selectedDatetime.toISOString(),
      });
    },

    changeNotes() {
      this.orderStore.update({
        notes: this.localNotes,
      });
    },

    closePhoneConfirmModal() {
      this.isOpenPhoneConfirmWarning = false;
    },
  },
});
