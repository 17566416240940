
import { defineComponent, PropType } from 'vue';

import IBonusTransaction, { isIncreaseType } from '@/models/bonusTransaction';

export default defineComponent({
  name: 'HistoryScoresItem',

  props: {
    item: {
      type: Object as PropType<IBonusTransaction>,
      default: () => ({}),
    },
  },

  data() {
    return {
      isIncrease: isIncreaseType(this.item.type),
      isOpenNotes: false,
      createdAt: this.getConvertTime(this.item.createdAt),
    };
  },

  methods: {
    toggleNote() {
      this.isOpenNotes = !this.isOpenNotes;
    },

    getConvertTime(datetime: string): string {
      const time = new Date(datetime);
      const convertedTime = new Date(time).toLocaleString();

      return convertedTime.replace(',', '').substring(0, convertedTime.length - 4);
    },
  },
});
