import { CATALOG_MENU_ITEM_TYPE } from '@/constants';

import jsonApi from '@/libs/jsonapi';

interface IKitchen {
  id: string;
  name: string;
  productsCount: number,
  isGeographic: boolean,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function isKitchens(items: any[]): items is IKitchen[] {
  return items[0]?.type === CATALOG_MENU_ITEM_TYPE.kitchens;
}

jsonApi.define<IKitchen>(
  'kitchen',
  {
    name: '',
    productsCount: 0,
    isGeographic: false,
  },
  {
    collectionPath: 'kitchens',
  },
);

export {
  IKitchen as default,
  isKitchens,
};
