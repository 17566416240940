export default function throttle(
  type: string,
  name: string,
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  obj?: any,
): void {
  const object = obj || window;

  let running = false;

  const func = () => {
    if (running) {
      return;
    }

    running = true;

    requestAnimationFrame(() => {
      object.dispatchEvent(new CustomEvent(name));
      running = false;
    });
  };

  object.addEventListener(type, func);
}
