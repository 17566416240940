
import { defineComponent, PropType } from 'vue';

import ICompany from '@/models/company';

import domainWithProtocol from '@/helpers/domainWithProtocol';

export default defineComponent({
  name: 'CompaniesList',

  props: {
    companies: {
      type: Array as PropType<ICompany[]>,
      required: true,
    },
  },

  data() {
    return {
      domainWithProtocol,
    };
  },
});
