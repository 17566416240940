import jsonApi from '@/libs/jsonapi';

enum PAYMENT_TYPE {
  CASH = 'cash',
  CARD_OFFLINE = 'card_offline',
  CARD_ONLINE = 'card_online',
  SBP = 'sbp',
}

interface IPaymentType {
  title: string;
  paymentTypeId: PAYMENT_TYPE;
  isEnabled: boolean;
  isContactless: boolean;
}

function isPaymentType(type: string | null): type is PAYMENT_TYPE {
  if (!type) {
    return false;
  }

  return (Object.values(PAYMENT_TYPE) as string[]).includes(type);
}

jsonApi.define(
  'company_payment_type',
  {
    title: '',
    paymentTypeId: '',
    isEnabled: '',
    isContactless: false,
  },
  {
    collectionPath: 'payment-types',
  },
);

export {
  IPaymentType,
  PAYMENT_TYPE,
  isPaymentType,
};
