
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'NumberPhonePanel',

  props: {
    phones: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    logoUrl: {
      type: String,
      default: '',
    },
  },
});
