// Если число целое - форматирует как целое
// Иначе форматирует с двумя 0 после запятой
export default (value: string, minimumFractionDigits = 2): string => {
  const numberValue = +value;

  if (Number.isNaN(numberValue)) {
    return '0';
  }

  return Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: numberValue % 1 === 0
      ? 0
      : minimumFractionDigits,
  }).format(numberValue);
};
