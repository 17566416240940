import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb6c10d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quantity-control" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "quantity-control__total" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "quantity-control__increase",
      disabled: _ctx.isLoading,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('increase')))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.quantity), 1),
    _createElementVNode("button", {
      class: "quantity-control__decrease",
      disabled: _ctx.isLoading,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('decrease')))
    }, null, 8, _hoisted_4)
  ]))
}