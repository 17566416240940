
import { defineComponent, PropType } from 'vue';
import vClickOutside from 'click-outside-vue3';

interface ISort {
  title: string,
  value: string,
}

export default defineComponent({
  name: 'SortPanel',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    items: {
      type: Array as PropType<ISort[]>,
      required: true,
    },
    querySort: {
      type: String,
      default: undefined,
    },
  },

  emits: ['select'],

  data() {
    return {
      isOpenPanel: false,
    };
  },

  computed: {
    activedItem(): ISort | null {
      return this.items.find(({ value }) => this.querySort === value) || null;
    },
  },

  methods: {
    select(value: string) {
      this.closePanel();

      this.$emit('select', value);
    },

    togglePanel() {
      this.isOpenPanel = !this.isOpenPanel;
    },

    closePanel() {
      this.isOpenPanel = false;
    },
  },
});
