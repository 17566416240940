
import { defineComponent, ref } from 'vue';

import { IOrder } from '@/models/order';

import useOrder from '@/stores/order';

import PaymentResultPanel from '@/components/company/Order/PaymentResultPanel/PaymentResultPanel.vue';

export default defineComponent({
  name: 'ErrorPayment',

  components: {
    PaymentResultPanel,
  },

  props: {
    orderId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const order = ref<IOrder>();

    useOrder()
      .getById(props.orderId, { include: 'payment' })
      .then((rOrder) => {
        order.value = rOrder;
      });

    return {
      order,
      orderID: props.orderId,
    };
  },
});
