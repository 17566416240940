
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ItemPanel',

  props: {
    isDefault: {
      type: Boolean,
      required: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setDefault', 'openForm', 'saveForm'],

  methods: {
    edit() {
      if (this.isOpened) {
        this.$emit('saveForm');
      } else {
        this.$emit('openForm');
      }
    },
  },
});
