
/* eslint-disable no-param-reassign */
import { defineComponent } from 'vue';
import paginate from 'jw-paginate';

export default defineComponent({
  name: 'Pagination',

  props: {
    total: {
      type: Number,
      required: true,
    },
    selectedPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 1,
    },
    pagesCount: {
      type: Number,
      required: true,
    },
  },

  emits: ['select'],

  data() {
    return {
      pages: [] as number[],
      startPage: 0,
      endPage: 0,
      maxPages: 5,
    };
  },

  watch: {
    total: {
      handler() {
        this.recalculate();
      },
      immediate: true,
    },

    selectedPage: {
      handler() {
        this.recalculate();
      },
      immediate: true,
    },
  },

  methods: {
    select(page: number) {
      if (page < 1) {
        page = 1;
      } else if (page > this.pagesCount) {
        page = this.pagesCount;
      }

      this.$emit('select', { number: page, size: this.pageSize });
    },

    showMore() {
      const newPage = this.selectedPage < this.pagesCount
        ? this.selectedPage + 1
        : this.pagesCount;

      this.$emit('select', {
        number: newPage,
        size: this.pageSize,
        append: true,
      });
    },

    recalculate() {
      const pagination = paginate(
        this.total,
        this.selectedPage,
        this.pageSize,
        this.maxPages,
      );

      this.pages = pagination.pages;
      this.startPage = pagination.startPage;
      this.endPage = pagination.endPage;
    },
  },
});
