
import { defineComponent } from 'vue';
import { MoPreloader } from '@mo/uikit';
import { useField } from 'vee-validate';
import { string } from 'yup';

import { DOCUMENT_TYPE } from '@/models/legalDocument';

import useCompany from '@/stores/company';

import getFormatTime from '@/helpers/getFormatTime';
import CodeField from '@/components/shared/CodeField/CodeField.vue';

export default defineComponent({
  name: 'CodeForm',

  components: {
    MoPreloader,
    CodeField,
  },

  props: {
    phone: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isRegistration: {
      type: Boolean,
      required: true,
    },
    allowSendAt: {
      type: Date || null,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['confirm', 'sendNewCode', 'changePhone'],

  setup() {
    const {
      validate,
      value: code,
      meta,
    } = useField<string>(
      'code',
      string()
        .required()
        .matches(/\d{4}/, 'Код подтверждения должен содержать 4 символа'),
    );

    return {
      validate,
      code,
      meta,
      companyStore: useCompany(),
      DOCUMENT_TYPE,
    };
  },

  data(): {
    isConfirmed?: false;
    timeLeft: number;
    getFormatTime: (t: number) => string;
    } {
    return {
      isConfirmed: undefined,
      timeLeft: 0,
      getFormatTime,
    };
  },

  computed: {
    isAuthAllowed(): boolean {
      return (
        this.meta.valid
        && ((this.isRegistration && this.isConfirmed) || !this.isRegistration)
      );
    },
  },

  watch: {
    allowSendAt: {
      handler() {
        this.setTimer();
      },
      immediate: true,
    },
  },

  methods: {
    setCode(code: string) {
      this.code = code;
    },

    confirm() {
      if (!this.meta.valid && this.isConfirmed) {
        return;
      }

      this.$emit('confirm', this.code);
    },

    setTimer() {
      const currentDate = new Date();

      currentDate.setTime(
        currentDate.getTime() + this.allowSendAt.getTimezoneOffset() * 60 * 1000,
      );

      this.timeLeft = Math.ceil(
        (this.allowSendAt.getTime() - currentDate.getTime()) / 1000,
      );

      const timerId = setInterval(() => {
        this.timeLeft -= 1;

        if (this.timeLeft <= 0) {
          clearInterval(timerId);
        }
      }, 1000);
    },
  },
});
