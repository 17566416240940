
import { defineComponent, PropType, ref, Ref } from 'vue';

import IProductOption from '@/models/productOption';

const GAP = 14;
const SLIDE_STEP = 100;
const VISIBLE_CLASS = 'options-list--important-visible';
const LIST_TYPES = {
  DEFAULT: 'default',
  SLIDER: 'slider',
};

export default defineComponent({
  name: 'OptionsList',

  props: {
    options: {
      type: Array as PropType<IProductOption[]>,
      default: () => ([]),
    },
    productId: {
      type: String,
      default: '',
    },
    selectedOptionId: {
      type: String,
      default: '',
    },
  },

  emits: ['select'],

  setup() {
    const optionsComponent: Ref<HTMLElement | null> = ref(null);

    return {
      optionsComponent,
    };
  },

  data(): {
    listWidth: number,
    totalWidth: number,
    sliderOffset: number,
    $list: HTMLElement | null,
    type: typeof LIST_TYPES[keyof typeof LIST_TYPES] | null,
    } {
    return {
      listWidth: 0,
      totalWidth: 0,
      sliderOffset: 0,
      $list: null,
      type: null,
    };
  },

  computed: {
    sliderCssStyle(): string {
      return `transform: translateX(${this.sliderOffset}px);`;
    },

    isBackBtnLocked(): boolean {
      return this.sliderOffset >= 0;
    },

    isForwardBtnLocked(): boolean {
      return this.totalWidth + this.sliderOffset <= this.listWidth;
    },
  },

  mounted() {
    if (!this.optionsComponent) {
      return;
    }

    this.optionsComponent.classList.add(VISIBLE_CLASS);

    this.init();

    this.$nextTick(() => {
      if (!this.optionsComponent) {
        return;
      }

      this.optionsComponent.classList.remove(VISIBLE_CLASS);
    });
  },

  methods: {
    init() {
      const $items = document.querySelectorAll(`#option-item-${this.productId}`);

      this.$list = this.$refs[`list-wrapper-${this.productId}`] as HTMLElement;
      this.listWidth = this.$list?.offsetWidth;

      $items.forEach((item) => {
        this.totalWidth += (item as HTMLElement).offsetWidth;
      });

      this.totalWidth += ($items.length - 1) * GAP;
      this.type = this.getType();
    },

    getType(): typeof LIST_TYPES[keyof typeof LIST_TYPES] {
      if (this.totalWidth > this.listWidth) {
        setTimeout(() => {
          this.listWidth = this.$list?.offsetWidth || this.listWidth;
        }, 0);
      }

      return this.totalWidth > this.listWidth
        ? LIST_TYPES.SLIDER
        : LIST_TYPES.DEFAULT;
    },

    slideBack() {
      this.sliderOffset += SLIDE_STEP;

      if (this.sliderOffset > 0) {
        this.sliderOffset = 0;
      }
    },

    slideForward() {
      this.sliderOffset -= SLIDE_STEP;

      if (this.sliderOffset < this.listWidth - this.totalWidth) {
        this.sliderOffset = this.listWidth - this.totalWidth;
      }
    },
  },
});
