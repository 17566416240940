import { defineStore } from 'pinia';
import { orderBy, remove } from 'lodash';

import IDeliveryAddress from '@/models/deliveryAddress';
import ISearchAddress from '@/models/searchAddress';

import useCompany from '@/stores/company';

import jsonApi from '@/libs/jsonapi';

type IDeliveryAddressState = {
  deliveryAddresses: IDeliveryAddress[],
}

const stub: Omit<IDeliveryAddress, 'fullName' | 'isDeliveryAvailable'> = {
  id: '',
  flat: '',
  floor: '',
  entrance: '',
  city: '',
  street: '',
  building: '',
  name: '',
  isDefault: false,
  isIntercomAvailable: false,
  geozoneId: '',
};

const useDeliveryAddress = defineStore('deliveryAddress', {
  state(): IDeliveryAddressState {
    return {
      deliveryAddresses: [],
    };
  },

  getters: {
    sortedDeliveryAddresses(state: IDeliveryAddressState) {
      return orderBy(state.deliveryAddresses, 'isDefault', 'desc');
    },
  },

  actions: {
    getEmpty() {
      return {
        ...stub,
      };
    },

    async setDefault(id: string) {
      const deliveryAddress = this.deliveryAddresses.find((da) => da.id === id);

      if (!deliveryAddress) {
        throw new Error('Ошибка установки адреса по-умолчанию');
      }

      await this.update({
        ...deliveryAddress,
        isDefault: true,
      });

      this.deliveryAddresses.forEach((item) => {
        /* eslint-disable-next-line no-param-reassign */
        item.isDefault = item.id === id;
      });
    },

    async getAll(params: Record<string, unknown> = {}): Promise<IDeliveryAddress[]> {
      const deliveryAddresses = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('company_user_delivery_address')
          .get<IDeliveryAddress[]>(params)
      ).data;

      this.deliveryAddresses = deliveryAddresses;

      return deliveryAddresses;
    },

    async remove(id: string) {
      await jsonApi.one('company', useCompany().activeCompany!.login)
        .one('company_user_delivery_address', id)
        .destroy();

      remove(this.deliveryAddresses, (da) => da.id === id);
    },

    async update(address: Omit<IDeliveryAddress, 'fullName' | 'isDeliveryAvailable'>): Promise<IDeliveryAddress> {
      const updatedAddress = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .one('company_user_delivery_address', address.id)
          .patch<IDeliveryAddress>(address)
      ).data;

      const index = this.deliveryAddresses.findIndex((da) => da.id === address.id);
      this.deliveryAddresses[index] = updatedAddress;

      return updatedAddress;
    },

    async create(
      address: Omit<IDeliveryAddress, 'id' | 'fullName' | 'isDeliveryAvailable'>,
    ): Promise<IDeliveryAddress> {
      const newAddress = (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .all('company_user_delivery_address')
          .post<IDeliveryAddress>(address)
      ).data;

      this.deliveryAddresses.push(newAddress);

      for (let i = 0; i < this.deliveryAddresses.length; i += 1) {
        this.deliveryAddresses[i].isDefault = this.deliveryAddresses[i].id === newAddress.id;
      }

      return newAddress;
    },

    findSearchAddresses(query: string) {
      return jsonApi.one('company', useCompany().activeCompany!.login)
        .all('search_address').get<ISearchAddress[]>({ query });
    },
  },
});

export default useDeliveryAddress;
