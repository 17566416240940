
import { defineComponent } from 'vue';

import useCompany from '@/stores/company';

export default defineComponent({
  name: 'FinalPricePanel',

  props: {
    isReversePanel: {
      type: Boolean,
      default: false,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    withPanelTitle: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      companyStore: useCompany(),
    };
  },
});
