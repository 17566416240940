import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ba62ff8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-drop-down-menu" }
const _hoisted_2 = { class: "header-drop-down-menu__items" }
const _hoisted_3 = { class: "header-drop-down-menu__items-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header-drop-down-menu__header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _renderSlot(_ctx.$slots, "header", { isOpened: _ctx.isOpened }, undefined, true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Cover, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "items", {}, undefined, true)
          ])
        ]),
        _: 3
      })
    ], 512), [
      [_vShow, _ctx.isOpened]
    ])
  ])), [
    [_directive_click_outside, _ctx.hide]
  ])
}