import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d6ab106"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-types-panel" }
const _hoisted_2 = { class: "item__type" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TypePanel = _resolveComponent("TypePanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryTypes, (type, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "delivery-types-panel__item item"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TypePanel, {
            type: type,
            "type-id": type.deliveryTypeId,
            "is-active": type.deliveryTypeId === _ctx.selectedId,
            onSelect: _ctx.selectDeliveryType
          }, null, 8, ["type", "type-id", "is-active", "onSelect"])
        ]),
        (!type.isAvailable && type.terms)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "item__description",
              innerHTML: type.terms
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}