import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './images/search.svg'


const _withScopeId = n => (_pushScopeId("data-v-10db5b9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-panel" }
const _hoisted_2 = {
  key: 0,
  class: "search-panel__input-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      class: "search-panel__search-icon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePanel(true)))
    }),
    (_ctx.isOpenPanel)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
            placeholder: "Поиск",
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('search', _ctx.value)), ["enter"])),
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('input', _ctx.value)))
          }, null, 544), [
            [_vModelText, _ctx.value]
          ]),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('search', _ctx.value)))
          })
        ])), [
          [_directive_click_outside, () => _ctx.togglePanel()]
        ])
      : _createCommentVNode("", true)
  ]))
}