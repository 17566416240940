import { defineStore } from 'pinia';

import axios from '@/libs/axios';

type ICustomPageStore = {
  currentPageTemplate: string,
}

const useCustomPage = defineStore('customPage', {
  state(): ICustomPageStore {
    return {
      currentPageTemplate: '',
    };
  },

  actions: {
    async getRenderTemplateById(id: string) {
      this.currentPageTemplate = await axios.get(`custom-pages/${id}`);
    },
  },
});

export default useCustomPage;
