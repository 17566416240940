
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { MoSpinner } from '@mo/uikit';

import { IOrder } from '@/models/order';
import { PAYMENT_TYPE } from '@/models/paymentType';
import { PAYMENT_STATUSES } from '@/models/orderPayment';

import useOrder from '@/stores/order';

import PaymentResultPanel from '@/components/company/Order/PaymentResultPanel/PaymentResultPanel.vue';
import OrdersNavigationPanel from '@/components/company/Order/NavigationPanel/NavigationPanel.vue';

function rejectDelay(reason: unknown) {
  const t = 5000;

  return new Promise((_, reject) => {
    setTimeout(reject.bind(null, reason), t);
  });
}

function noNeedPayment(order: IOrder) {
  if (!order.paymentTypeId) {
    return false;
  }

  return (([
    PAYMENT_TYPE.CASH,
    PAYMENT_TYPE.CARD_OFFLINE,
    PAYMENT_TYPE.SBP,
  ] as string[]).includes(order.paymentTypeId));
}

export default defineComponent({
  name: 'SuccessPaymentView',

  components: {
    MoSpinner,
    PaymentResultPanel,
    OrdersNavigationPanel,
  },

  props: {
    orderId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const orderStore = useOrder();
    const order = ref<IOrder>();
    const isOver = ref<boolean>();
    const maxTries = 5;
    const orderParams = {
      include: 'items,items.product,payment',
    };

    let p: Promise<any> = Promise.reject();

    for (let i = 0; i < maxTries; i += 1) {
      p = p
        .catch(() => orderStore.getById(props.orderId, orderParams))
        // eslint-disable-next-line consistent-return
        .then((rOrder: IOrder) => {
          if (!rOrder) {
            router.push({
              name: 'c-error-payment',
              query: {
                ...route.query,
              },
            });
          }

          if (noNeedPayment(rOrder)) {
            return rOrder;
          }

          switch (rOrder.payment?.status) {
            case PAYMENT_STATUSES.pending:
              throw Error('Заказ не оплачен');

            case PAYMENT_STATUSES.failed:
              router.push({
                name: 'c-error-payment',
                query: {
                  ...route.query,
                },
              });

              break;

            case PAYMENT_STATUSES.successful:
              return rOrder;

            default:
              throw Error('Некорректный статус оплаты');
          }
        })
        .catch(rejectDelay);
    }

    p.then((rOrder) => {
      order.value = rOrder;
    }).catch(() => {
      isOver.value = true;
    });

    return {
      order,
      isOver,
      noNeedPayment,
    };
  },
});
