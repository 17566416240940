import { defineStore } from 'pinia';

import IKind from '@/models/kind';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';

const useKind = defineStore('kind', {
  actions: {
    async getAll(params: Partial<{
        filters: Partial<{
          'restaurant_id': string,
        }>,
      }> = {}): Promise<IKind[]> {
      const response = await jsonApi.one('company', useCompany().activeCompany!.login)
        .all('filter')
        .get<IKind[]>(params);

      return response.data;
    },
  },
});

export default useKind;
