
import { defineComponent, PropType } from 'vue';

import IKitchen from '@/models/kitchen';

import Item from '../Item/Item.vue';

import isEven from '@/helpers/isEven';

export default defineComponent({
  name: 'KitchensList',

  components: {
    Item,
  },

  props: {
    kitchens: {
      type: Array as PropType<IKitchen[]>,
      required: true,
    },
    selectedIds: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },

  emits: ['select', 'close'],

  data() {
    return {
      isEven,
    };
  },

  computed: {
    filteredFilters(): {
      notGeographic: IKitchen[],
      geographic: (IKitchen & { isDivider?: boolean })[],
      } {
      const filters = {
        notGeographic: [] as IKitchen[],
        geographic: [
          {
            name: 'Географический ориентир',
            isDivider: true,
          },
        ] as (IKitchen & { isDivider?: boolean })[],
      };

      this.kitchens.forEach((item) => {
        if (item.isGeographic) {
          filters.geographic.push(item);
        } else {
          filters.notGeographic.push(item);
        }
      });

      return filters;
    },

    mobileFilteredFilters(): (IKitchen & { isDivider?: boolean })[] {
      return [...this.filteredFilters.notGeographic, ...this.filteredFilters.geographic];
    },

    isSelectedItem() {
      return (filter: {[x: string]: any}) => this.selectedIds.includes(filter.id)
        || this.selectedIds.filter(({ id }) => id).map(({ id }) => id).includes(filter.id);
    },
  },
});
