import { createPinia } from 'pinia';
import { setLocale } from 'yup';
import Maska from 'maska';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import VueRecaptcha from 'vue3-recaptcha-v2';

import App from './App.vue';
import router from './router';
import translations from './translations';
import formattedNumber from './filters/formattedNumber';
import formattedDateTime from './filters/formattedDateTime';
import replacedHtmlTags from './filters/replacedHtmlTags';
import replaceFirstEight from './filters/replaceFirstEight';

import '@/models/index';

setLocale(translations);

const app = createApp(App);

app.config.globalProperties.$filters = {
  formattedNumber,
  formattedDateTime,
  replacedHtmlTags,
  replaceFirstEight,
};

app.use(createPinia());
app.use(Maska);
app.use(router);
app.use(Toast);

app.use(VueRecaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
});

if (process.env.VUE_APP_SENTRY_DNS !== ''
  && process.env.NODE_ENV === 'production') {
  const WHITE_LIST_LEVELS = [
    Sentry.Severity.Error,
    Sentry.Severity.Critical,
    Sentry.Severity.Fatal,
  ];

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    tunnel: '/sentry-tunnel',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.Event) {
      if (event.level && !WHITE_LIST_LEVELS.includes(event.level)) {
        return null;
      }

      return event;
    },
  });
}

app.mount('#app');
