
import { ref, defineComponent } from 'vue';
import { MoPreloader } from '@mo/uikit';

import useCustomPages from '@/stores/customPage';
import useCompany from '@/stores/company';

import MainMenu from '@/components/shared/MainMenu/MainMenu.vue';
import NotFound from '@/components/shared/ErrorPages/NotFound/NotFound.vue';

interface ICustomPageMenuItem {
  title: string,
  routeSettings: {
    name: string,
    params: {
      customPage: string,
    },
  },
}

export default defineComponent({
  name: 'CustomPage',

  components: {
    MainMenu,
    MoPreloader,
    NotFound,
  },

  setup() {
    const customPageStore = useCustomPages();
    const companyStore = useCompany();

    const frame = ref<HTMLIFrameElement>();

    return {
      frame,
      customPageStore,
      companyStore,
    };
  },

  data() {
    return {
      isMounted: false,
      isLoading: true,
    };
  },

  computed: {
    currentMenuItem(): ICustomPageMenuItem {
      const currentPage = this.companyStore.activeCompany?.customPages?.find(
        ({ slug }) => this.$route.params.customPage === slug,
      );

      return (currentPage
        ? {
          title: currentPage.name,
          routeSettings: {
            name: 'c-custom-pages',
            params: {
              customPage: currentPage.slug,
            },
          },
        } : {} as ICustomPageMenuItem);
    },
  },

  watch: {
    '$route.params': 'getTemplate',
  },

  mounted() {
    this.isMounted = true;

    this.getTemplate();
  },

  methods: {
    resizeFrameHeight({ target }: { target: HTMLIFrameElement }) {
      /* eslint-disable no-param-reassign */
      target.style.height = `${target.contentWindow?.document.body.scrollHeight}px`;
    },

    async getTemplate() {
      const currentPage = this.companyStore.activeCompany?.customPages?.find(
        ({ slug }) => this.$route.params.customPage === slug,
      );

      if (!currentPage) {
        this.isLoading = false;

        return;
      }

      this.isLoading = true;

      await this.customPageStore.getRenderTemplateById(currentPage.id);

      this.isLoading = false;
    },
  },
});
