import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8ee1084"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-menu-wrapper" }
const _hoisted_2 = { class: "left-col" }
const _hoisted_3 = { class: "main-menu" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "submenu__title" }
const _hoisted_6 = {
  key: 0,
  id: "submenu",
  class: "submenu__dropdown"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "left-col", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainCategoriesList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item,
          class: _normalizeClass({
          'main-menu__item': true,
          'main-menu__item--active': item.isActive,
        })
        }, [
          (item.routeSettings || item.route)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.routeSettings || { name: item.route },
                class: "main-menu__link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: ($event: any) => (_ctx.select(item))
              }, _toDisplayString(item.title || item), 9, _hoisted_4)),
          _createElementVNode("img", {
            class: _normalizeClass({
            'arrow-icon': true,
            'arrow-icon--mini': _ctx.isMiniArrow,
          })
          }, null, 2)
        ], 2))
      }), 128)),
      (_ctx.hiddenCategoriesList.length)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['main-menu__item submenu', {'submenu--is-open': _ctx.isOpen}])
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "submenu__title-text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle()))
              }, " Еще "),
              (_ctx.isOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hiddenCategoriesList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item,
                        class: "submenu__list-item",
                        onClick: ($event: any) => (_ctx.select(item))
                      }, [
                        (item.routeSettings || item.route)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: item.routeSettings || { name: item.route },
                              class: "main-menu__link"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.title || item), 1))
                      ], 8, _hoisted_7))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)), [
            [_directive_click_outside, _ctx.hideSubMenu]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}