
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FavoriteToggler',

  props: {
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['toggle'],
});
