/* eslint-disable no-template-curly-in-string */
const translations = {
  string: {
    email: '${path} должен быть корректным email',
    notType: 'Не выбрано значение',
  },
  mixed: {
    required: '${path} это обязательное поле',
  },
};

export default translations;
