
import { defineComponent, PropType } from 'vue';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

import { MOBILE_BREAKPOINT, DELIVERY_ZONE_OPACITY } from '@/constants';

import IDeliveryZone from '@/models/deliveryZone';

const OPTIONS = {
  desktop: {
    zoom: 11.4,
    centerCoords: [53.195060, 45.102700],
  },
  mobile: {
    zoom: 10.2,
    centerCoords: [53.195060, 45.03],
  },
};

const SETTINGS = {
  apiKey: process.env.VUE_APP_YAMAPS_APIKEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

export default defineComponent({
  name: 'CustomYandexMap',

  components: {
    yandexMap,
    ymapMarker,
  },

  props: {
    zones: {
      type: Array as PropType<IDeliveryZone[]>,
      required: true,
    },
    selectedId: {
      type: String || null,
      default: null,
    },
  },

  emits: ['select'],

  setup() {
    const options = window.innerWidth > MOBILE_BREAKPOINT
      ? OPTIONS.desktop
      : OPTIONS.mobile;

    return {
      options,
      DELIVERY_ZONE_OPACITY,
      SETTINGS,
    };
  },

  data(): {
    hoveredId: string | undefined,
    } {
    return {
      hoveredId: undefined,
    };
  },

  methods: {
    toggleOpacity(id: string | undefined) {
      this.hoveredId = id;
    },
  },
});
