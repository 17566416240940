
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  onUnmounted,
} from 'vue';
import { chunk } from 'lodash';

import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
} from '@/constants';

import Item from '../Item/Item.vue';

import throttleEvent from '@/libs/throttleEvent';

interface IFilter {
  [x: string]: any,
}

const getColumnsCount = (width: number) => {
  if (width > DESKTOP_BREAKPOINT) {
    return 6;
  } if (width > TABLET_BREAKPOINT && width <= DESKTOP_BREAKPOINT) {
    return 4;
  } if (width > MOBILE_BREAKPOINT && width <= TABLET_BREAKPOINT) {
    return 3;
  }

  return 2;
};

export default defineComponent({
  name: 'FiltersList',

  components: {
    Item,
  },

  props: {
    filters: {
      type: Array as PropType<IFilter[]>,
      required: true,
    },
    selectedIds: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },

  emits: ['select', 'close'],

  setup(props) {
    throttleEvent('resize', 'optimizedResize');

    const windowWidth = ref(window.innerWidth);

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => window.addEventListener('optimizedResize', onWidthChange));
    onUnmounted(() => window.removeEventListener('optimizedResize', onWidthChange));

    const filtersByColumns = computed(
      () => chunk(
        props.filters,
        Math.ceil(
          props.filters.length / getColumnsCount(windowWidth.value),
        ),
      ),
    );

    return {
      filtersByColumns,
    };
  },

  computed: {
    filterType(): string {
      const [filter] = this.filters;

      return filter?.type;
    },

    isSelectedItem() {
      return (filter: {[x: string]: any}) => this.selectedIds.includes(filter.id)
        || this.selectedIds.filter(({ id }) => id).map(({ id }) => id).includes(filter.id);
    },
  },
});
