
import { defineComponent, PropType } from 'vue';

interface IFilter {
  [x: string]: any,
}

export default defineComponent({
  name: 'Tag',

  props: {
    filter: {
      type: Object as PropType<IFilter>,
      required: true,
    },
    isSubTag: {
      type: Boolean,
      default: false,
    },
    isLastChild: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['deselect', 'selectSubGroup'],

  computed: {
    isRightArrow(): boolean {
      return this.filter.filters?.length || (this.isSubTag && !this.isLastChild);
    },

    isLeftArrow(): boolean {
      return this.isSubTag;
    },
  },

  methods: {
    selectSub() {
      if (!this.isSubTag) {
        return;
      }

      this.$emit('selectSubGroup', this.filter.id);
    },
  },
});
