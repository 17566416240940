import { defineStore } from 'pinia';

import ICompany from '@/models/company';

import jsonApi from '@/libs/jsonapi';

import { DOCUMENT_TYPE, isLegalDocument } from '@/models/legalDocument';

type IDocumentUrl = {[key in DOCUMENT_TYPE]?: string}

type ICompanyState = {
  activeCompany?: ICompany;
  activeParentCompany?: ICompany;
}

const useCompany = defineStore('company', {
  state(): ICompanyState {
    return {
      activeCompany: undefined,
      activeParentCompany: undefined,
    };
  },

  getters: {
    deliveryTime(state: ICompanyState) {
      const deliveryTime = state.activeCompany?.deliveryTime;

      if (deliveryTime && deliveryTime.length) {
        return `${deliveryTime.join(' - ')} мин`;
      }

      return 'Не определено';
    },

    overviewCustomPages(state: ICompanyState) {
      return state.activeCompany?.customPages?.filter(
        ({ isDisplayOnOverview }) => isDisplayOnOverview,
      ) || [];
    },

    hasOneSocialLink(state: ICompanyState) {
      const company = state.activeCompany;
      return company?.vk || company?.youtube || company?.facebook || company?.instagram;
    },

    getRestaurantById: (state: ICompanyState) => (
      (restaurantId: string) => state.activeCompany?.restaurants?.find(
        ({ id }) => +id === +restaurantId,
      )
    ),

    documentsUrls(state: ICompanyState) {
      if (!state.activeCompany?.legalDocuments?.length) {
        return {};
      }

      const documentsUrls = {} as IDocumentUrl;

      state.activeCompany.legalDocuments.forEach((item) => {
        if (isLegalDocument(item.slug)) {
          documentsUrls[item.slug] = item.url;
        }
      });

      return documentsUrls;
    },
  },

  actions: {
    clearActiveCompany() {
      this.activeCompany = undefined;
    },

    clearActiveParentCompany() {
      this.activeParentCompany = undefined;
    },

    async fetchActiveCompany(login: string, params: Record<string, unknown> = {}) {
      const company = (await jsonApi.find<ICompany>('company', login, params)).data;

      if (company) {
        this.activeCompany = { ...this.activeCompany, ...company };
      }
    },

    async fetchActiveParentCompany(params: Record<string, unknown> = {}) {
      if (!this.activeCompany?.parent) {
        this.activeParentCompany = undefined;

        return;
      }

      const parentCompany = (await jsonApi.find<ICompany>(
        'company',
        this.activeCompany!.parent!.login,
        params,
      )).data;

      this.activeParentCompany = parentCompany;
    },

    async findAll(params: Record<string, unknown> = {}) {
      const companies = (await jsonApi.findAll<ICompany>('company', params)).data;

      return companies;
    },
  },
});

export default useCompany;
