
import { defineComponent } from 'vue';

import { CATALOG_PAGE, VERSION_WARNING_MESSAGE } from '@/constants';

import { IPage, QUERY_PARAMS } from '@/types';

import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';
import useVersion from '@/stores/version';
import useFastView from '@/stores/fastView';
import { useCatalog } from '@/stores/catalog';

import getFormatPages from '@/helpers/getFormatPages';
import getQueryParams from '@/helpers/queryParams';
import ModalDialog from '@/components/shared/ModalDialog/ModalDialog.vue';
import Cover from '@/components/shared/Cover/Cover.vue';
import Header from '@/components/shared/Header/Header/Header.vue';
import ProgressPreloader from '@/components/shared/ProgressPreloader/ProgressPreloader.vue';
import Footer from '@/components/shared/Footer/Footer.vue';
import AuthForm from '@/components/company/Auth/AuthForm/AuthForm.vue';
import CompanyHeader from '@/components/company/Header/Header.vue';
import SectionMenu from '@/components/shared/SectionMenu/SectionMenu.vue';
import Warning from '@/components/shared/Warning/Warning.vue';
import FastViewPanel from '@/components/company/Catalog/FastViewPanel/FastViewPanel.vue';

export default defineComponent({
  name: 'CompanyRootView',

  components: {
    AuthForm,
    Cover,
    CompanyHeader,
    Header,
    ModalDialog,
    Footer,
    ProgressPreloader,
    Warning,
    SectionMenu,
    FastViewPanel,
  },

  setup() {
    return {
      authStore: useAuth(),
      companyStore: useCompany(),
      catalogStore: useCatalog(),
      versionStore: useVersion(),
      fastViewStore: useFastView(),
    };
  },

  data(): {
    pages: IPage[],
    VERSION_WARNING_MESSAGE: string,
    } {
    return {
      VERSION_WARNING_MESSAGE,
      pages: [],
    };
  },

  mounted() {
    this.pages = getFormatPages(
      CATALOG_PAGE,
      ...this.companyStore.overviewCustomPages,
    );

    if (this.$route.query.opened_product_id) {
      this.fastViewStore.setProduct(this.$route.query.opened_product_id as string);
    }

    if (this.authStore.activeUser && this.companyStore.activeCompany?.hasBonusPoints) {
      this.authStore.fetchActiveUser('bonus_info');
    }
  },

  methods: {
    reloadPage() {
      window.location.reload();
    },

    closeFastView() {
      const query = getQueryParams(this.$route.query, {
        [QUERY_PARAMS.openedProductId]: '',
      });

      const params = { ...this.$route.params, isScrollDisabled: 'true' };

      this.$router.push({
        params,
        query,
      });

      this.fastViewStore.close();
    },
  },
});
