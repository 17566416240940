
import { ref, defineComponent, PropType } from 'vue';
import { MoPreloader } from '@mo/uikit';
import vClickOutside from 'click-outside-vue3';

import IProduct from '@/models/product';
import IProductOption from '@/models/productOption';

import useOrder from '@/stores/order';
import useFavorite from '@/stores/favorite';

import VerticalQuantityControl from '@/components/shared/VerticalQuantityControl/VerticalQuantityControl.vue';
import FinalPricePanel from '@/components/shared/Products/FinalPricePanel/FinalPricePanel.vue';
import FavoriteToggler from '@/components/shared/Products/FavoriteToggler/FavoriteToggler.vue';
import ProductButton from '@/components/shared/Products/ProductButton/ProductButton.vue';
import OptionsList from '@/components/shared/Products/OptionsList/OptionsList.vue';

const MIN_X_COORD = 50;
const MOBILE_BREAKPOINT = 768;

export default defineComponent({
  name: 'InteractiveProductItem',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    MoPreloader,
    VerticalQuantityControl,
    FinalPricePanel,
    FavoriteToggler,
    ProductButton,
    OptionsList,
  },

  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },

  emits: [
    'addToOrder',
    'increaseQuantity',
    'decreaseQuantity',
    'toggleFastView',
    'toggleFavorite',
  ],

  setup() {
    const productField = ref<HTMLElement>();

    return {
      orderStore: useOrder(),
      favoriteStore: useFavorite(),
      productField,
    };
  },

  data(): {
    isReverseFinalPanel: boolean,
    selectedOption: IProductOption | null,
    isSelected: boolean,
    } {
    return {
      isReverseFinalPanel: false,
      selectedOption: null,
      isSelected: false,
    };
  },

  computed: {
    isSingleOption(): boolean {
      return this.product.productOptions.length === 1;
    },

    quantityInCart(): number {
      if (!this.selectedOption) {
        return 0;
      }

      return this.orderStore.getOptionQuantity(this.selectedOption.id);
    },
  },

  mounted() {
    this.setReversingPanel();

    const optionInCart = this.product.productOptions.find(
      ({ id }) => this.orderStore.orderItemsIdsByOptionsIds[id],
    );

    if (optionInCart) {
      this.selectedOption = optionInCart;
    } else {
      [this.selectedOption] = this.product.productOptions;
    }
  },

  methods: {
    setReversingPanel() {
      if (!this.selectedOption?.hasStopPrice || !this.productField) {
        return;
      }

      const { x: xElemCoord } = this.productField.getBoundingClientRect();

      this.isReverseFinalPanel = xElemCoord < MIN_X_COORD;
    },

    selectOption(option: IProductOption) {
      this.selectedOption = option;
      this.isSelected = true;
    },

    clickProduct() {
      if (window.innerWidth <= MOBILE_BREAKPOINT && !this.isSelected) {
        this.isSelected = true;

        return;
      }

      this.isSelected = false;
      this.$emit('toggleFastView', this.selectedOption);
    },

    unselect() {
      this.isSelected = false;
    },
  },
});
