import useVersion from '@/stores/version';

export default {
  name: 'version-control-middleware',
  res: (payload: Record<any, any>): any => {
    const version = payload.res.headers['x-version'];

    if (!version) {
      return payload;
    }

    const versionStore = useVersion();

    if (versionStore.version && versionStore.version !== version) {
      versionStore.toggleOpenVersionWarning();
    }

    versionStore.setVersion(version);

    return payload;
  },
};
