import camelCase from 'lodash/camelCase';
import { isObject, isArray } from 'lodash';

export default function convertObjectKeysToCamelCase(element: Record<string, any>): any {
  const convertedDataItem: any = isArray(element) ? [] : {};

  if (!element) {
    return element;
  }

  Object.keys(element).forEach((key) => {
    const convertedKey = camelCase(key);

    if (isObject(element[key])) {
      convertedDataItem[convertedKey] = convertObjectKeysToCamelCase(element[key]);
    } else {
      convertedDataItem[convertedKey] = element[key];
    }
  });

  return convertedDataItem;
}
