
import { defineComponent } from 'vue';

const defaultTitle = 'Внимание!';
const defaultTextButton = 'Обновить страницу';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: defaultTitle,
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: defaultTextButton,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],
});
