import snakeCase from 'lodash/snakeCase';

export default function convertObjectKeysToSnakeCase(element: Record<string, any>): any {
  const convertedDataItem: any = {};

  Object.keys(element).forEach((key) => {
    const convertedKey = snakeCase(key);
    convertedDataItem[convertedKey] = element[key];
  });

  return convertedDataItem;
}
