import jsonApi from '@/libs/jsonapi';

enum PAYMENT_STATUSES {
  new = 'new',
  pending = 'pending',
  successful = 'successful',
  failed = 'failed',
  canceled = 'canceled',
}

interface IOrderPayment {
  id: string,
  createdAt: string | null,
  expireAt: string | null,
  finishedAt: string | null,
  redirectFormUrl: string,
  sbpQrImg: string,
  sbpQrUrl: string,
  status: keyof typeof PAYMENT_STATUSES,
  statusDescription: string,
  price: string,
}

jsonApi.define(
  'payment',
  {
    id: '',
    createdAt: '',
    expireAt: '',
    finishedAt: '',
    redirectFormUrl: '',
    sbpQrImg: '',
    sbpQrUrl: '',
    status: PAYMENT_STATUSES.pending,
    statusDescription: '',
    price: '',
  },
);

export {
  IOrderPayment,
  PAYMENT_STATUSES,
};
