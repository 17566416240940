import IFavoriteProduct from './favoriteProduct';
import IBonusInfo from './bonusInfo';

import jsonApi from '@/libs/jsonapi';

interface IMoBaseUser {
  avatarBigUrl: string;
  avatarUrl: string;
  birthday: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  login: string;
  middleName: string;
  isMale: boolean;
}

interface IUser {
  id: string;
  login: string;
  phone: string;
  email: string;
  moBaseUser: IMoBaseUser;
  favoriteProducts: IFavoriteProduct[];
  bonusInfo: IBonusInfo,
  isAdmin: boolean;
}

jsonApi.define<IMoBaseUser>('mo_base_user', {
  avatarBigUrl: '',
  avatarUrl: '',
  birthday: '',
  email: '',
  firstName: '',
  fullName: '',
  lastName: '',
  login: '',
  middleName: '',
  isMale: false,
});

jsonApi.define<IUser>('user', {
  login: '',
  phone: '',
  email: '',
  moBaseUser: {
    jsonApi: 'hasOne',
    type: 'mo_base_user',
  },
  bonusInfo: {
    jsonApi: 'hasOne',
    type: 'bonus_info',
  },
  isAdmin: false,
  favoriteProducts: {
    jsonApi: 'hasMany',
    type: 'user_favorite_product',
  },
});

export {
  IUser,
  IMoBaseUser,
};
