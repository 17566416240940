import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f8cf6d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banners" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "item"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items.length > 1)
      ? (_openBlock(), _createBlock(_component_Carousel, {
          key: 0,
          class: "carousel",
          autoplay: _ctx.AUTOPLAY_TIME,
          transition: _ctx.TRANSITION_TIME,
          "wrap-around": true,
          "mouse-drag": false
        }, {
          addons: _withCtx(() => [
            _createVNode(_component_Pagination)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (banner) => {
              return (_openBlock(), _createBlock(_component_Slide, { key: banner }, {
                default: _withCtx(() => [
                  (banner.href)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: banner.href,
                        class: "item",
                        target: "_blank"
                      }, [
                        _createElementVNode("img", {
                          src: banner[_ctx.attrName]
                        }, null, 8, _hoisted_3)
                      ], 8, _hoisted_2))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("img", {
                          src: banner[_ctx.attrName]
                        }, null, 8, _hoisted_5)
                      ]))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["autoplay", "transition"]))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.items[0].href,
          class: "item"
        }, [
          _createElementVNode("img", {
            src: _ctx.items[0][_ctx.attrName]
          }, null, 8, _hoisted_7)
        ], 8, _hoisted_6))
  ]))
}