import jsonApi from '@/libs/jsonapi';

interface IFavoriteProduct {
  productId: string;
}

jsonApi.define<IFavoriteProduct>(
  'user_favorite_product',
  {
    productId: '',
  },
  {
    collectionPath: 'user/favorite-products',
  },
);

export default IFavoriteProduct;
