import { useToast } from 'vue-toastification';
import { isArray } from 'lodash';

const toast = useToast();
const catalogPath = '/c/catalog';
const allowedPaths = ['/c/catalog', '/c/custom-pages', '/c/delivery-zones'];

export default {
  name: 'custom-error',
  error(payload: Record<any, any>): any {
    const errors = payload?.response?.data?.errors;
    const status = payload?.response?.status;
    const isAllowedPath = allowedPaths.some((route) => window.location.pathname.includes(route));

    if (status === 401 && !isAllowedPath) {
      window.location.assign(catalogPath);

      return payload;
    }

    if (status === 500) {
      toast.error('Произошла ошибка. Обновите страницу и попробуйте снова');

      return payload;
    }

    if (status === 404) {
      return payload;
    }

    if (isArray(errors)) {
      errors.forEach((error) => {
        if (!error?.source?.pointer) {
          return;
        }

        const pointers = error.source.pointer.split('/');
        const isRelation = pointers.pop() === 'data';

        if (isRelation) {
          // eslint-disable-next-line no-param-reassign
          error.source.pointer = pointers.join('/');
        }
      });
    }

    return payload;
  },
};
