
import { defineComponent, PropType } from 'vue';
import { cloneDeep } from 'lodash';
import vClickOutside from 'click-outside-vue3';

interface ISelectFieldItem {
  key: string,
  value: string,
}

export default defineComponent({
  name: 'SelectField',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    items: {
      type: Array as PropType<ISelectFieldItem[]>,
      required: true,
    },
    selectedKey: {
      type: String,
      required: true,
    },
  },

  emits: ['select'],

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    selectedItem(): ISelectFieldItem | null {
      return this.items.find(({ key }) => this.selectedKey === key) || null;
    },

    itemsWithSelectedFirst(): ISelectFieldItem[] {
      const newList = cloneDeep(this.items);
      const index = this.items.findIndex(({ key }) => key === this.selectedKey);

      if (index !== -1) {
        newList.splice(index, 1);
        newList.unshift(this.items[index]);

        return newList;
      }

      return this.items;
    },
  },

  methods: {
    select(key: string) {
      if (key === this.selectedKey) {
        this.toggle();

        return;
      }

      this.$emit('select', key);
      this.toggle();
    },

    toggle(state = false) {
      this.isOpen = state;
    },
  },
});
