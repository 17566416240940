import { Router } from "vue-router";

interface IPostPaidPageParams {
  id: string,
  type: string,
  redirectUrl: string,
}

interface IPaymentType {
  router: Router,
  window: Window,
  toPostPaidPage: (order: Partial<IPostPaidPageParams>) => void,
}

class PaymentType {
  router: Router;

  window: Window;

  constructor(router: Router, window: Window) {
    this.router = router;
    this.window = window;
  }
}

export { PaymentType as default, IPaymentType, IPostPaidPageParams };
