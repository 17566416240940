
import { defineComponent, PropType } from 'vue';

interface IFilter {
  [x: string]: any,
}

export default defineComponent({
  name: 'RolledTag',

  props: {
    filter: {
      type: Object as PropType<IFilter>,
      required: true,
    },
  },

  emits: ['deselect'],
});
