import { defineStore } from 'pinia';

import IDeliveryZone from '@/models/deliveryZone';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';

type IDeliveryZoneState = {
  deliveryZones: IDeliveryZone[];
};

const useDeliveryZone = defineStore('deliveryZone', {
  state(): IDeliveryZoneState {
    return {
      deliveryZones: [],
    };
  },

  actions: {
    async fetchDeliveryZones(): Promise<void> {
      const { data } = await jsonApi
        .one('company', useCompany().activeCompany!.login)
        .all('delivery-zones')
        .get<IDeliveryZone[]>();
      this.deliveryZones = data;
    },
  },
});

export default useDeliveryZone;
