
import { defineComponent } from 'vue';
import vClickOutside from 'click-outside-vue3';

import useAuth from '@/stores/auth';

export default defineComponent({
  name: 'ProfileMenu',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    orderItemsQuantity: {
      type: Number,
      required: true,
    },
    favoritesCount: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'logout'],

  setup() {
    return {
      authStore: useAuth(),
    };
  },

  methods: {
    filterByFavorites() {
      this.$router.push({
        name: 'c-catalog',
        query: {
          is_favorite: 'true',
        },
      });
    },
  },
});
