import jsonApi from '@/libs/jsonapi';

interface IPickupAddress {
  address: string;
  description: string;
  latitude: string;
  longitude: string;
  isDefault: boolean;
}

jsonApi.define<IPickupAddress>(
  'company_pickup_address',
  {
    address: '',
    description: '',
    latitude: '',
    longitude: '',
    isDefault: false,
  },
  {
    collectionPath: 'pickup-addresses',
  },
);

export default IPickupAddress;
