import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05778c91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "companies-list" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "company-item__info" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "company-item__description" }
const _hoisted_8 = { class: "company-item__second-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item,
        class: "company-item"
      }, [
        _createElementVNode("a", {
          href: _ctx.domainWithProtocol(item.domain?.name),
          class: "company-item__preview"
        }, [
          _createElementVNode("img", {
            class: "company-item__preview-image",
            src: item.previewImage
          }, null, 8, _hoisted_3),
          _createElementVNode("img", {
            class: "company-item__logo-image",
            src: item.logo
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            href: _ctx.domainWithProtocol(item.domain?.name),
            class: "company-item__name"
          }, _toDisplayString(item.name), 9, _hoisted_6),
          _createElementVNode("div", _hoisted_7, _toDisplayString(item.description), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(item.secondDescription), 1)
        ])
      ]))
    }), 128))
  ]))
}