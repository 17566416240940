import jsonApi from '@/libs/jsonapi';

interface ICheque {
  url: string;
}

jsonApi.define<ICheque>('cheque', {
  url: '',
});

export default ICheque;
