import jsonApi from '@/libs/jsonapi';

interface IBonusLevel {
  percent: string,
}

jsonApi.define<IBonusLevel>('bonusLevel', {
  percent: '',
});

export default IBonusLevel;
