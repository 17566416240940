/* eslint-disable import/prefer-default-export */
import { CATALOG_MENU_ITEM_TYPE } from './constants';

interface IPage {
  [x: string]: any,
}

type IMenuItem<T extends string> = {
  title: string;

  type?: T;
  action?: string;
  route?: string;
  isActive?: boolean;
}

type ICatalogMenuItem = IMenuItem<CATALOG_MENU_ITEM_TYPE>

enum QUERY_PARAMS {
  groupsIds = 'groups_ids',
  kindsIds = 'kinds_ids',
  kitchensIds = 'kitchens_ids',
  restaurantId = 'restaurant_id',
  name = 'name',
  sort = 'sort',
  page = 'page',
  append = 'append',
  isFavorite = 'is_favorite',
  openedProductId = 'opened_product_id',
}

enum SCOPE_TYPE {
  AGGREGATOR = 'aggregator',
  PARENT_COMPANY = 'parent_company',
  COMPANY = 'company',
}

enum ENV_TYPE {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export {
  ICatalogMenuItem,
  IMenuItem,
  SCOPE_TYPE,
  ENV_TYPE,
  IPage,
  QUERY_PARAMS,
};
