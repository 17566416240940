
import { defineComponent, PropType } from 'vue';

import { IMoBaseUser } from '@/models/user';

import BirthdayField from '@/components/company/UserProfile/InfoPanel/BirthdayField/BirthdayField.vue';
import pluralize from '@/helpers/pluralize';
import transliterate from '@/helpers/transliterate';

export default defineComponent({
  name: 'InfoPanel',

  components: {
    BirthdayField,
  },

  props: {
    moBaseUser: {
      type: Object as PropType<IMoBaseUser>,
      required: true,
    },
  },

  data() {
    return {
      pluralize,
      transliterate,
    };
  },

  computed: {
    userGender(): string {
      return this.moBaseUser.isMale ? 'М' : 'Ж';
    },

    formattedDate(): string {
      return new Date(
        this.moBaseUser.birthday,
      ).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    userAge(): number {
      const todayDate = new Date();
      const birthdayDate = new Date(
        this.moBaseUser.birthday,
      );

      let yearDiff = todayDate.getFullYear() - birthdayDate.getFullYear();

      if (
        todayDate.getMonth() < birthdayDate.getMonth()
        || (
          todayDate.getDate() < birthdayDate.getDate()
          && todayDate.getMonth() === birthdayDate.getMonth()
        )
      ) {
        yearDiff -= 1;
      }

      return yearDiff;
    },
  },
});
