import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e84cbb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "birthday-field" }
const _hoisted_2 = { class: "zodiac" }
const _hoisted_3 = { class: "zodiac__icons" }
const _hoisted_4 = {
  key: 0,
  class: "zodiac-sign"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "east-calendar-sign"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "zodiac__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.zodiacSignIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", { src: _ctx.zodiacSignIcon }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        (_ctx.eastCalendarAnimalIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("img", { src: _ctx.eastCalendarAnimalIcon }, null, 8, _hoisted_7)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}