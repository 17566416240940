<template>
  <div class="certificate-warning">
    <p class="certificate-warning__text">
      Зарубежные компании начали отзывать сертификаты безопасности у российских сайтов.
      Без сертификатов ваши личные данные недостаточно защищены, поэтому, в случае,
      если вы ранее не установили сертификаты, при попытке оплаты вы можете увидеть
      предупреждение о небезопасности ресурса.
    </p>
    <p class="certificate-warning__text">
      <a class="certificate-warning__link" href="http://www.sberbank.ru/ru/certificates" target="_blank">
        Установите сертификаты НУЦ Минцифры России
      </a>
      или используйте Яндекс браузер.
      Установка российских сертификатов гарантирует безопасный доступ ко всем сайтам
      с любых устройств.
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.certificate-warning {
  background-color: $grey;
  padding: 10px 20px 20px;
  margin-top: 21px;
  font-family: 'Gilroy Light', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: $light-text-gray;

  &__text {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__link {
    color: #000;
    text-decoration: underline;
  }
}
</style>
