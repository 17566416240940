
import { defineComponent } from 'vue';

import useAuth from '@/stores/auth';

export default defineComponent({
  name: 'Header',

  props: {
    homeRoute: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    logoUrl: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup() {
    return {
      authStore: useAuth(),
    };
  },
});
