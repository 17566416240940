import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cb474c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoUserLogin = _resolveComponent("MoUserLogin")!
  const _component_ProfileMenu = _resolveComponent("ProfileMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MoUserLogin, {
      class: _normalizeClass({ 'user-login--authorized': _ctx.authStore.activeUser }),
      "is-authorized": Boolean(_ctx.authStore.activeUser),
      "avatar-url": _ctx.avatarUrl,
      onAvatarClick: _ctx.toggleProfileMenu,
      onSignIn: _cache[0] || (_cache[0] = ($event: any) => (_ctx.authStore.toggleAuthWindow(true)))
    }, null, 8, ["class", "is-authorized", "avatar-url", "onAvatarClick"]),
    _createElementVNode("div", {
      class: _normalizeClass(["user-panel__profile-menu", {
        'user-panel__profile-menu--is-opened': _ctx.isProfileMenuOpen,
      }])
    }, [
      (_ctx.isProfileMenuOpen)
        ? (_openBlock(), _createBlock(_component_ProfileMenu, {
            key: 0,
            "order-items-quantity": _ctx.orderStore.totalItemsQuantity || 0,
            "favorites-count": _ctx.favoriteStore.productsCount,
            "company-login": _ctx.companyStore.activeCompany.login,
            "is-admin": _ctx.authStore.activeUser.isAdmin,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleProfileMenu(false))),
            onLogout: _ctx.logout
          }, null, 8, ["order-items-quantity", "favorites-count", "company-login", "is-admin", "onLogout"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}