
import { defineComponent, PropType } from 'vue';

import IDeliveryAddress from '@/models/deliveryAddress';

import useDeliveryAddress from '@/stores/deliveryAddress';

import DeliveryAddressForm from '../Form/Form.vue';

import ItemPanel from '@/components/shared/ItemPanel/ItemPanel.vue';

export default defineComponent({
  name: 'DeliveryAddressItem',

  components: {
    DeliveryAddressForm,
    ItemPanel,
  },

  props: {
    addressNumber: {
      type: Number,
      required: true,
    },
    deliveryAddress: {
      type: Object as PropType<IDeliveryAddress>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    withZones: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['select', 'wasRemoved', 'wasUpdated'],

  setup() {
    return {
      deliveryAddressStore: useDeliveryAddress(),
    };
  },

  data() {
    return {
      isFormOpened: false,
    };
  },

  methods: {
    toggleForm(state: boolean) {
      this.isFormOpened = state;
    },
  },
});
