
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductButton',

  props: {
    isInCart: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['add', 'increase', 'decrease'],
});
