import jsonApi from '@/libs/jsonapi';

interface IProductOption {
  id: string;
  title: string;
  description: string;
  weight: string;
  protein: string;
  fat: string;
  carbohydrate: string;
  calories: string;
  isInStopList: boolean;
  isHidden: boolean;
  hasStopPrice: boolean;
  price: string;
  priceWithoutDiscount: string;
  quantity: number;
  cookingTime: number;
  isAvailableForPurchase: boolean;
  discountPercent: string;
}

jsonApi.define<IProductOption>('product_option', {
  title: '',
  description: '',
  weight: '',
  protein: '',
  fat: '',
  carbohydrate: '',
  calories: '',
  isInStopList: false,
  isHidden: false,
  hasStopPrice: false,
  price: '',
  priceWithoutDiscount: '',
  quantity: 0,
  cookingTime: 0,
  isAvailableForPurchase: false,
  discountPercent: '',
});

export { IProductOption as default };
