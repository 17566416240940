
import { defineComponent } from 'vue';

import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';

import CodeForm from './CodeForm/CodeForm.vue';
import PhoneForm from './PhoneForm/PhoneForm.vue';

type AUTH_STEP = 'phone' | 'code';

const PHONE_STEP: AUTH_STEP = 'phone';
const CODE_STEP: AUTH_STEP = 'code';

const CAPTCHA_ERROR_TEXT = 'Ошибка выполнения капчи. Попробуйте еще раз';

export default defineComponent({
  name: 'AuthForm',

  components: {
    CodeForm,
    PhoneForm,
  },

  emits: ['authWasSuccessed'],

  setup() {
    return {
      authStore: useAuth(),
      companyStore: useCompany(),
    };
  },

  data(): {
    step: AUTH_STEP;
    phone?: string;
    code?: string;
    phoneStepErrorMessage?: string;
    codeStepErrorMessage?: string;
    allowSendAt?: Date;
    isRegistration?: boolean;
    captchaError: string,
    captcha: string | null,
    PHONE_STEP: AUTH_STEP;
    CODE_STEP: AUTH_STEP;
    isVisibleCaptcha: boolean,
    } {
    return {
      PHONE_STEP,
      CODE_STEP,

      step: PHONE_STEP,

      phone: undefined,
      code: undefined,
      captcha: null,

      phoneStepErrorMessage: undefined,
      codeStepErrorMessage: undefined,

      allowSendAt: undefined,
      isRegistration: undefined,

      captchaError: '',
      isVisibleCaptcha: false,
    };
  },

  methods: {
    clearErrorCaptcha() {
      this.captchaError = '';
    },

    goToFirstStep() {
      this.step = PHONE_STEP;
      this.phone = undefined;
      this.phoneStepErrorMessage = undefined;
      this.captcha = null;
      this.isVisibleCaptcha = false;
    },

    sendNewCode() {
      this.step = PHONE_STEP;
      this.captcha = null;
      this.phoneStepErrorMessage = undefined;
    },

    async sendCode(phone: string, captcha: string) {
      this.phone = phone;
      this.captcha = captcha;
      this.codeStepErrorMessage = undefined;

      let response: Record<string, any>;

      try {
        response = await this.authStore.sendCode(phone, captcha);
      } catch (error) {
        this.phoneStepErrorMessage = (error as Record<string, any>).title;

        return;
      }

      if (response.captcha) {
        this.isVisibleCaptcha = true;

        return;
      }

      if (!response.captcha_success) {
        this.captchaError = CAPTCHA_ERROR_TEXT;
        this.captcha = null;

        return;
      }

      this.allowSendAt = new Date(response.allowSend_at);
      this.isRegistration = response.is_registration;

      this.step = CODE_STEP;
    },

    async validateCode(code: string) {
      this.authStore.setLoading(true);

      try {
        await this.authStore.validateCode(this.phone!, code);
      } catch (error: unknown) {
        this.authStore.setLoading();

        this.codeStepErrorMessage = (error as Record<string, any>).title;

        return;
      }

      await this.authStore.fetchActiveUser('mo_base_user,favorite_products');

      if (this.authStore.activeUser && this.companyStore.activeCompany?.hasBonusPoints) {
        this.authStore.fetchActiveUser('bonus_info');
      }

      this.authStore.setLoading();

      if (this.authStore.redirectRoute) {
        this.$router.push({ name: this.authStore.redirectRoute });
      }

      this.$emit('authWasSuccessed');
    },
  },
});
