
import { defineComponent } from 'vue';

import useAuth from '@/stores/auth';
import useDeliveryAddress from '@/stores/deliveryAddress';
import useUserCustomer from '@/stores/userCustomer';

import DeliveryAddressesList from '@/components/company/DeliveryAddresses/List/List.vue';
import UserCustomersList from '@/components/company/UserCustomers/List/List.vue';
import UserInfoPanel from '@/components/company/UserProfile/InfoPanel/InfoPanel.vue';
import OrderHistoryPanel from '@/components/company/UserProfile/OrderHistoryPanel/OrderHistoryPanel.vue';

export default defineComponent({
  name: 'ProfileView',

  components: {
    OrderHistoryPanel,
    UserInfoPanel,
    DeliveryAddressesList,
    UserCustomersList,
  },

  setup() {
    return {
      authStore: useAuth(),
      deliveryAddressesStore: useDeliveryAddress(),
      userCustomerStore: useUserCustomer(),
    };
  },

  computed: {
    selectedUserCustomerId(): string | undefined {
      return this.userCustomerStore.userCustomers?.find(({ isDefault }) => isDefault)?.id;
    },

    selectedDeliveryAddressId(): string | undefined {
      return this.deliveryAddressesStore.deliveryAddresses?.find(
        ({ isDefault }) => isDefault,
      )?.id;
    },
  },
});
