import jsonApi from '@/libs/jsonapi';

enum bonusTypes {
  ACCRUAL = 'accrual',
  WRITEOFF = 'write_off',
  EXPIRE = 'expire',
}

function isIncreaseType(type: any): boolean {
  return type === bonusTypes.ACCRUAL;
}

interface IBonusTransaction {
  createdAt: string;
  sum: string;
  type: bonusTypes;
  title: string;
  description: string;
  isHidden: boolean;
}

jsonApi.define<IBonusTransaction>(
  'bonus_transaction',
  {
    createdAt: '',
    sum: '',
    type: bonusTypes.ACCRUAL,
    title: '',
    description: '',
    isHidden: false,
  },
  {
    collectionPath: 'user/bonus-transactions',
  },
);

export { IBonusTransaction as default, isIncreaseType };
