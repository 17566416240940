
import { defineComponent, PropType } from 'vue';

import { IPage } from '@/types';

import { lockScrollBody, unlockScrollBody } from '@/helpers/scrollBody';

export default defineComponent({
  name: 'HamburgerMenu',

  props: {
    pages: {
      type: Array as PropType<IPage[]>,
      default: () => ([]),
    },
    hasDeliveryZone: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleMenu(state = false) {
      this.isOpen = state;

      if (this.isOpen) {
        lockScrollBody();
      } else {
        unlockScrollBody();
      }
    },
  },
});
