import jsonApi from '@/libs/jsonapi';

interface ICoupon {
  code: string;
  name: string;
  isActivated: boolean;
  dateStart: string;
  dateEnd: string;
  minOrderPrice: string;
}

jsonApi.define<ICoupon>(
  'coupon',
  {
    code: '',
    name: '',
    isActivated: false,
    dateStart: '',
    dateEnd: '',
    minOrderPrice: '',
  },
  {
    collectionPath: 'user/coupons',
  },
);

export default ICoupon;
