
import { defineComponent, ref } from 'vue';

import { IOrder, ORDER_STATUS } from '@/models/order';

import useOrder from '@/stores/order';

const YEARS_COUNT = 20;
const YEARS_AFTER_CURRENT = 3;
const DAYS_IN_YEAR = 365;
const MIN_HEIGHT = 250;
const START_POSITION_ORDER_BY_Y = 10;
const ORDER_INFO_HEIGHT = 30;
const LOGO_COLORS = [
  '655294',
  '0F381E',
  'B9E1BB',
  'C6D6E4',
  'CC7E7E',
  'FFE1A9',
];

interface IRestructuredOrder {
  id: string;
  date: string;
  position: string;
  logoColor: string;
}

export default defineComponent({
  name: 'OrderHistoryPanel',

  setup() {
    return {
      orderStore: useOrder(),
    };
  },

  data(): {
    maxYear: number,
    years: number[],
    height: number,
    orders: IOrder[],
    restructuredOrders: any,
    } {
    const height = ORDER_INFO_HEIGHT;
    const currentDate = new Date();
    const maxYear = new Date(
      currentDate.setFullYear(
        currentDate.getFullYear() + YEARS_AFTER_CURRENT,
      ),
    ).getFullYear();

    return {
      maxYear,
      years: [],
      height,
      orders: [],
      restructuredOrders: null,
    };
  },

  computed: {
    containerHeight(): string {
      return `height: ${
        this.height < MIN_HEIGHT
          ? MIN_HEIGHT
          : this.height
      }px`;
    },
  },

  async mounted() {
    this.initYears();
    await this.fetchOrders();

    this.restructuredOrders = this.getRestructuredOrders();
  },

  methods: {
    async fetchOrders() {
      this.orders = await this.orderStore.fetch({
        'filters[status]': [ORDER_STATUS.COMPLETED, ORDER_STATUS.CANCELED].join(','),
      });
    },

    getRestructuredOrders(): Record<string, IRestructuredOrder[]> {
      return this.orders.reduce((
        acc: Record<string, IRestructuredOrder[]>,
        item: IOrder,
        index: number,
      ) => {
        const completedAt = item.completedAt as string;

        const currentYear = new Date(completedAt).getFullYear();
        const positionX = this.getPositionOrder(completedAt, currentYear);
        const positionY = START_POSITION_ORDER_BY_Y + ORDER_INFO_HEIGHT * index;

        if (!acc[currentYear]) {
          acc[currentYear] = [];
        }

        acc[currentYear].push({
          id: item.id,
          date: this.getFormattedDate(completedAt),
          position: `left: ${positionX}%; bottom: ${positionY}px`,
          logoColor: `background-color: #${
            LOGO_COLORS[Math.floor(Math.random() * LOGO_COLORS.length)]
          }`,
        });

        this.height += ORDER_INFO_HEIGHT;

        return acc;
      }, {});
    },

    initYears() {
      for (
        let i = 0;
        i < YEARS_COUNT;
        i += 1
      ) {
        this.years.push(this.maxYear - i);
      }
    },

    getPositionOrder(date: string, currentYear: number) {
      const diff = new Date(date).getTime() - new Date(currentYear, 0, 0).getTime();
      const oneDay = 1000 * 60 * 60 * 24;
      const numberDayReverse = DAYS_IN_YEAR - Math.floor(diff / oneDay);

      return (numberDayReverse / DAYS_IN_YEAR) * 100;
    },

    getFormattedDate(date: string) {
      return new Date(date).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
});
