
import { defineComponent, PropType } from 'vue';
import vClickOutside from 'click-outside-vue3';

interface ILimit {
  value: number;
  max?: number;
  min?: number;
}

interface IMenuItem {
  title: string;
  id?: string;
  type?: string;
  action?: string;
  route?: string;
  isActive?: boolean;
  routeSettings?: {
    name: string,
    params: {
      customPage: string,
    },
  },
}

type TBreakpoints =
  'smallMobile'
  | 'mediumMobile'
  | 'largeMobile'
  | 'smallDesktop'
  | 'mediumDesktop'
  | 'largeDesktop'
  | 'extralargeDesktop';

const LIMITS: {
  [key in TBreakpoints]: ILimit;
} = {
  smallMobile: {
    value: 1,
    max: 320,
  },
  mediumMobile: {
    value: 2,
    min: 320,
    max: 480,
  },
  largeMobile: {
    value: 4,
    min: 480,
    max: 768,
  },
  smallDesktop: {
    value: 3,
    min: 768,
    max: 1024,
  },
  mediumDesktop: {
    value: 5,
    min: 1024,
    max: 1200,
  },
  largeDesktop: {
    value: 6,
    min: 1200,
    max: 1400,
  },
  extralargeDesktop: {
    value: 8,
    min: 1400,
  },
};

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    items: {
      type: Array as PropType<IMenuItem[]>,
      default: () => ([]),
    },
    isMiniArrow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      windowWidth: 0,
      isOpen: false,
    };
  },

  computed: {
    categoriesLimit(): number {
      return this.getCategoriesLimitByBreakpoint();
    },

    mainCategoriesList(): IMenuItem[] {
      if (this.categoriesLimit === -1) {
        return this.items;
      }

      return this.items.slice(0, this.categoriesLimit);
    },

    hiddenCategoriesList(): IMenuItem[] {
      if (this.categoriesLimit === -1) {
        return [];
      }

      return this.items.slice(this.categoriesLimit);
    },
  },

  created() {
    window.addEventListener('resize', this.redrawLayout);

    this.redrawLayout();
  },

  unmounted() {
    window.removeEventListener('resize', this.redrawLayout);
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },

    hideSubMenu() {
      this.isOpen = false;
    },

    select(item: IMenuItem) {
      this.hideSubMenu();

      this.$emit(item.action || 'click', item);
    },

    redrawLayout() {
      this.windowWidth = window.innerWidth;
    },

    getCategoriesLimitByBreakpoint() {
      let result = -1;

      (Object.keys(LIMITS) as Array<keyof typeof LIMITS>).forEach((sizeName) => {
        if (
          this.windowWidth > (LIMITS[sizeName].min || 0)
          && this.windowWidth
            <= (LIMITS[sizeName].max || this.windowWidth)
            && this.items.length > LIMITS[sizeName].value
        ) {
          result = LIMITS[sizeName].value;
        }
      });

      return result;
    },
  },
});
