import jsonApi from '@/libs/jsonapi';

interface ICustomPage {
  id: string;
  name: string;
  slug: string;
  templateUrl: string;
  order: string;
  isActive: boolean;
  isDisplayOnOverview: boolean;
}

jsonApi.define<ICustomPage>('custom_page', {
  name: '',
  slug: '',
  templateUrl: '',
  order: '',
  isActive: false,
  isDisplayOnOverview: false,
});

export default ICustomPage;
