export default function getFormatHours(timeInMinutes: number): string {
  const currentDate = new Date();
  const timezoneOffset = currentDate.getTimezoneOffset() / 60;

  let hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes - hours * 60;

  hours -= timezoneOffset;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
