
import { defineComponent, PropType } from 'vue';

import {
  IDeliveryType,
  DeliveryType,
} from '@/models/deliveryType';

import useOrder from '@/stores/order';

import TypePanel from '@/components/shared/TypePanel/TypePanel.vue';

export default defineComponent({
  name: 'DeliveryTypesPanel',

  components: {
    TypePanel,
  },

  props: {
    deliveryTypes: {
      type: Array as PropType<IDeliveryType[]>,
      required: true,
    },
    selectedId: {
      type: String,
      default: undefined,
    },
  },

  emits: ['select'],

  setup() {
    return {
      orderStore: useOrder(),
    };
  },

  computed: {
    selectedType(): IDeliveryType | undefined {
      return this.deliveryTypes.find(
        ({ deliveryTypeId }) => deliveryTypeId === this.selectedId,
      );
    },
  },

  watch: {
    'selectedType.isAvailable': {
      handler(value) {
        if (value) {
          return;
        }

        const newSelectedType = this.deliveryTypes.find(({ isAvailable }) => isAvailable);

        if (!newSelectedType) {
          return;
        }

        this.selectDeliveryType(newSelectedType.deliveryTypeId);
      },
    },
  },

  methods: {
    selectDeliveryType(id: DeliveryType) {
      this.orderStore.update({deliveryTypeId: id});
    },
  },
});
