
import { defineComponent } from 'vue';

import {
  ANIMALS,
  START_CALENDAR_YEAR,
  ZODIACS,
} from './zodiacs';

const ASSETS_PATH = './images';

export default defineComponent({
  name: 'UserInfo',

  props: {
    birthday: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      text: '',
      zodiacSignIcon: '',
      eastCalendarAnimalIcon: '',
    };
  },

  mounted() {
    const date = new Date(this.birthday);

    this.init(date);
  },

  methods: {
    init(date: Date) {
      let selectedZodiac: string[] = [];

      const position = (date.getFullYear() - START_CALENDAR_YEAR) % 12;
      const [eastCalendarAnimalTitle, eastCalendarAnimalCode] = ANIMALS[position];

      // Приведение номера дня к формату 0X из X
      const day = `0${date.getDate()}`.substr(-2);
      const dateNumber = +`${date.getMonth() + 1}${day}`;

      /* eslint-disable-next-line */
      for (let zodiac of ZODIACS) {
        const [[startDay, endDay], sign] = zodiac;

        if (startDay > endDay) {
          if (startDay <= dateNumber || endDay >= dateNumber) {
            selectedZodiac = sign;

            break;
          }
        } else if (startDay <= dateNumber && endDay >= dateNumber) {
          selectedZodiac = sign;

          break;
        }
      }

      const [zodiacCode, zodiacTitle] = selectedZodiac;

      this.text = `${zodiacTitle} в год ${eastCalendarAnimalTitle}`;

      /* eslint-disable */
      this.zodiacSignIcon = require(`${ASSETS_PATH}/${zodiacCode}.svg`);
      this.eastCalendarAnimalIcon = require(`${ASSETS_PATH}/east-calendar/${eastCalendarAnimalCode}.svg`);
      /* eslint-enable */
    },
  },
});
