
import { defineComponent, PropType } from 'vue';
import vClickOutside from 'click-outside-vue3';

import ICompany from '@/models/company';

import domainWithProtocol from '@/helpers/domainWithProtocol';

export default defineComponent({
  name: 'CompaniesDropDown',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    parentCompany: {
      type: Object as PropType<ICompany>,
      required: true,
    },
    selectedCompanyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      domainWithProtocol,
      isOpened: false,
    };
  },

  computed: {
    selectedCompany(): ICompany | null {
      return this.parentCompany.children!.find(
        (c) => c.id === this.selectedCompanyId,
      ) || null;
    },
  },

  methods: {
    toggle(state?: boolean) {
      this.isOpened = state === undefined ? !this.isOpened : state;
    },
  },
});
