<template>
  <div :class="['code-group', { 'code-group--error': errorMessage }]">
    <div class="code-group__inputs">
      <span
        v-for="(item, index) in Array(codeLength)"
        :key="index"
        :class="[
          'code-group__input-wrapper',
          { 'code-group__input-wrapper--loading': isLoading },
        ]"
      >
        <input
          :ref="setFieldRef"
          autocomplete="off"
          type="tel"
          maxlength="1"
          @focusout="setCode"
          @input="onInput(index, $event)"
          @keydown="onKeydown(index, $event)"
          @keypress="onKeypress"
          @paste="onPaste(index, $event)"
        />

        <div v-if="isLoading" class="code-group__preloader">
          <MoPreloader :is-multiple="false" :index-circle="index" />
        </div>
      </span>
    </div>

    <div class="input-error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeUpdate } from 'vue';
import { MoPreloader } from '@mo/uikit';

import setFocusAtEnd from '@/helpers/setFocusAtEnd';

export default defineComponent({
  components: {
    MoPreloader,
  },

  props: {
    codeLength: {
      type: Number,
      default: 4,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setCode'],

  setup() {
    let fields = [];

    const setFieldRef = (el) => {
      fields.push(el);
    };

    onBeforeUpdate(() => {
      fields = [];
    });

    return {
      fields,
      setFieldRef,
    };
  },

  data() {
    return {
      code: [],
    };
  },

  methods: {
    onInput(index, event) {
      event.stopPropagation();

      if (!this.fields[index].value) {
        return;
      }

      this.code[index] = this.fields[index].value;

      this.focusNextOrBlurCurrent(index);
    },

    onKeypress(event) {
      event.stopPropagation();

      const keyCode = event.which || event.keyCode;

      if (
        !(
          this.isNumberKeyCode(keyCode)
          || this.isTab(keyCode)
          || this.isBackspace(keyCode)
          || this.isMetaKey(event, keyCode)
        )
      ) {
        event.preventDefault();
      }
    },

    onKeydown(index, event) {
      event.stopPropagation();

      const keyCode = event.which || event.keyCode;

      if (this.isBackspace(keyCode)) {
        this.backspaceHandler(index, event);
      }

      if (this.isLeftArrow(keyCode) || this.isRightArrow(keyCode)) {
        this.arrowHandler(index, keyCode, event);
      }
    },

    onPaste(index, event) {
      event.preventDefault();

      let pasteData = (event.clipboardData || window.clipboardData).getData('text');

      pasteData = pasteData
        .replace(/\s/g, '')
        .substr(0, this.codeLength - index)
        .split('');

      this.pasteData(index, pasteData);
    },

    setCode() {
      if (this.code.length !== this.codeLength) {
        return;
      }

      this.$emit('setCode', this.code.join(''));
    },

    backspaceHandler(index, event) {
      this.fields[index].value = '';

      if (index > 0) {
        setFocusAtEnd(this.fields[index - 1]);
      }

      event.preventDefault();
    },

    arrowHandler(index, keyCode, event) {
      const newIndex = this.isLeftArrow(keyCode)
        ? index - 1
        : index + 1;

      if (newIndex < 0 || newIndex >= this.codeLength) {
        return;
      }

      setFocusAtEnd(this.fields[newIndex]);

      event.preventDefault();
    },

    focusNextOrBlurCurrent(index) {
      if (index !== this.codeLength - 1) {
        setFocusAtEnd(this.fields[index + 1]);

        return;
      }

      this.fields[index].blur();
    },

    pasteData(index, pasteData) {
      for (let i = 0; i < this.fields.length; i += 1) {
        const numericValue = Number(pasteData[i]);

        if (Number.isNaN(numericValue)) {
          return;
        }

        this.fields[i + index].value = pasteData[i];
        this.code[i + index] = pasteData[i];
      }

      if (pasteData.length + index < this.codeLength) {
        setFocusAtEnd(this.fields[pasteData.length]);
      } else {
        this.fields[index].blur();
      }
    },

    isNumberKeyCode(keyCode) {
      return keyCode >= 48 && keyCode <= 57;
    },

    isTab(keyCode) {
      return keyCode === 9;
    },

    isBackspace(keyCode) {
      return keyCode === 8;
    },

    isMetaKey(e, keyCode) {
      return e.metaKey && keyCode === 118;
    },

    isLeftArrow(keyCode) {
      return keyCode === 37;
    },

    isRightArrow(keyCode) {
      return keyCode === 39;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.code-group {
  width: auto;

  &--error {
    input {
      border-color: $error-red !important;
    }

    &::after {
      display: none;
    }
  }

  &__inputs {
    display: flex;
    justify-content: center;
  }

  &__input-wrapper {
    position: relative;

    &--loading {
      input {
        color: $light-grey;
        cursor: default;
      }
    }

    input {
      padding: 8px;
      text-align: center;
    }

    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  &__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 47%;
  }
}
</style>
