
import { defineComponent } from 'vue';

import { INFO_PAGES } from '@/constants';

import MainMenu from '@/components/shared/MainMenu/MainMenu.vue';

export default defineComponent({
  name: 'InfoView',

  components: {
    MainMenu,
  },

  data() {
    return {
      isMounted: false,
    };
  },

  computed: {
    currentMenuItem(): {
    id: string;
    title: string;
    route: string;
    } | null {
      return INFO_PAGES.find(({ route }) => route === this.$route.name) || null;
    },
  },

  mounted() {
    this.isMounted = true;
  },
});
