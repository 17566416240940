import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a41d19a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quantity-control" }
const _hoisted_2 = { class: "quantity-control__controls controls" }
const _hoisted_3 = { class: "quantity-control__total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["controls__item controls__item--decrease", {'controls__item--locked': _ctx.isBlockDecreaseControl && _ctx.quantity <= _ctx.MIN_VALUE}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('decrease')))
      }, null, 2),
      _createElementVNode("button", {
        class: "controls__item controls__item--increase",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('increase')))
      })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.quantity), 1)
  ]))
}