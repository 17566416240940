import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-057093f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-customer" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "content__name" }
const _hoisted_4 = { class: "content__phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCustomerForm = _resolveComponent("UserCustomerForm")!
  const _component_ItemPanel = _resolveComponent("ItemPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemPanel, {
      "is-default": _ctx.isSelected,
      "is-opened": _ctx.isFormOpened,
      onSetDefault: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select'))),
      onOpenForm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleForm(true))),
      onSaveForm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$refs.form.save()))
    }, {
      preview: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.userCustomer.fullName), 1),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.userCustomer.phone), 1)
          ])
        ])
      ]),
      form: _withCtx(() => [
        _createVNode(_component_UserCustomerForm, {
          ref: "form",
          "user-customer": _ctx.userCustomer,
          onClose: _ctx.toggleForm,
          onWasRemoved: _cache[0] || (_cache[0] = (id) => _ctx.$emit('wasRemoved', id))
        }, null, 8, ["user-customer", "onClose"])
      ]),
      _: 1
    }, 8, ["is-default", "is-opened"])
  ]))
}