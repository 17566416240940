
import { defineComponent } from 'vue';
import Loading from 'vue-loading-overlay';

import getFormatTime from '@/helpers/getFormatTime';

const REQUEST_DELAY = 5;

export default defineComponent({
  name: 'SBPPanel',

  components: {
    Loading,
  },

  props: {
    totalPrice: {
      type: String,
      default: '',
    },
    qrCodeImg: {
      type: String,
      default: '',
    },
    allowSendAt: {
      type: Date || null,
      default: null,
    },
    errorText: {
      type: String,
      default: '',
    },
  },

  emits: ['process', 'retry', 'timeOver', 'close', 'checkPayment'],

  setup() {
    return {
      getFormatTime,
    };
  },

  data() {
    return {
      timeLeft: 0,
      requestTimer: 0,
      timer: 0,
      isLoading: !this.qrCodeImg,
    };
  },

  watch: {
    errorText() {
      if (!this.timer) {
        return;
      }

      this.clearTimer();
    },

    qrCodeImg(value) {
      if (!value) {
        return;
      }

      this.isLoading = false;
    },
  },

  mounted() {
    if (this.timer) {
      return;
    }

    this.setTimer();
  },

  unmounted() {
    clearInterval(this.timer);
  },

  methods: {
    setTimer() {
      const currentDate = new Date();

      this.timeLeft = Math.ceil(
        (this.allowSendAt.getTime() - currentDate.getTime()) / 1000,
      );

      this.timer = setInterval(() => {
        this.timeLeft -= 1;
        this.requestTimer += 1;

        if (this.requestTimer >= REQUEST_DELAY) {
          this.$emit('checkPayment');

          this.requestTimer = 0;
        }

        if (this.timeLeft <= 0) {
          this.$emit('timeOver');

          this.clearTimer();
        }
      }, 1000);
    },

    clearTimer() {
      clearInterval(this.timer);
      this.timer = 0;
    },
  },
});
