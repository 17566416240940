export const ANIMALS = [
  ['Крысы', 'rat'],
  ['Быка', 'bull'],
  ['Тигра', 'tiger'],
  ['Кролика', 'rabbit'],
  ['Дракона', 'dragon'],
  ['Змея', 'snake'],
  ['Лошади', 'horse'],
  ['Козы', 'goat'],
  ['Обезьяны', 'monkey'],
  ['Петуха', 'cock'],
  ['Собаки', 'dog'],
  ['Кабана', 'pig'],
];
export const START_CALENDAR_YEAR = 1900;
export const ZODIACS = new Map([
  [
    [321, 419],
    ['aries', 'Овен'],
  ],
  [
    [420, 520],
    ['taurus', 'Телец'],
  ],
  [
    [521, 620],
    ['gemini', 'Близнецы'],
  ],
  [
    [621, 722],
    ['cancer', 'Рак'],
  ],
  [
    [723, 822],
    ['leo', 'Лев'],
  ],
  [
    [823, 922],
    ['virgo', 'Дева'],
  ],
  [
    [923, 1022],
    ['libra', 'Весы'],
  ],
  [
    [1023, 1121],
    ['scorpion', 'Скорпион'],
  ],
  [
    [1122, 1221],
    ['sagittarius', 'Стрелец'],
  ],
  [
    [1222, 119],
    ['capricorn', 'Козерог'],
  ],
  [
    [120, 218],
    ['aquarius', 'Водолей'],
  ],
  [
    [219, 320],
    ['pisces', 'Рыбы'],
  ],
]);
