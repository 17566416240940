
import { defineComponent, PropType } from 'vue';

import { IPaymentType, PAYMENT_TYPE } from '@/models/paymentType';

import useOrder from '@/stores/order';
import usePaymentType from '@/stores/paymentType';

import TypePanel from '@/components/shared/TypePanel/TypePanel.vue';

export default defineComponent({
  name: 'PaymentTypesPanel',

  components: {
    TypePanel,
  },

  props: {
    paymentsTypes: {
      type: Array as PropType<IPaymentType[]>,
      required: true,
    },
    selectedPaymentTypeId: {
      type: String,
      default: undefined,
    },
  },

  setup() {
    return {
      orderStore: useOrder(),
      paymentTypeStore: usePaymentType(),
    };
  },

  methods: {
    selectPaymentType(id: PAYMENT_TYPE) {
      this.orderStore.update({
        paymentTypeId: id,
      });

      this.paymentTypeStore.setType(id);
    },
  },
});
