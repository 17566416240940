import { defineStore } from 'pinia';
import { Router } from 'vue-router';

import { PAYMENT_TYPE } from '@/models/paymentType';

import Sbp from '@/strategies/paymentType/sbp';
import Cash from '@/strategies/paymentType/cash';
import CardOffline from '@/strategies/paymentType/cardOffline';
import CardOnline from '@/strategies/paymentType/cardOnline';

interface IPaymentTypeState {
  isOpenSBPModal: boolean,
  type: PAYMENT_TYPE | null,
  currentStrategy: any,
}

const STRATEGIES = {
  [PAYMENT_TYPE.CASH]: Cash,
  [PAYMENT_TYPE.SBP]: Sbp,
  [PAYMENT_TYPE.CARD_ONLINE]: CardOnline,
  [PAYMENT_TYPE.CARD_OFFLINE]: CardOffline,
};

const usePaymentType = defineStore('paymentType', {
  state(): IPaymentTypeState {
    return {
      isOpenSBPModal: false,
      type: null,
      currentStrategy: null,
    };
  },

  getters: {
    isSbp(state: IPaymentTypeState) {
      return state.type === PAYMENT_TYPE.SBP;
    },
  },

  actions: {
    setType(type: PAYMENT_TYPE) {
      this.type = type;
    },

    initStrategies(router: Router, window: Window) {
      if (!this.type) {
        return;
      }

      this.currentStrategy = new STRATEGIES[this.type](router, window);
    },

    toPostPaidPage(order: Partial<{ id: string, type: string, redirectUrl: string }>) {
      if (!this.currentStrategy) {
        return;
      }

      this.currentStrategy.toPostPaidPage(order);
    },

    openSbpModal() {
      this.isOpenSBPModal = true;
    },

    closeSbpModal() {
      this.isOpenSBPModal = false;
    },
  },
});

export default usePaymentType;
