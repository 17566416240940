
import { defineComponent, PropType } from 'vue';

import { IPage } from '@/types';

const LS_VARIABLE_NAME = 'isOpenSectionMenu';

export default defineComponent({
  name: 'SectionMenu',

  props: {
    pages: {
      type: Array as PropType<IPage[]>,
      default: () => ([]),
    },

    hasDeliveryZone: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isOpen: true,
    };
  },

  mounted() {
    const localStoreValue = localStorage.getItem(LS_VARIABLE_NAME);

    if (localStoreValue) {
      this.isOpen = JSON.parse(localStoreValue);
    }
  },

  methods: {
    toggleMenu(state = false) {
      this.isOpen = state;

      localStorage.setItem(LS_VARIABLE_NAME, JSON.stringify(state));
    },
  },
});
