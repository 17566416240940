
import { defineComponent } from 'vue';

import useCompany from '@/stores/company';

import CompaniesList from '@/components/shared/Companies/CompaniesList/CompaniesList.vue';

export default defineComponent({
  name: 'ChildCompaniesView',

  components: {
    CompaniesList,
  },

  setup() {
    return {
      companyStore: useCompany(),
    };
  },
});
