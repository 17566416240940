import convertObjectKeysToSnakeCase from '@/helpers/convertObjectKeysToSnakeCase';

export default {
  name: 'convert-to-snake-case',
  req: (payload: Record<any, any>): any => {
    if (payload.req.method === 'GET' || payload.req.method === 'DELETE') {
      return payload;
    }

    const convertedAttributes = convertObjectKeysToSnakeCase(payload.req.data);
    const changedPayload = payload;

    changedPayload.req.data = convertedAttributes;

    return changedPayload;
  },
};
