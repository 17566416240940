import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24ca1c3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-header" }
const _hoisted_2 = { class: "base-header__main-content main-content" }
const _hoisted_3 = { class: "main-content__logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "main-content__title" }
const _hoisted_6 = { class: "base-header__aside-content aside-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.homeRoute }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", { src: _ctx.logoUrl }, null, 8, _hoisted_4)
          ])
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("h1", _hoisted_5, [
        _createVNode(_component_router_link, { to: _ctx.homeRoute }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}