import { defineStore } from 'pinia';

import IBonusInfo from '@/models/bonusInfo';
import { IUser } from '@/models/user';

import useFavorite from '@/stores/favorite';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';
import axios from '@/libs/axios';

type IAuthState = {
  activeUser?: IUser | null;
  isAuthWindowOpened: boolean;
  isLoading: boolean;
  redirectRoute: string;
  ordersInDeliveryCount: number | null;
};

const BONUS_INFO_VARIABLE_NAME = 'bonusInfo';

const useAuth = defineStore('auth', {
  state(): IAuthState {
    return {
      isLoading: false,
      redirectRoute: '',
      activeUser: undefined,
      isAuthWindowOpened: false,
      ordersInDeliveryCount: null,
    };
  },

  getters: {
    moBaseUser(state: IAuthState) {
      return state.activeUser?.moBaseUser;
    },

    bonusInfo(state: IAuthState) {
      if (!state.activeUser) {
        return null;
      }

      const { bonusInfo } = state.activeUser;

      if (bonusInfo) {
        return bonusInfo;
      }

      const localStoreValue = localStorage.getItem(BONUS_INFO_VARIABLE_NAME);

      if (localStoreValue) {
        return JSON.parse(localStoreValue) as IBonusInfo;
      }

      return null;
    },

    authApiUrls() {
      const prefix = `/auth/${useCompany().activeCompany!.login}`;

      return {
        phoneSendCode: `${prefix}/phone/send-code`,
        phoneConfirmCode: `${prefix}/phone/confirm`,
        logout: `${prefix}/sign-out`,
      };
    },
  },

  actions: {
    clearActiveUser() {
      this.activeUser = undefined;
    },

    sendCode(phone?: string, captchaToken?: string) {
      return axios.post(this.authApiUrls.phoneSendCode, {
        phone,
        captcha_token: captchaToken,
      });
    },

    validateCode(phone: string, code: string) {
      return axios.post(this.authApiUrls.phoneConfirmCode, {
        code,
        phone,
      });
    },

    toggleAuthWindow(state: boolean, routeName?: string) {
      this.redirectRoute = routeName || '';

      this.isAuthWindowOpened = state;
    },

    logout() {
      return axios.post(this.authApiUrls.logout);
    },

    async fetchActiveUser(include: string): Promise<void> {
      this.isLoading = true;

      jsonApi.builderStack = [
        {
          path: `companies/${useCompany().activeCompany!.login}/user`,
        },
      ];

      let user: IUser | null;
      let userMeta: Record<string, any> | null = null;

      try {
        const { data, meta } = await jsonApi.get<IUser>({ include });

        user = data;
        userMeta = meta;
      } catch (error) {
        user = null;

        console.log('non authorized');
      } finally {
        this.isLoading = false;
      }

      if (user) {
        this.activeUser = { ...this.activeUser, ...user };

        if (user.bonusInfo) {
          this.setBonusInfoToLocalStorage(user.bonusInfo);
        }
      }

      this.ordersInDeliveryCount = userMeta?.orders_in_delivery_count as number || null;

      useFavorite().initFavorites(this.activeUser?.favoriteProducts);
    },

    setLoading(state = false) {
      this.isLoading = state;
    },

    setBonusInfoToLocalStorage(bonusInfo: IBonusInfo) {
      if (!bonusInfo) {
        return;
      }

      localStorage.setItem(BONUS_INFO_VARIABLE_NAME, JSON.stringify(bonusInfo));
    },

    clearBonusInfoInLocalStorage() {
      localStorage.removeItem(BONUS_INFO_VARIABLE_NAME);
    },
  },
});

export default useAuth;
