
import { defineComponent, PropType } from 'vue';

import IUserCustomer from '@/models/userCustomer';

import useUserCustomer from '@/stores/userCustomer';

import UserCustomerForm from '../Form/Form.vue';

import ItemPanel from '@/components/shared/ItemPanel/ItemPanel.vue';

export default defineComponent({
  name: 'UserCustomerItem',

  components: {
    ItemPanel,
    UserCustomerForm,
  },

  props: {
    userCustomer: {
      type: Object as PropType<IUserCustomer>,
      required: true,
    },

    isSelected: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['select', 'wasRemoved'],

  setup() {
    return {
      userCustomerStore: useUserCustomer(),
    };
  },

  data() {
    return {
      isFormOpened: false,
    };
  },

  methods: {
    toggleForm(state: boolean) {
      this.isFormOpened = state;
    },
  },
});
