
import { defineComponent } from 'vue';
import { MoSpinner } from '@mo/uikit';
import { useToast } from 'vue-toastification';

import { PAYMENT_ERRORS } from '@/constants';

import { IOrder } from '@/models/order';
import { IPaymentType, PAYMENT_TYPE } from '@/models/paymentType';
import { IOrderPayment, PAYMENT_STATUSES } from '@/models/orderPayment';

import useOrder from '@/stores/order';
import usePaymentType from '@/stores/paymentType';

import pluralize from '@/helpers/pluralize';
import TypePanel from '@/components/shared/TypePanel/TypePanel.vue';
import ModalDialog from '@/components/shared/ModalDialog/ModalDialog.vue';
import SBPPanel from '@/components/company/Order/SBPPanel/SBPPanel.vue';

interface IDeliveryItem {
  name: string,
  price: string,
}

const toast = useToast();

export default defineComponent({
  name: 'PaymentInDeliveryView',

  components: {
    MoSpinner,
    ModalDialog,
    SBPPanel,
    TypePanel,
  },

  props: {
    orderId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      orderStore: useOrder(),
      paymentTypeStore: usePaymentType(),
      pluralize,
    };
  },

  data(): {
    order: IOrder | null,
    payment: IOrderPayment | null,
    paymentErrorText: string,
    } {
    return {
      order: null,
      payment: null,
      paymentErrorText: '',
    };
  },

  computed: {
    paymentTypes(): IPaymentType[] {
      return this.orderStore.paymentsTypes.filter(
        ({ paymentTypeId }) => [
          PAYMENT_TYPE.SBP, PAYMENT_TYPE.CARD_ONLINE,
        ].includes(paymentTypeId),
      );
    },

    allItemsCount(): number {
      if (!this.order || !this.order.items) {
        return 0;
      }

      return this.order.items?.reduce(
        (count: number, { quantity } : { quantity: number}) => count + quantity, 0,
      );
    },

    delivery(): { restaurants: IDeliveryItem[], main: IDeliveryItem | null } {
      if (!this.order || !this.order.deliveryPrice) {
        return {
          restaurants: [],
          main: null,
        };
      }

      return this.order.deliveryPrice.reduce(
        (acc: { restaurants: IDeliveryItem[], main: IDeliveryItem | null}, item) => {
          if (item.name) {
            acc.restaurants.push(item);
          } else {
            acc.main = item;
          }

          return acc;
        }, {
          restaurants: [],
          main: null,
        },
      );
    },
  },

  async created() {
    this.orderStore.getPaymentTypes();
    this.order = await this.orderStore.getById(this.orderId, {
      include: 'items,items.product',
    });
  },

  methods: {
    selectPaymentType(id: PAYMENT_TYPE) {
      this.paymentTypeStore.setType(id);
    },

    async process() {
      if (!this.order || !this.paymentTypeStore.type) {
        return;
      }

      let order = null;

      try {
        order = await this.orderStore.payOrder({
          ...this.order,
          paymentTypeId: this.paymentTypeStore.type,
        });
      } catch (e: any) {
        if (e.response.data.errors[0].title) {
          toast.error(e.response.data.errors[0].title);
        }

        return;
      }

      this.payment = order?.payment || null;

      this.resetPaymentError();

      if (!order || !order.paymentTypeId) {
        return;
      }

      this.paymentTypeStore.initStrategies(this.$router, window);

      if (this.paymentTypeStore.isSbp) {
        this.paymentTypeStore.openSbpModal();
      } else {
        this.paymentTypeStore.toPostPaidPage({
          id: order.id,
          type: order.paymentTypeId,
          redirectUrl: this.payment?.redirectFormUrl || '',
        });
      }
    },

    processSbp() {
      window.location.href = String(this.payment?.sbpQrUrl);
    },

    async checkPayment() {
      if (!this.payment) {
        return;
      }

      const payment = (await this.orderStore.getPayment(this.payment.id)).data;

      if (payment.status === PAYMENT_STATUSES.successful && this.orderStore.activeOrder?.id) {
        this.paymentTypeStore.toPostPaidPage({ id: this.orderStore.activeOrder.id });
      } else if (payment.status === PAYMENT_STATUSES.failed) {
        this.setPaymentError(PAYMENT_ERRORS.serverError);
      }
    },

    setPaymentError(text: string) {
      this.paymentErrorText = text;
    },

    resetPaymentError() {
      this.paymentErrorText = '';
    },
  },
});
