import { defineStore } from 'pinia';
import { isEmpty } from 'lodash';

import { CATALOG_MENU_ITEM_TYPE } from '@/constants';

import IProduct, { TProductSort, isProductSort } from '@/models/product';
import IGroup, { IProductGroupsQueryParam } from '@/models/group';
import IKind from '@/models/kind';
import IKitchen from '@/models/kitchen';

import useProduct from './product';
import useGroup from './group';
import useKind from './kind';
import useKitchen from './kitchen';

interface ICatalogState {
  products: IProduct[];
  groups: IGroup[];
  kinds: IKind[];
  kitchens: IKitchen[];
  totalProducts: number,

  selectedPage: number,
  pageSize: number,
  totalPages?: number,

  selectedGroupsIds: IProductGroupsQueryParam[],
  selectedKindsIds: string[],
  selectedKitchensIds: string[],
  selectedRestaurantId: string,

  isMenuPanelOpened: boolean,
  activeMenuItemType: CATALOG_MENU_ITEM_TYPE,

  isRolledTagMenu: boolean,

  searchValue?: string,
  sort?: TProductSort,

  isFirstLoad: boolean,
  isLoadingProducts: boolean;
  restaurantWasChanged: boolean;
}

interface IRouteQueryParams {
  page: string;
  'kinds_ids': string,
  'kitchens_ids': string,
  'groups_ids': string,
  'is_favorite': string,
  name: string,
  append: boolean,
  sort: string,
  'restaurant_id': string,
}

interface IFiltersParams {
  filters: {
    // eslint-disable-next-line camelcase
    restaurant_id: string,
  },
}

const useCatalog = defineStore('catalog', {
  state(): ICatalogState {
    return {
      // Данные для отображения
      products: [],
      groups: [],
      kinds: [],
      kitchens: [],
      totalProducts: 0,

      // Пагинация
      selectedPage: 1,
      pageSize: 50,
      totalPages: 0,

      // Выбранные категории c фильтрами
      selectedGroupsIds: [],

      // Выбранные фильтры
      selectedKindsIds: [],

      // Выбранные кухни
      selectedKitchensIds: [],

      // Ресторан
      selectedRestaurantId: '',

      // Панель фильтров/категорий
      isMenuPanelOpened: true,
      activeMenuItemType: CATALOG_MENU_ITEM_TYPE.groups,

      isRolledTagMenu: false,

      searchValue: '',
      sort: undefined,

      isFirstLoad: true,
      isLoadingProducts: false,
      restaurantWasChanged: false,
    };
  },

  getters: {
    haveSelectedItems(state: ICatalogState) {
      return (
        !isEmpty(state.selectedKindsIds)
        || !isEmpty(state.selectedGroupsIds)
        || !isEmpty(state.selectedKitchensIds)
      );
    },
  },

  actions: {
    deserializeQueryParams(queryParams: Partial<IRouteQueryParams>) {
      this.selectedGroupsIds = queryParams.groups_ids
        ? JSON.parse(queryParams.groups_ids)
        : [];

      this.selectedKindsIds = queryParams.kinds_ids?.split(',') || [];
      this.selectedKitchensIds = queryParams.kitchens_ids?.split(',') || [];
      this.selectedPage = queryParams.page ? parseInt(queryParams.page, 10) : 1;
      this.searchValue = queryParams.name;

      const selectedRestaurantId = queryParams.restaurant_id || '';

      this.restaurantWasChanged = this.selectedRestaurantId !== selectedRestaurantId;
      this.selectedRestaurantId = selectedRestaurantId;

      this.sort = isProductSort(queryParams.sort)
        ? queryParams.sort
        : undefined;
    },

    fetch(query: Partial<IRouteQueryParams>) {
      this.partialFetch({
        fetchProducts: true,
        fetchFilters: this.isFirstLoad || this.restaurantWasChanged,
      }, query);
    },

    partialFetch(config: Partial<{
      fetchProducts: boolean,
      fetchFilters: boolean,
    }> = {}, routeQueryParams: Partial<IRouteQueryParams>) {
      if (config.fetchProducts) {
        this.fetchProducts(routeQueryParams);
      }

      if (config.fetchFilters) {
        const params = {} as IFiltersParams;

        if (this.selectedRestaurantId) {
          params.filters = {
            restaurant_id: this.selectedRestaurantId,
          };
        }

        this.fetchKinds(params);
        this.fetchKitchens(params);
        this.fetchGroups(params);
      }
    },

    async fetchProducts(routeQueryParams: Partial<IRouteQueryParams>) {
      this.isLoadingProducts = true;

      const response = await useProduct().getCompanyProducts(
        {
          filters: {

            // Избранное
            is_favorite: routeQueryParams.is_favorite,

            // Категории
            product_groups: routeQueryParams.groups_ids,

            // Фильтры
            filter_ids: routeQueryParams.kinds_ids,

            // Кухни
            kitchen_ids: routeQueryParams.kitchens_ids,

            // Ресторан
            restaurant_id: routeQueryParams.restaurant_id,

            // Поиск по названию
            name: routeQueryParams.name,
          },

          // Сортировка
          sort: this.sort,

          // Пагинация
          page: {
            number: routeQueryParams.page || '1',
            size: String(this.pageSize),
          },
        },
      );

      const products = response.data;

      if (routeQueryParams.append) {
        this.products.push(...products);
      } else {
        this.products = products;
      }

      this.isFirstLoad = false;
      this.totalProducts = response.meta.total;
      this.totalPages = Math.ceil(this.totalProducts / this.pageSize);
      this.isLoadingProducts = false;
    },

    async fetchGroups(params: IFiltersParams) {
      this.groups = await useGroup().getAll({
        ...params,
        include: 'filters',
      });
    },

    async fetchKinds(params: IFiltersParams) {
      this.kinds = await useKind().getAll(params);
    },

    async fetchKitchens(params: IFiltersParams) {
      this.kitchens = await useKitchen().getAll(params);
    },

    openMenuPanel() {
      this.isMenuPanelOpened = true;
    },

    closeMenuPanel() {
      this.isMenuPanelOpened = false;
    },

    selectMenuItem(type: CATALOG_MENU_ITEM_TYPE) {
      this.activeMenuItemType = type;
    },

    toggleRolledTagMenu(state = false) {
      this.isRolledTagMenu = state;
    },
  },
});

export {
  useCatalog,
};
