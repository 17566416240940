
import { defineComponent, PropType } from 'vue';

interface IFilter {
  isDivider: boolean,
  name: string,
  productsCount?: number,
}

export default defineComponent({
  name: 'FilterItem',

  props: {
    item: {
      type: Object as PropType<IFilter>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['select'],
});
