function lockScrollBody(): void {
  document.body.dataset.scrollY = String(
    document.documentElement?.scrollTop || document.body?.scrollTop,
  );
  document.body.style.top = `-${document.body.dataset.scrollY}px`;
  document.body.classList.add('modal-open');
}

function unlockScrollBody(): void {
  document.body.classList.remove('modal-open');
  window.scrollTo(0, +(document.body.dataset.scrollY || 0));
}

export {
  lockScrollBody,
  unlockScrollBody,
};
