import ICoupon from './coupon';
import ICheque from './cheque';
import IOrderItem from './orderItem';
import IPickupAddress from './pickupAddress';
import IUserCustomer from './userCustomer';
import IDeliveryAddress from './deliveryAddress';
import { IOrderPayment } from './orderPayment';

import jsonApi from '@/libs/jsonapi';

interface IDeliveryPrice {
  name: string;
  price: string;
}

interface IOrder {
  id: string;
  status: string;
  deliveryPrice: IDeliveryPrice[] | null;
  totalDiscount: string | null;
  couponDiscount: string | null;
  totalCardDiscount: string | null;
  totalPrice: string;
  totalPriceWithoutDiscount: string;
  personCount?: number | null;
  deliveryAt: string | null;
  createdAt: string | null;
  cashAmount: string | null;
  chequeEmail: string | null;
  notes: string | null;
  confirmByPhone?: boolean | null;
  isContactless?: boolean | null;
  deliveryTypeId: string;
  paymentTypeId: string | null;
  deliveryTime: number[] | null;
  cardType: string | null;
  bonusesWriteOffSum: string;
  bonusesMaxWriteOffSum: string;
  bonusesAccrualSum: string;
  bonusesAccruedSum: string;
  bonusesWrittenOffSum: string;
  isPaid: boolean;
  deviceType: string | null;

  // Денормализованные данные
  customerFullName: string | null;
  customerPhone: string | null;
  completedAt: string | null;
  acceptedAt: string | null;
  deliveryAddress: string | null;
  sumPaid: string | null;
  sumToPay: string | null;
  pickupAddress: string | null;

  // Связи
  userCustomer: IUserCustomer;
  companyUserDeliveryAddress: IDeliveryAddress | null;
  companyPickupAddress: IPickupAddress | null;
  items?: IOrderItem[];
  payment?: IOrderPayment;
  coupon?: ICoupon | null;
  cheque?: ICheque | null;
}

enum ORDER_STATUS {
  NEW = 'new',
  ACCEPTED = 'accepted',
  COMPLETED = 'completed',
  COOKING = 'cooking',
  IN_DELIVERY = 'in_delivery',
  CANCELED = 'canceled',
  FAILED_PAYMENT = 'failed_payment',
}

const ORDER_TITLE_STATUS: {
  [value in ORDER_STATUS]: string
} = {
  [ORDER_STATUS.NEW]: 'новый',
  [ORDER_STATUS.ACCEPTED]: 'принят',
  [ORDER_STATUS.COOKING]: 'готовится',
  [ORDER_STATUS.IN_DELIVERY]: 'доставляется',
  [ORDER_STATUS.COMPLETED]: 'доставлен',
  [ORDER_STATUS.CANCELED]: 'отменен',
  [ORDER_STATUS.FAILED_PAYMENT]: 'ошибка оплаты',
};

jsonApi.define<IOrder>(
  'order',
  {
    status: '',
    totalPriceWithoutDiscount: '',
    totalPrice: '',
    deliveryPrice: [],
    totalDiscount: '',
    couponDiscount: '',
    totalCardDiscount: '',
    personCount: 0,
    deliveryAt: '',
    createdAt: '',
    cashAmount: '',
    chequeEmail: '',
    deliveryTypeId: '',
    notes: '',
    confirmByPhone: false,
    isContactless: false,
    paymentTypeId: '',
    deliveryTime: [],
    cardType: '',
    bonusesWriteOffSum: '',
    bonusesMaxWriteOffSum: '',
    bonusesAccrualSum: '',
    bonusesAccruedSum: '',
    bonusesWrittenOffSum: '',
    isPaid: false,
    deviceType: '',

    // Денормализованные данные
    customerFullName: '',
    customerPhone: '',
    acceptedAt: '',
    completedAt: '',
    deliveryAddress: '',
    sumPaid: '',
    sumToPay: '',
    pickupAddress: '',

    // Связи
    userCustomer: {
      jsonApi: 'hasOne',
      type: 'user_customers',
    },

    companyUserDeliveryAddress: {
      jsonApi: 'hasOne',
      type: 'company_user_delivery_addresses',
    },

    companyPickupAddress: {
      jsonApi: 'hasOne',
      type: 'company_pickup_addresses',
    },

    items: {
      jsonApi: 'hasMany',
      type: 'order_item',
    },

    payment: {
      jsonApi: 'hasOne',
      type: 'payment',
    },

    coupon: {
      jsonApi: 'hasOne',
      type: 'coupons',
    },

    cheque: {
      jsonApi: 'hasOne',
      type: 'cheques',
    },
  },

  {
    collectionPath: 'user/orders',
  },
);

export {
  IOrder,
  ORDER_STATUS,
  ORDER_TITLE_STATUS,
  IDeliveryPrice,
};
