
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useToast } from 'vue-toastification';

import IOrderItem from '@/models/orderItem';
import { IOrder, ORDER_STATUS, ORDER_TITLE_STATUS } from '@/models/order';

import useOrder from '@/stores/order';

import EmptyLayout from '@/components/company/Order/EmptyLayout/EmptyLayout.vue';
import ProductList from '@/components/company/Order/ProductList/ProductList.vue';
import Warning from '@/components/shared/Warning/Warning.vue';
import ModalDialog from '@/components/shared/ModalDialog/ModalDialog.vue';
import throttleEvent from '@/libs/throttleEvent';

const toast = useToast();
const WARNING_TITLE = 'Замена состава корзины';
const WARNING_TEXT = 'Состав из корзины будет заменен на состав из заказа';

export default defineComponent({
  name: 'OrdersHistoryView',

  components: {
    EmptyLayout,
    ProductList,
    ModalDialog,
    Warning,
  },

  setup() {
    throttleEvent('resize', 'optimizedResize');

    const windowWidth = ref(window.innerWidth);

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => window.addEventListener('optimizedResize', onWidthChange));
    onUnmounted(() => window.removeEventListener('optimizedResize', onWidthChange));

    return {
      windowWidth,
    };
  },

  data() {
    const orderStore = useOrder();
    const orders = ref<IOrder[]>([]);
    const wasLoaded = ref<boolean>(false);

    orderStore
      .fetch({
        'filters[status]': [
          ORDER_STATUS.COMPLETED,
          ORDER_STATUS.CANCELED,
          ORDER_STATUS.FAILED_PAYMENT,
        ].join(','),
        sort: '-accepted_at',
        include: 'items,items.product,cheque',
      })
      .then((rOrders: IOrder[]) => {
        orders.value = rOrders;
        wasLoaded.value = true;
      });

    return {
      orders,
      ORDER_TITLE_STATUS,
      ORDER_STATUS,
      WARNING_TITLE,
      WARNING_TEXT,
      wasLoaded,
      orderStore,
      isProcessing: false,
      isOpenConfirmWarning: false,
      orderIdToRepeat: '',
    };
  },

  methods: {
    closeWarningModal() {
      this.isOpenConfirmWarning = false;
    },

    tryRepeatOrder(orderId: string) {
      this.orderIdToRepeat = orderId;

      if (!this.orderStore.isEmpty) {
        this.isOpenConfirmWarning = true;
        return;
      }

      this.repeatOrder();
    },

    async repeatOrder() {
      this.isProcessing = true;

      try {
        await this.orderStore.repeatOrder(this.orderIdToRepeat);
      } catch (error: any) {
        toast.error('Не удалось повторить заказ');
        return;
      } finally {
        this.isProcessing = false;
        this.closeWarningModal();
      }

      this.$router.push({
        name: 'c-cart',
      });
    },

    isAvailableForPurchase(orderItems: IOrderItem[]) {
      return orderItems.every((orderItem) => orderItem.isAvailableForPurchase === false);
    },
  },
});
