
import { defineComponent, PropType } from 'vue';
import { useForm, useField } from 'vee-validate';
import { debounce } from 'lodash';
import { useToast } from 'vue-toastification';
import VueSelect from 'vue-select';
import * as yup from 'yup';

import IDeliveryAddress from '@/models/deliveryAddress';
import ISearchAddress from '@/models/searchAddress';

import useDeliveryAddress from '@/stores/deliveryAddress';

const toast = useToast();

type IDeliveryAddressEdit = Omit<IDeliveryAddress, 'id'>;

const presetValidation = (address: IDeliveryAddressEdit) => {
  const fullName = address.city && address.street
    ? [address.city, address.street].join(', ')
    : '';

  const { errors, meta } = useForm<
    Omit<
      IDeliveryAddressEdit,
      'isDefault' | 'fullName' | 'street' | 'city' | 'isDeliveryAvailable'
    > & {
      selectedAddress: unknown;
    }
  >({
    initialValues: {
      name: address.name,
      building: address.building,
      flat: address.flat,
      entrance: address.entrance,
      floor: address.floor,
      isIntercomAvailable: address.isIntercomAvailable,
      selectedAddress: {
        fullName,
        city: address.city,
        street: address.street,
        building: address.building,
      },
      geozoneId: address.geozoneId,
      deliveryZone: address.deliveryZone,
    },
    validationSchema: yup.object({
      name: yup.string().default(''),
      selectedAddress: yup
        .object()
        .required()
        .shape({
          fullName: yup.string().required(),
          city: yup.string().required(),
          street: yup.string().required(),
          building: yup.string().nullable(),
        })
        .label('Адрес')
        .typeError('Выберите адрес из списка'),
      building: yup.string().required().label('Дом'),
      isIntercomAvailable: yup.boolean().required(),

      flat: yup.string(),
      entrance: yup.string(),
      floor: yup.string(),
      geozoneId: yup.string(),
      deliveryZone: yup
        .object()
        .shape({})
        .nullable(),
    }),
  });

  return {
    errors,
    meta,

    name: useField<string>('name').value,
    selectedAddress: useField<ISearchAddress>('selectedAddress').value,
    isIntercomAvailable: useField<boolean>('isIntercomAvailable').value,
    building: useField<string>('building').value,

    flat: useField<string | undefined>('flat').value,
    entrance: useField<string | undefined>('entrance').value,
    floor: useField<string | undefined>('floor').value,
  };
};

export default defineComponent({
  name: 'DeliveryAddressForm',

  components: {
    VueSelect,
  },

  props: {
    deliveryAddress: {
      type: Object as PropType<IDeliveryAddress>,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close', 'wasUpdated', 'wasRemoved', 'select'],

  setup(props) {
    const validationData = presetValidation(props.deliveryAddress);

    return {
      isNew: !props.deliveryAddress.id,
      ...validationData,
      deliveryAddressStore: useDeliveryAddress(),
    };
  },

  data(): {
    isProcessing: boolean;
    addresses: ISearchAddress[];
    selectedAddress?: ISearchAddress;
    debouncedFetchAddresses: () => void;
    } {
    return {
      isProcessing: false,
      addresses: [],
      selectedAddress: undefined,
      debouncedFetchAddresses: debounce(this.fetchAddresses as () => void, 500),
    };
  },

  computed: {
    title(): string {
      return this.isNew
        ? 'Добавление нового адреса'
        : 'Редактирование адреса доставки';
    },
  },

  watch: {
    selectedAddress(value: ISearchAddress) {
      if (!value?.building) {
        return;
      }

      this.building = value.building;
    },
  },

  methods: {
    async fetchAddresses(search: string, loading: (state: boolean) => void) {
      loading(true);
      const response = await this.deliveryAddressStore.findSearchAddresses(
        search,
      );
      this.addresses = response.data;
      loading(false);
    },

    async save() {
      if (!this.meta.valid) {
        return;
      }

      let deliveryAddress: IDeliveryAddress | undefined;
      this.isProcessing = true;

      const method = this.isNew ? this.create : this.update;

      try {
        deliveryAddress = await method();
      } catch (error) {
        toast.error('Ошибка сохранения адреса доставки');

        return;
      } finally {
        this.isProcessing = false;
      }

      if (this.isNew) {
        this.$emit('select', deliveryAddress);
      }

      this.$emit('wasUpdated', deliveryAddress);
      this.$emit('close');
    },

    async remove() {
      this.isProcessing = true;

      await this.deliveryAddressStore.remove(this.deliveryAddress.id);

      this.isProcessing = false;

      this.$emit('wasRemoved', this.deliveryAddress.id);
    },

    update() {
      return this.deliveryAddressStore.update({
        id: this.deliveryAddress.id,
        name: this.name,
        street: this.selectedAddress?.street,
        city: this.selectedAddress?.city,
        building: this.building,
        flat: this.flat,
        entrance: this.entrance,
        floor: this.floor,
        isIntercomAvailable: this.isIntercomAvailable || false,
        isDefault: this.deliveryAddress.isDefault,
        geozoneId: this.selectedAddress.geozoneId,
      });
    },

    create() {
      return this.deliveryAddressStore.create({
        name: this.name,
        street: this.selectedAddress.street,
        city: this.selectedAddress.city,
        building: this.building,
        flat: this.flat,
        entrance: this.entrance?.toString(),
        floor: this.floor?.toString(),
        isIntercomAvailable: this.isIntercomAvailable || false,
        isDefault: true,
        geozoneId: this.selectedAddress.geozoneId,
      });
    },
  },
});
