
import { defineComponent } from 'vue';

import OrdersNavigationPanel from '@/components/company/Order/NavigationPanel/NavigationPanel.vue';

export default defineComponent({
  name: 'OrdersView',

  components: {
    OrdersNavigationPanel,
  },
});
