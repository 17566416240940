import IKind from './kind';

import jsonApi from '@/libs/jsonapi';

interface IGroup {
  id: string;
  name: string;
  color: string;
  filters: IKind[];
}

interface IProductGroupsQueryParam {
  id: string,
  'filter_ids'?: string[],
}

jsonApi.define<IGroup>(
  'product_group',
  {
    name: '',
    color: '',
    filters: {
      type: 'filters',
      jsonApi: 'hasMany',
    },
  },
  {
    collectionPath: 'product-groups',
  },
);

export { IGroup as default, IProductGroupsQueryParam };
