
import {
  defineComponent,
  PropType,
  nextTick,
  ref,
  Ref,
  onMounted,
  onUnmounted,
} from 'vue';

import { TABLET_BREAKPOINT } from '@/constants';

import IGroup, { IProductGroupsQueryParam } from '@/models/group';
import IKind from '@/models/kind';
import IKitchen from '@/models/kitchen';

import RolledTag from './RolledTag.vue';
import Tag from './Tag.vue';

import throttleEvent from '@/libs/throttleEvent';

const TAG_DEFAULT_COLOR = '03B506';
const UNROLL_MOBILE_ICON_WIDTH = 50;
const OFFSET_FROM_END_LINE = 42;
const CHANGE_WIDTH_WHEN_ROLL_TAG = 32;
const MAIN_FILTER_ATTR = 'data-is-main-filter';
const NEW_LINE_ATTR = 'data-is-new-line';
const ONE_ROLLED_ITEM = 'one-rolled-item';
const PRELOAD_IMAGES = [
  /* eslint-disable global-require */
  require('./tag-images/center.svg'),
  require('./tag-images/left-arrow-selected.svg'),
  require('./tag-images/left-arrow-unselected.svg'),
  require('./tag-images/mobile-left-arrow-unselected.svg'),
  require('./tag-images/mobile-right-arrow-unselected.svg'),
  require('./tag-images/right-arrow-selected.svg'),
  require('./tag-images/right-arrow-unselected.svg'),
  require('./tag-images/right-border.svg'),
  /* eslint-enabled global-require */
];

export default defineComponent({
  components: {
    Tag,
    RolledTag,
  },

  props: {
    selectedKinds: {
      type: Array as PropType<IKind[]>,
      required: true,
    },
    selectedKitchens: {
      type: Array as PropType<IKitchen[]>,
      required: true,
    },
    selectedGroups: {
      type: Array as PropType<IGroup[]>,
      required: true,
    },
    selectedGroupsFilters: {
      type: Object as PropType<IProductGroupsQueryParam[]>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isRolled: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'reset',
    'toggle',
    'deselect',
    'selectSubGroup',
    'roll',
  ],

  setup() {
    const tagList: Ref<HTMLElement | null> = ref(null);
    const tagsWrapper: Ref<HTMLElement | null> = ref(null);

    throttleEvent('resize', 'optimizedResize');

    const windowWidth = ref(window.innerWidth);

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => window.addEventListener('optimizedResize', onWidthChange));
    onUnmounted(() => window.removeEventListener('optimizedResize', onWidthChange));

    return {
      tagsWrapper,
      tagList,
      windowWidth,
    };
  },

  data() {
    return {
      TAG_DEFAULT_COLOR,
      isMounted: false,
      isNotShowingRollToggler: false,
    };
  },

  computed: {
    filters() {
      if (this.tagList) {
        this.tagList.classList.remove(ONE_ROLLED_ITEM);
      }

      let filters: {[x: string]: any}[] = [
        ...this.selectedGroups,
        ...this.selectedKitchens,
        ...this.selectedKinds,
      ];

      if (this.isRolled && filters.length) {
        const allowedCountItems = this.getAllowedFiltersCount();

        if (allowedCountItems && allowedCountItems !== filters.length) {
          filters = filters.slice(0, allowedCountItems);
        }

        if (!allowedCountItems && this.tagList) {
          filters = filters.slice(0, 1);
          this.tagList.classList.add(ONE_ROLLED_ITEM);
        }
      }

      return filters;
    },
  },

  watch: {
    'filters.length': {
      async handler(value, oldValue) {
        if (!value) {
          return;
        }

        await nextTick();

        if (oldValue) {
          this.recalculateTagsList();
        } else {
          setTimeout(() => this.recalculateTagsList(), 200);
        }
      },
      immediate: true,
    },

    async isRolled(value) {
      if (value) {
        return;
      }

      await nextTick();

      this.recalculateTagsList();
    },

    windowWidth(value) {
      if (value <= TABLET_BREAKPOINT) {
        this.recalculateTagsList();
      }
    },
  },

  mounted() {
    this.preloadImages(PRELOAD_IMAGES);
  },

  methods: {
    preloadImages(images: string[]) {
      images.forEach((itemSrc) => {
        const img = new Image();

        img.src = itemSrc;
      });
    },

    createNewLineBlock() {
      const elem = document.createElement('div');

      elem.classList.add('new-line-block');
      elem.dataset.isNewLine = String(true);

      return elem;
    },

    recalculateTagsList() {
      if (!this.tagList?.children.length) {
        return;
      }

      // eslint-disable-next-line no-unused-expressions
      this.tagList?.querySelectorAll('.new-line-block').forEach((item) => item.remove());

      const list = this.tagList.children;

      const yCoordFirstLine = list[0].getBoundingClientRect().y;
      const yCoordLastLine = list[list.length - 1].getBoundingClientRect().y;

      this.recalculateLine(list, yCoordFirstLine);

      this.isNotShowingRollToggler = yCoordFirstLine === yCoordLastLine;

      if (yCoordFirstLine !== yCoordLastLine) {
        this.recalculateLine(list, yCoordLastLine);
      }
    },

    recalculateLine(list: HTMLCollection, yCord: number) {
      if (!this.tagList || !this.tagsWrapper) {
        return;
      }

      const wrapperStyle = window.getComputedStyle(this.tagsWrapper);
      const allowedWidth = this.tagsWrapper?.offsetWidth
        - parseFloat(wrapperStyle.paddingLeft)
        - parseFloat(wrapperStyle.paddingRight)
        - OFFSET_FROM_END_LINE;

      const lineElements = [...list].filter(
        (item) => item.getBoundingClientRect().y === yCord && !item.hasAttribute(NEW_LINE_ATTR),
      );

      const sumWidthLine = lineElements.reduce((acc, item) => {
        // eslint-disable-next-line no-param-reassign
        acc += this.getElemWidth((item as HTMLElement));

        return acc;
      }, 0);

      if (sumWidthLine >= allowedWidth) {
        this.tagList.insertBefore(this.createNewLineBlock(), lineElements[lineElements.length - 1]);
      }
    },

    getAllowedFiltersCount() {
      if (!this.tagsWrapper || !this.tagList) {
        return 0;
      }

      // eslint-disable-next-line no-unused-expressions
      this.tagList?.querySelectorAll('.new-line-block').forEach((item) => item.remove());

      const listItems = [...this.tagList.children]
        .filter((item) => item.hasAttribute(MAIN_FILTER_ATTR));

      const wrapperStyle = window.getComputedStyle(this.tagsWrapper);

      const allowedWidth = this.tagsWrapper.offsetWidth
        - parseFloat(wrapperStyle.paddingLeft)
        - parseFloat(wrapperStyle.paddingRight)
        - UNROLL_MOBILE_ICON_WIDTH
        - OFFSET_FROM_END_LINE;

      const { allowedCountItems } = [...listItems].reduce((acc, item) => {
        if (acc.isBreak) {
          return acc;
        }

        let currItemWidth = this.getElemWidth(item as HTMLElement);

        if ((item as HTMLElement).dataset.withSubfilters === String(true)) {
          currItemWidth += CHANGE_WIDTH_WHEN_ROLL_TAG;
        }

        if (acc.sumWidth + currItemWidth >= allowedWidth) {
          acc.isBreak = true;

          return acc;
        }

        acc.allowedCountItems += 1;
        acc.sumWidth += currItemWidth;

        return acc;
      }, {
        isBreak: false,
        sumWidth: 0,
        allowedCountItems: 0,
      });

      return allowedCountItems;
    },

    getElemWidth(item: HTMLElement) {
      const itemStyle = window.getComputedStyle(item);

      return (item as HTMLElement).offsetWidth
        + parseFloat(itemStyle.marginLeft)
        + parseFloat(itemStyle.marginRight);
    },

    isActiveFilterByGroupId(groupId: string, filterId: string) {
      const currentGroupFilters = this.selectedGroupsFilters
        .find(({ id }) => groupId === id)?.filter_ids
        || [];

      return currentGroupFilters.includes(filterId);
    },

    unroll() {
      this.isNotShowingRollToggler = false;

      this.$emit('roll');
    },
  },
});
