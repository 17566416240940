
import { defineComponent } from 'vue';
import { last } from 'lodash';

import NumberPhonePanel from '@/components/shared/NumberPhonePanel/NumberPhonePanel.vue';

import { DAYS_OF_THE_WEEK } from '@/constants';

import useCompany from '@/stores/company';

import getFormatHours from '@/helpers/getFormatHours';

export default defineComponent({
  components: {
    NumberPhonePanel,
  },

  setup() {
    return {
      companyStore: useCompany(),
    };
  },

  data() {
    return {
      daysOfTheWeek: DAYS_OF_THE_WEEK,
      last,
      getFormatHours,
    };
  },
});
