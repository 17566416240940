import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-item__description" }
const _hoisted_2 = { class: "filter-item__name" }
const _hoisted_3 = {
  key: 0,
  class: "filter-item__count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(
      [
        'filter-items__item filter-item',
        {
          'filter-item--selected': _ctx.isSelected,
          'filter-item--divider': _ctx.item.isDivider,
        }
      ]
    ),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.name), 1),
      (_ctx.item.productsCount)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(` ${_ctx.item.productsCount}`), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}