import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-968551ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "type" }
const _hoisted_2 = { class: "type__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'type-wrapper',
      { 'type-wrapper--active': _ctx.isActive },
      { 'type-wrapper--disabled': !(_ctx.type.isAvailable ?? _ctx.type.isEnabled) },
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.typeId)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(['type__icon', `type__icon--${_ctx.typeId}`])
      }, null, 2),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.type.title), 1)
    ])
  ], 2))
}