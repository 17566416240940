import { RouteRecordRaw } from 'vue-router';

import { SCOPE_TYPE } from '@/types';

import AggregatorRootView from '@/views/Aggregator/Root.vue';

const AggregatorRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'aggregator',
    meta: {
      scope: SCOPE_TYPE.AGGREGATOR,
    },
    component: AggregatorRootView,

    children: [],
  },
];

export default AggregatorRoutes;
