
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toastification';

import IProduct from '@/models/product';
import IProductOption from '@/models/productOption';

import useOrder from '@/stores/order';
import useFastView from '@/stores/fastView';

import formattedNumber from '@/filters/formattedNumber';
import QuantityControl from '@/components/shared/QuantityControl/QuantityControl.vue';
import FinalPricePanel from '@/components/shared/Products/FinalPricePanel/FinalPricePanel.vue';
import SelectField from '@/components/shared/SelectField/SelectField.vue';

const toast = useToast();

export default defineComponent({
  components: {
    QuantityControl,
    FinalPricePanel,
    SelectField,
  },

  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
    option: {
      type: Object as PropType<IProductOption>,
      required: true,
    },
  },

  emits: ['close'],

  setup() {
    return {
      orderStore: useOrder(),
      fastViewStore: useFastView(),
    };
  },

  data(): {
    quantity: number,
    selectedOption: IProductOption | null,
    } {
    return {
      quantity: 1,
      selectedOption: null,
    };
  },

  computed: {
    totalPrice(): number {
      if (!this.selectedOption) {
        return 0;
      }

      return this.quantity * +this.selectedOption.price;
    },

    isShowNutricionalValues(): boolean {
      if (!this.selectedOption) {
        return false;
      }

      return !!(this.selectedOption.protein
        || this.selectedOption.fat
        || this.selectedOption.carbohydrate
        || this.selectedOption.calories);
    },

    isSingleOption(): boolean {
      if (!this.product?.productOptions) {
        return false;
      }

      return this.product.productOptions
        .filter(({ isAvailableForPurchase }) => isAvailableForPurchase)
        .length === 1;
    },

    modifyOptionsForSelect(): { key: string, value: string }[] {
      if (!this.product?.productOptions) {
        return [];
      }

      return this.product.productOptions
        .filter(({ isAvailableForPurchase }) => isAvailableForPurchase)
        .map(({ id, title, weight }) => ({
          key: id,
          value: `${title}, ${formattedNumber(weight)} г`,
        }));
    },
  },

  mounted() {
    if (this.option) {
      this.selectedOption = this.option;
    } else if (this.product.productOptions) {
      [this.selectedOption] = this.product.productOptions;
    }
  },

  methods: {
    selectOption(optionId: string) {
      const newOption = this.product.productOptions.find(({ id }) => id === optionId);

      if (!newOption) {
        return;
      }

      this.quantity = 1;
      this.selectedOption = newOption;
    },

    increase() {
      this.quantity += 1;
    },

    decrease() {
      this.quantity -= 1;
    },

    async add() {
      if (!this.selectedOption) {
        return;
      }

      try {
        if (this.orderStore.orderItemsIdsByOptionsIds[this.selectedOption.id]) {
          await this.orderStore.increaseItemQuantity(
            this.orderStore.orderItemsIdsByOptionsIds[this.selectedOption.id],
            this.quantity,
          );
        } else {
          await this.orderStore.addProduct(this.product, this.selectedOption, this.quantity);
        }

        if (this.fastViewStore.withNotify) {
          toast.success('Выбранный товар успешно добавлен в корзину.');
        }
      } catch {
        toast.error('Ошибка добавления товара в корзину.');

        return;
      }

      this.$emit('close');
    },
  },
});
