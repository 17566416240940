import jsonApi from '@/libs/jsonapi';

interface IKind {
  id: string;
  name: string;
}

jsonApi.define<IKind>(
  'filter',
  {
    name: '',
  },
  {
    collectionPath: 'filters',
  },
);

export default IKind;
