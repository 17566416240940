import * as yup from 'yup';

import { REG_EXP_EMAIL } from '@/constants';

import { IOrder } from '@/models/order';
import {
  DeliveryType, DELIVERY_TYPE,
} from '@/models/deliveryType';
import { PAYMENT_TYPE } from '@/models/paymentType';

type EXCLUDED_ATTR =
  | 'items'
  | 'cheque'
  | 'payment'
  | 'coupon'
  | 'bonusesWriteOffSum'
  | 'bonusesMaxWriteOffSum'
  | 'bonusesAccrualSum'
  | 'bonusesAccruedSum'
  | 'bonusesWrittenOffSum';

yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.matches(REG_EXP_EMAIL, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});

interface IPreProcessUpdateSchema {
  chequeEmail: string;
}

const chequeEmailSchema: yup.SchemaOf<IPreProcessUpdateSchema> = yup.object({
  chequeEmail: yup.string().default('').email('Некорректный email'),
});

const orderSchema: yup.SchemaOf<Omit<IOrder, EXCLUDED_ATTR>> = yup.object({
  id: yup.string().required(),
  status: yup.string().required(),
  deliveryPrice: yup.array().default([]).nullable(),
  totalDiscount: yup.string().default('').nullable(),
  couponDiscount: yup.string().default('').nullable(),
  totalCardDiscount: yup.string().default('').nullable(),
  totalPrice: yup.string().required(),
  totalPriceWithoutDiscount: yup.string().required(),
  deliveryTime: yup.array().default([]).nullable(),
  cardType: yup.string().default('').nullable(),

  // Настройка для оплаты наличными
  cashAmount: yup.string().default('').nullable(),

  // Email при оплате сбп или картой на сайте
  chequeEmail: yup.string().default('').nullable(),

  // Дополнительная информация
  deliveryAt: yup.string().default('').nullable(),
  createdAt: yup.string().default('').nullable(),
  personCount: yup.number().nullable(),
  bonusesWriteOffSum: yup.number().nullable(),
  confirmByPhone: yup.boolean().nullable(),
  isContactless: yup.boolean().nullable(),
  notes: yup.string().default('').nullable(),
  isPaid: yup.boolean().required(),

  // Не валидируем денормализованные данные
  customerFullName: yup.string().default('').nullable(),
  customerPhone: yup.string().default('').nullable(),
  acceptedAt: yup.string().default('').nullable(),
  completedAt: yup.string().default('').nullable(),
  deliveryAddress: yup.string().default('').nullable(),
  sumPaid: yup.string().default('').nullable(),
  sumToPay: yup.string().default('').nullable(),
  pickupAddress: yup.string().default('').nullable(),
  deviceType: yup.string().default('').nullable(),

  deliveryTypeId: yup
    .string()
    .required('Выберите способ доставки')
    .typeError('Выберите способ доставки'),
  paymentTypeId: yup
    .string()
    .oneOf([
      PAYMENT_TYPE.CASH,
      PAYMENT_TYPE.SBP,
      PAYMENT_TYPE.CARD_OFFLINE,
      PAYMENT_TYPE.CARD_ONLINE,
    ])
    .required('Выберите способ оплаты')
    .typeError('Выберите способ оплаты'),

  userCustomer: yup
    .object()
    .default(null)
    .required('Выберите получателя')
    .typeError('Выберите получателя'),
  companyUserDeliveryAddress: yup
    .object()
    .default(null)
    .nullable()
    .when('deliveryTypeId', (val: DeliveryType, schema: yup.AnyObjectSchema) => {
      if (val === DELIVERY_TYPE.COURIER) {
        return schema.required('Выберите адрес доставки');
      }

      return schema;
    }),
  companyPickupAddress: yup
    .object()
    .default(null)
    .nullable()
    .when('deliveryTypeId', (val: DeliveryType, schema: yup.AnyObjectSchema) => {
      if (val === DELIVERY_TYPE.SELF_PICKUP) {
        return schema.required('Выберите пункт самовывоза');
      }

      return schema;
    }),
});

export { orderSchema, chequeEmailSchema };
