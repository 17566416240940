import PaymentType, { IPaymentType, IPostPaidPageParams } from './paymentType';

export default class CardOnline extends PaymentType implements IPaymentType {
  toPostPaidPage(order: Partial<IPostPaidPageParams>): void {
    if (!order.redirectUrl) {
      return;
    }

    this.window.location.href = order.redirectUrl;
  }
}
