
import { defineComponent } from 'vue';
import { MoHeaderDivider } from '@mo/uikit';

export default defineComponent({
  name: 'HeaderDivider',

  components: {
    MoHeaderDivider,
  },
});
