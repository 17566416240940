
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VerticalQuantityControl',

  props: {
    quantity: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['increase', 'decrease'],
});
