
import { defineComponent } from 'vue';
import { MoPreloader } from '@mo/uikit';

export default defineComponent({
  name: 'BonusesPanel',

  components: {
    MoPreloader,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    bonusesWriteOffSum: {
      type: String,
      default: '',
    },
    bonusesMaxWriteOffSum: {
      type: String,
      required: true,
    },
    balance: {
      type: String,
      required: true,
    },
  },

  emits: ['apply', 'reset'],

  data() {
    return {
      bonusesCount: '',
    };
  },

  watch: {
    bonusesWriteOffSum: {
      handler(value) {
        this.bonusesCount = value;
      },
      immediate: true,
    },
  },

  methods: {
    reset() {
      this.bonusesCount = '';

      this.$emit('reset');
    },

    setWriteOfSum(maxWriteOffSum: string) {
      if (this.bonusesWriteOffSum || +this.bonusesMaxWriteOffSum === 0) {
        return;
      }

      this.bonusesCount = maxWriteOffSum;
    },
  },
});
