
import { defineComponent, PropType } from 'vue';

import { IPage } from '@/types';

import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';
import useOrder from '@/stores/order';
import useFavorite from '@/stores/favorite';

import UserPanel from '@/components/shared/UserPanel/UserPanel.vue';
import HamburgerMenu from '@/components/shared/HamburgerMenu/HamburgerMenu.vue';

export default defineComponent({
  name: 'CompanyHeader',

  components: {
    UserPanel,
    HamburgerMenu,
  },

  props: {
    pages: {
      type: Array as PropType<IPage[]>,
      default: () => ([]),
    },
  },

  setup() {
    return {
      authStore: useAuth(),
      companyStore: useCompany(),
      orderStore: useOrder(),
      favoriteStore: useFavorite(),
    };
  },

  methods: {
    goToCart() {
      if (!this.authStore.activeUser) {
        this.authStore.toggleAuthWindow(true, 'c-cart');
      } else {
        this.$router.push({
          name: 'c-cart',
        });
      }
    },

    goToDelivery() {
      this.$router.push({
        name: 'c-orders-in-delivery',
      });
    },

    goLoyalty() {
      this.$router.push({
        name: 'c-loyalty',
      });
    },

    filterByFavorites() {
      this.$router.push({
        name: 'c-catalog',
        query: {
          is_favorite: 'true',
        },
      });
    },
  },
});
