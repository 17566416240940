import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24d062da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-item-product-list" }
const _hoisted_2 = { class: "item__image-wrapper" }
const _hoisted_3 = ["src", "onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(
        [
          'order-item-product-list__items',
          {
            'order-item-product-list__items--full': _ctx.isFullOrder,
          },
        ]
      )
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          ref_for: true,
          ref: _ctx.setItemRef,
          key: item,
          class: _normalizeClass(
          [
            'order-item-product-list__item',
            'item',
            { 'item--not-available': !item.isAvailableForPurchase },
          ]
        )
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "item__image",
              src: item.product.image,
              onClick: ($event: any) => (_ctx.openFastView(item.product, item.productOption, true))
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", {
            class: "item__name",
            onClick: ($event: any) => (_ctx.openFastView(item.product, item.productOption, true))
          }, _toDisplayString(item.name), 9, _hoisted_4)
        ], 2))
      }), 128))
    ], 2),
    (_ctx.isToggler)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "order-item-product-list__toggler",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFullnessOrder && _ctx.toggleFullnessOrder(...args)))
        }, _toDisplayString(_ctx.togglerTitle), 1))
      : _createCommentVNode("", true)
  ]))
}