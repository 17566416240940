import jsonApi from '@/libs/jsonapi';

interface ISearchAddress {
  city: string;
  street: string;
  building: string;
  fullName: string;
  geozoneId: string;
}

jsonApi.define<ISearchAddress>(
  'search_address',
  {
    city: '',
    street: '',
    building: '',
    fullName: '',
    geozoneId: '',
  },
  {
    collectionPath: 'search-addresses',
  },
);

export default ISearchAddress;
