import jsonApi from '@/libs/jsonapi';

interface ICompanyDomain {
  id: string;
  name: string;
  isMain: boolean;
  isAttached: boolean;
}

jsonApi.define<ICompanyDomain>('company_domain', {
  name: '',
  isMain: false,
  isAttached: false,
});

export default ICompanyDomain;
