
import vClickOutside from 'click-outside-vue3';
import { defineComponent } from 'vue';

import { lockScrollBody, unlockScrollBody } from '@/helpers/scrollBody';

const BLUR_EVENT = 'blur';

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive,
  },

  emits: ['close'],

  mounted() {
    lockScrollBody();
  },

  unmounted() {
    unlockScrollBody();
  },

  methods: {
    clickOutside(event: Event) {
      if (event.type === BLUR_EVENT) {
        return;
      }

      this.$emit('close');
    },
  },
});
