import { IPage } from '@/types';

export default function getFormatPages(...pages: IPage[]): IPage[] {
  return pages.map<IPage>((page) => ({
    title: page.name || page.title,
    icon: page.slug || page.id,
    routeSettings: page.route
      ? { name: page.route }
      : {
        name: 'c-custom-pages',
        params: { customPage: page.slug },
      },
  }));
}
