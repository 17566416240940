import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#company-header-menu"
        }, [
          _createVNode(_component_MainMenu, { items: _ctx.menuItems }, null, 8, ["items"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}