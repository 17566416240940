import { format } from 'date-fns';

function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days);

  return date;
}

function getDayAndMonthStr(date: Date): string {
  return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}`;
}

function formatDate(date: Date, type: string): string {
  return format(date, type);
}

export { addDays, getDayAndMonthStr, formatDate };
