import { defineStore } from 'pinia';

import IProduct from '@/models/product';
import IProductOption from '@/models/productOption';

import { useCatalog } from '@/stores/catalog';
import useProduct from '@/stores/product';

type IFastViewState = {
  selectedProduct: IProduct | undefined,
  selectedOption: IProductOption | undefined,
  withNotify: boolean,
};

const useFastView = defineStore('fastView', {
  state(): IFastViewState {
    return {
      selectedProduct: undefined,
      selectedOption: undefined,
      withNotify: false,
    };
  },

  actions: {
    close() {
      this.withNotify = false;
      this.selectedProduct = undefined;
      this.selectedOption = undefined;
    },

    open(params: { product: IProduct, option: IProductOption }, withNotify = false) {
      const { product, option } = params;

      this.withNotify = withNotify;
      this.setProduct(product.id, option);
    },

    async setProduct(productId: string, option?: IProductOption) {
      this.selectedProduct = useCatalog().products.find(({ id }) => id === productId);

      if (this.selectedProduct) {
        this.selectedOption = option;

        return;
      }

      this.selectedProduct = await useProduct().getById(productId);
      [this.selectedOption] = this.selectedProduct.productOptions;
    },
  },
});

export default useFastView;
