
import { defineComponent } from 'vue';

import useCompany from '@/stores/company';

import Footer from '@/components/shared/Footer/Footer.vue';
import Cover from '@/components/shared/Cover/Cover.vue';
import Header from '@/components/shared/Header/Header/Header.vue';
import HeaderDivider from '@/components/shared/Header/HeaderDivider/HeaderDivider.vue';
import MainMenu from '@/components/shared/MainMenu/MainMenu.vue';
import ParentCompanyHeader from '@/components/parentCompany/Header/Header.vue';

export default defineComponent({
  name: 'ParentCompanyRootView',

  components: {
    Footer,
    Cover,
    Header,
    HeaderDivider,
    MainMenu,
    ParentCompanyHeader,
  },

  setup() {
    return {
      companyStore: useCompany(),
    };
  },

  data() {
    return {
      menuItems: [],
    };
  },
});
