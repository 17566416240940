import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';

import IFavoriteProduct from '@/models/favoriteProduct';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';

type IFavoriteState = {
  favoriteProductsIds: {[x: string]: boolean},
};

const toast = useToast();

const useFavorite = defineStore('favoriteStore', {
  state(): IFavoriteState {
    return {
      favoriteProductsIds: {},
    };
  },

  getters: {
    productsCount(state: IFavoriteState) {
      return Object.keys(state.favoriteProductsIds).length;
    },
  },

  actions: {
    async toggleFavoriteProduct(id: string) {
      if (this.favoriteProductsIds[id]) {
        this.deleteFromFavorites(id);
      } else {
        this.addToFavorites(id);
      }
    },

    async addToFavorites(id: string) {
      try {
        await jsonApi
          .one('company', useCompany().activeCompany!.login)
          .all('user_favorite_product')
          .post({
            productId: id,
          });
      } catch (error: any) {
        toast.error(
          error.response.data.errors[0].title || 'Ошибка добавления продукта в список избранных',
        );

        return;
      }

      this.favoriteProductsIds[id] = true;
    },

    async deleteFromFavorites(id: string) {
      try {
        await jsonApi
          .one('company', useCompany().activeCompany!.login)
          .one('user_favorite_product', id)
          .destroy();
      } catch (error: any) {
        toast.error(
          error.response.data.errors[0].title || 'Ошибка удаления продукта из списка избранных',
        );

        return;
      }

      delete this.favoriteProductsIds[id];
    },

    initFavorites(products: IFavoriteProduct[] | undefined) {
      if (!products) {
        return;
      }

      products.forEach(({ productId }) => {
        this.favoriteProductsIds[productId] = true;
      });
    },
  },
});

export default useFavorite;
