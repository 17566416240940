
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  onUnmounted,
} from 'vue';
import {
  Slide,
  Carousel,
  Pagination,
} from 'vue3-carousel';

import { MOBILE_BREAKPOINT } from '@/constants';

import ICompanyBanner from '@/models/banner';

import throttleEvent from '@/libs/throttleEvent';

const AUTOPLAY_TIME = 6000;
const TRANSITION_TIME = 500;
const BANNERS = {
  mobile: 'mobileImage',
  desktop: 'desktopImage',
};

export default defineComponent({
  name: 'Banners',

  components: {
    Slide,
    Carousel,
    Pagination,
  },

  props: {
    items: {
      type: Array as PropType<ICompanyBanner[]>,
      required: true,
    },
  },

  setup() {
    throttleEvent('resize', 'optimizedResize');

    const windowWidth = ref(window.innerWidth);

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => window.addEventListener('optimizedResize', onWidthChange));
    onUnmounted(() => window.removeEventListener('optimizedResize', onWidthChange));

    const isMobile = computed(() => windowWidth.value <= MOBILE_BREAKPOINT);

    return {
      isMobile,
    };
  },

  data() {
    return {
      AUTOPLAY_TIME,
      TRANSITION_TIME,
    };
  },

  computed: {
    attrName(): string {
      return this.isMobile
        ? BANNERS.mobile
        : BANNERS.desktop;
    },
  },
});
