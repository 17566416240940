import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-133f545b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "history-table-item-wrapper" }
const _hoisted_2 = { class: "history-table-item" }
const _hoisted_3 = { class: "history-table-item__name-date name-date" }
const _hoisted_4 = { class: "name-date__name" }
const _hoisted_5 = { class: "name-date__date" }
const _hoisted_6 = { class: "history-table-item__name" }
const _hoisted_7 = { class: "history-table-item__date" }
const _hoisted_8 = {
  key: 0,
  class: "notes"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, _toDisplayString(_ctx.item.title), 1),
          (_ctx.item.notes)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(['notes-toggler', {'notes-toggler--active': _ctx.isOpenNotes}]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleNote && _ctx.toggleNote(...args)))
              }, null, 2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.createdAt), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, _toDisplayString(_ctx.item.title), 1),
        (!_ctx.item.isHidden)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['notes-toggler', {'notes-toggler--active': _ctx.isOpenNotes}]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleNote && _ctx.toggleNote(...args)))
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.createdAt), 1),
      _createElementVNode("div", {
        class: _normalizeClass([
          'history-table-item__scores',
          {
            'history-table-item__scores--decrease': !_ctx.isIncrease,
          },
        ])
      }, _toDisplayString(_ctx.isIncrease ? '+' : '-') + " " + _toDisplayString(_ctx.item.sum) + " ББ ", 3)
    ]),
    (_ctx.isOpenNotes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.item.description), 1))
      : _createCommentVNode("", true)
  ]))
}