import PaymentType, { IPaymentType, IPostPaidPageParams } from './paymentType';

export default class Cash extends PaymentType implements IPaymentType {
  toPostPaidPage(order: Partial<IPostPaidPageParams>): void {
    this.router.push({
      name: 'c-success-payment',
      params: { orderId: order.id },
    });
  }
}
