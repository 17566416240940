import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6145dd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyOrder = _resolveComponent("EmptyOrder")!
  const _component_FullOrder = _resolveComponent("FullOrder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.orderStore.wasLoaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.orderStore.isEmpty)
            ? (_openBlock(), _createBlock(_component_EmptyOrder, { key: 0 }))
            : (_openBlock(), _createBlock(_component_FullOrder, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}