
import { defineComponent, PropType } from 'vue';

import { DELIVERY_ZONE_OPACITY } from '@/constants';

import IDeliveryZone from '@/models/deliveryZone';

export default defineComponent({
  props: {
    zones: {
      type: Array as PropType<IDeliveryZone[]>,
      required: true,
    },
    selectedId: {
      type: String || null,
      default: null,
    },
  },

  emits: ['select'],

  setup() {
    return {
      DELIVERY_ZONE_OPACITY,
    };
  },

  watch: {
    selectedId: 'scrollToSelected',
  },

  methods: {
    scrollToSelected(value: string) {
      (this.$refs[`zone-${value}`] as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    },
  },
});
