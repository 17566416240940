
import { defineComponent } from 'vue';

const MIN_VALUE = 1;

export default defineComponent({
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    isBlockDecreaseControl: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['increase', 'decrease'],

  data() {
    return {
      MIN_VALUE,
    };
  },
});
