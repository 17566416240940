
import { defineComponent, PropType } from 'vue';
import { MoPreloader } from '@mo/uikit';

import ICoupon from '@/models/coupon';

export default defineComponent({
  name: 'CouponPanel',

  components: {
    MoPreloader,
  },

  props: {
    coupon: {
      type: Object as PropType<ICoupon>,
      default: null,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['apply', 'reset'],

  data() {
    return {
      couponCode: this.coupon?.code,
    };
  },

  watch: {
    coupon(value) {
      this.couponCode = value?.code || '';
    },
  },

  methods: {
    resetCoupon() {
      this.couponCode = '';

      this.$emit('reset');
    },
  },
});
