import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bcd6961"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "optionsComponent",
    class: _normalizeClass(["options-list", `options-list--${_ctx.type}`])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["options-list__prev", {'options-list__prev--disabled': _ctx.isBackBtnLocked}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.slideBack && _ctx.slideBack(...args)))
    }, null, 2),
    _createElementVNode("div", {
      ref: `list-wrapper-${_ctx.productId}`,
      class: _normalizeClass(["options-list__list-wrapper", [
        {'options-list__list-wrapper--without-end-shadow': _ctx.isForwardBtnLocked},
        {'options-list__list-wrapper--without-start-shadow': _ctx.isBackBtnLocked},
      ]])
    }, [
      _createElementVNode("div", {
        class: "options-list__list",
        style: _normalizeStyle(_ctx.sliderCssStyle)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            id: `option-item-${_ctx.productId}`,
            key: item.id,
            class: _normalizeClass(["options-list__item", { 'options-list__item--selected': _ctx.selectedOptionId === item.id }]),
            onClick: ($event: any) => (_ctx.$emit('select', item))
          }, _toDisplayString(item.title), 11, _hoisted_1))
        }), 128))
      ], 4)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["options-list__next", {'options-list__next--disabled': _ctx.isForwardBtnLocked}]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.slideForward && _ctx.slideForward(...args)))
    }, null, 2)
  ], 2))
}