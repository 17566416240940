import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cc0eb16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-zones" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomYandexMap = _resolveComponent("CustomYandexMap")!
  const _component_Info = _resolveComponent("Info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.deliveryZoneStore.deliveryZones)
      ? (_openBlock(), _createBlock(_component_CustomYandexMap, {
          key: 0,
          class: "delivery-zones__map",
          zones: _ctx.deliveryZoneStore.deliveryZones,
          "selected-id": _ctx.selectedZoneId,
          onSelect: _ctx.selectZone
        }, null, 8, ["zones", "selected-id", "onSelect"]))
      : _createCommentVNode("", true),
    (_ctx.deliveryZoneStore.deliveryZones)
      ? (_openBlock(), _createBlock(_component_Info, {
          key: 1,
          class: "delivery-zones__info",
          zones: _ctx.deliveryZoneStore.deliveryZones,
          "selected-id": _ctx.selectedZoneId,
          onSelect: _ctx.selectZone
        }, null, 8, ["zones", "selected-id", "onSelect"]))
      : _createCommentVNode("", true)
  ]))
}