import jsonApi from '@/libs/jsonapi';

interface IRestaurant {
  id: string;
  logo: string;
  name: string;
  deliveryPrice: string;
}

jsonApi.define<IRestaurant>(
  'restaurant',
  {
    logo: '',
    name: '',
    deliveryPrice: '',
  },
  {
    collectionPath: 'restaurants',
  },
);

export default IRestaurant;
