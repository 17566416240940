
import { defineComponent } from 'vue';

import useCompany from '@/stores/company';

export default defineComponent({
  name: 'Cover',

  setup() {
    return {
      companyStore: useCompany(),
    };
  },
});
