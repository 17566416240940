import { RouteRecordRaw } from 'vue-router';

import { SCOPE_TYPE } from '@/types';

import ParentCompanyRootView from '@/views/ParentCompany/Root.vue';
import ChildCompaniesView from '@/views/ParentCompany/ChildCompanies/ChildCompaniesView.vue';

const ParentCompanyRoutes: Array<RouteRecordRaw> = [
  {
    path: '/p/',
    name: 'parent-company',
    meta: {
      scope: SCOPE_TYPE.PARENT_COMPANY,
    },
    component: ParentCompanyRootView,

    redirect: () => ({
      name: 'pc-child-companies',
    }),

    children: [
      {
        path: 'companies',
        name: 'pc-child-companies',
        component: ChildCompaniesView,
      },
    ],
  },
];

export default ParentCompanyRoutes;
