
import { defineComponent } from 'vue';

import useUserCustomer from '@/stores/userCustomer';

import UserCustomerItem from '../Item/Item.vue';
import UserCustomerForm from '../Form/Form.vue';

export default defineComponent({
  name: 'UserCustomersList',

  components: {
    UserCustomerItem,
    UserCustomerForm,
  },

  props: {
    selectedId: {
      type: String,
      default: undefined,
    },
    isFormOpenedWhenEmpty: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['wasSelected', 'wasCreated', 'wasRemoved', 'wasLoaded'],

  data() {
    const userCustomerStore = useUserCustomer();

    userCustomerStore.getAll().then((customers) => {
      if (this.isFormOpenedWhenEmpty && !customers.length) {
        this.toggleForm(true);
      }

      this.$emit('wasLoaded', customers);
    });

    return {
      userCustomerStore,
      newUserCustomer: userCustomerStore.getEmpty(),
      isFormOpened: false,
      isAutocomplete: false,
    };
  },

  methods: {
    toggleForm(state: boolean) {
      this.isAutocomplete = !this.userCustomerStore.userCustomers.length;

      this.isFormOpened = state;
    },
  },
});
