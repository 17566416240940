import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1d0a366"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-types-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TypePanel = _resolveComponent("TypePanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsTypes, (type, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "payment-types-panel__item"
      }, [
        _createVNode(_component_TypePanel, {
          type: type,
          "type-id": type.paymentTypeId,
          "is-active": type.paymentTypeId === _ctx.selectedPaymentTypeId,
          onSelect: _ctx.selectPaymentType
        }, null, 8, ["type", "type-id", "is-active", "onSelect"])
      ]))
    }), 128))
  ]))
}