import { defineStore } from 'pinia';

const useVersion = defineStore('version', {
  state(): {
    version: string,
    isOpenVersionWarning: boolean,
    } {
    return {
      version: '',
      isOpenVersionWarning: false,
    };
  },

  actions: {
    setVersion(version: string) {
      this.version = version;
    },

    toggleOpenVersionWarning(state = true) {
      this.isOpenVersionWarning = state;
    },
  },
});

export default useVersion;
