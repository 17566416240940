import { LocationQuery, LocationQueryRaw } from 'vue-router';
import { cloneDeep } from 'lodash';

import { QUERY_PARAMS } from '@/types';

type TQUERY = Partial<{
  [key in QUERY_PARAMS]: string;
}>

function getQueryParams(
  query: LocationQuery, updatedQuery: TQUERY,
): LocationQueryRaw {
  const queryParams = cloneDeep(query);

  (Object.keys(updatedQuery) as QUERY_PARAMS[]).forEach((key) => {
    if (updatedQuery[key]) {
      queryParams[key] = String(updatedQuery[key]);
    } else {
      delete queryParams[key];
    }
  });

  return queryParams as LocationQueryRaw;
}

export { getQueryParams as default };
