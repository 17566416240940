
import { defineComponent } from 'vue';
import { MoUserLogin } from '@mo/uikit';

import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';
import useOrder from '@/stores/order';
import useFavorite from '@/stores/favorite';

import ProfileMenu from '@/components/company/Auth/AuthForm/ProfileMenu/ProfileMenu.vue';

export default defineComponent({
  name: 'UserPanel',

  components: {
    MoUserLogin,
    ProfileMenu,
  },

  setup() {
    return {
      authStore: useAuth(),
      companyStore: useCompany(),
      orderStore: useOrder(),
      favoriteStore: useFavorite(),
    };
  },

  data() {
    return {
      isProfileMenuOpen: false,
      // eslint-disable-next-line global-require
      profileIcon: require('./images/profile.svg'),
    };
  },

  computed: {
    avatarUrl(): string {
      return (
        this.authStore.activeUser?.moBaseUser?.avatarUrl
        || this.profileIcon
      );
    },
  },

  methods: {
    toggleProfileMenu(state?: boolean) {
      this.isProfileMenuOpen = state === undefined ? !this.isProfileMenuOpen : state;
    },

    async logout() {
      await this.authStore.logout();
      this.toggleProfileMenu(false);
      this.authStore.clearActiveUser();
      this.authStore.clearBonusInfoInLocalStorage();

      document.location.reload();
    },
  },

});
