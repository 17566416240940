
import { defineComponent } from 'vue';

import ICompany from '@/models/company';

import useCompany from '@/stores/company';

import CompaniesList from '@/components/shared/Companies/CompaniesList/CompaniesList.vue';
import Cover from '@/components/shared/Cover/Cover.vue';
import Header from '@/components/shared/Header/Header/Header.vue';
import HeaderDivider from '@/components/shared/Header/HeaderDivider/HeaderDivider.vue';
import Footer from '@/components/shared/Footer/Footer.vue';

export default defineComponent({
  name: 'AggregatorRootView',

  components: {
    CompaniesList,
    Cover,
    Header,
    HeaderDivider,
    Footer,
  },

  setup() {
    return {
      companyStore: useCompany(),
    };
  },

  data() {
    return {
      companies: [] as ICompany[],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.companies = await this.companyStore.findAll({
        include: 'domain',
      });
    },
  },
});
