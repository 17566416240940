import { defineStore } from 'pinia';

import IGroup from '@/models/group';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';

const useGroup = defineStore('product_group', {
  actions: {
    async getAll(
      params: Record<string, unknown> = {},
    ): Promise<IGroup[]> {
      const response = await jsonApi.one('company', useCompany().activeCompany!.login)
        .all('product_group')
        .get<IGroup[]>(params);

      return response.data;
    },
  },
});

export default useGroup;
