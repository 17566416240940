import { defineStore } from 'pinia';

import IProduct, { TProductSort } from '@/models/product';

import useCompany from './company';

import jsonApi from '@/libs/jsonapi';

const useProduct = defineStore('product', {
  actions: {
    async getCompanyProducts(
      params: Partial<{
        filters: Partial<{
          name: string;
          'product_groups': string;
          'filter_ids': string;
          'kitchen_ids': string;
          'is_favorite': string;
          'restaurant_id': string;
        }>,
        sort: TProductSort,
        page: {
          number: string,
          size: string,
        },
      }> = {},
    ): Promise<JsonApiResponse<IProduct[], { total: number }>> {
      const response = await jsonApi.one('company', useCompany().activeCompany!.login)
        .all('product')
        .get<IProduct[], { total: number }>({
          ...params,
          include: 'product_options',
        });

      return response;
    },

    async getById(id: string) {
      return (
        await jsonApi.one('company', useCompany().activeCompany!.login)
          .one('product', id)
          .get<IProduct>({ include: 'product_options' })
      ).data;
    },
  },
});

export default useProduct;
