
import { defineComponent } from 'vue';
import { debounce } from 'lodash';

import { INVALID_ORDER_ERRORS } from '@/constants';

import { DOCUMENT_TYPE } from '@/models/legalDocument';
import IDeliveryAddress from '@/models/deliveryAddress';
import { DELIVERY_TYPE } from '@/models/deliveryType';
import { PAYMENT_TYPE, isPaymentType } from '@/models/paymentType';
import IUserCustomer from '@/models/userCustomer';

import useCompany from '@/stores/company';
import useOrder from '@/stores/order';

import ModalDialog from '@/components/shared/ModalDialog/ModalDialog.vue';
import ProductsPanel from '@/components/company/Order/ProductsPanel/ProductsPanel.vue';
import DeliveryTypesPanel from '@/components/company/Order/DeliveryTypesPanel/DeliveryTypesPanel.vue';
import DeliveryAddressesList from '@/components/company/DeliveryAddresses/List/List.vue';
import PaymentTypesPanel from '@/components/company/Order/PaymentTypesPanel/PaymentTypesPanel.vue';
import DeliverySettingsForm from '@/components/company/Order/DeliverySettingsForm/DeliverySettingsForm.vue';
import CertificateWarning from '@/components/company/Order/CertificateWarning/CertificateWarning.vue';
import PickupAddresses from '@/components/company/Order/PickupAddresses/PickupAddresses.vue';
import UserCustomersList from '@/components/company/UserCustomers/List/List.vue';
import SummaryLayout from '@/components/company/Order/SummaryLayout/SummaryLayout.vue';
import EmptyLayout from '@/components/company/Order/EmptyLayout/EmptyLayout.vue';
import Warning from '@/components/shared/Warning/Warning.vue';

export default defineComponent({
  name: 'CartView',

  components: {
    ProductsPanel,
    DeliveryTypesPanel,
    DeliveryAddressesList,
    DeliverySettingsForm,
    PaymentTypesPanel,
    UserCustomersList,
    PickupAddresses,
    SummaryLayout,
    EmptyLayout,
    ModalDialog,
    Warning,
    CertificateWarning,
  },

  async beforeRouteEnter(to, from, next) {
    const companyStore = useCompany();

    await companyStore.fetchActiveCompany(companyStore.activeCompany!.login, {
      include: 'order_placement_options',
    });

    next();
  },

  setup() {
    const orderStore = useOrder();
    const companyStore = useCompany();

    orderStore.getPaymentTypes();
    orderStore.getDeliveryTypes();

    return {
      orderStore,
      companyStore,
      INVALID_ORDER_ERRORS,
      DOCUMENT_TYPE,
    };
  },

  data() {
    return {
      isMounted: false,
      DELIVERY_TYPE,
      PAYMENT_TYPE,
      isVisibleErrors: false,
      debouncedChangeChequeEmail: debounce(this.changeChequeEmail as () => void, 300),
      debouncedChangeCashAmountOrder: debounce(
        this.changeCashAmount as () => void, 300,
      ),
    };
  },

  computed: {
    isCertificateWarningVisible(): boolean {
      if (!this.orderStore.activeOrder?.paymentTypeId
        || !isPaymentType(this.orderStore.activeOrder.paymentTypeId)) {
        return false;
      }

      return PAYMENT_TYPE.CARD_ONLINE === this.orderStore.activeOrder?.paymentTypeId;
    },

    isEmailFormVisible(): boolean {
      return [PAYMENT_TYPE.SBP, PAYMENT_TYPE.CARD_ONLINE].includes(
        this.orderStore.activeOrder?.paymentTypeId as PAYMENT_TYPE,
      );
    },

    isCashAmountFormVisible(): boolean {
      return this.orderStore.activeOrder?.paymentTypeId === PAYMENT_TYPE.CASH;
    },
  },

  watch: {
    'orderStore.activeOrder.deliveryTypeId': {
      handler() {
        this.isVisibleErrors = false;
      },
    },

    'orderStore.errorsByKeys': {
      handler(value) {
        if (!this.isVisibleErrors) {
          return;
        }

        this.scrollToErrorField(value);
      },
    },
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    changeChequeEmail(e: InputEvent) {
      this.setVisibilityErrors(true, false);
      this.orderStore.changeChequeEmail((e.target as HTMLInputElement).value);
    },

    async changeCashAmount(e: InputEvent) {
      this.orderStore.update({
        cashAmount: (e.target as HTMLInputElement).value,
      });
    },

    scrollToErrorField(errorsByKeys: Record<string, boolean>) {
      const [fieldId] = Object.keys(errorsByKeys).sort(
        (a, b) => this.orderStore.errorOrders[a] - this.orderStore.errorOrders[b],
      );

      const errorElement = document.getElementById(fieldId);

      if (!errorElement) {
        return;
      }

      errorElement.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    },

    updateUserCustomer(userCustomer: IUserCustomer) {
      this.orderStore.update({
        userCustomer,
      });
    },

    removeUserCustomer(id: string) {
      if (this.orderStore.activeOrder?.userCustomer?.id !== id) {
        return;
      }

      this.orderStore.update({
        userCustomer: undefined,
      });
    },

    selectDeliveryAddress(deliveryAddress: IDeliveryAddress) {
      this.orderStore.update({
        companyUserDeliveryAddress: deliveryAddress,
      });
    },

    updateDeliveryAddress(deliveryAddress: IDeliveryAddress) {
      if (deliveryAddress.id !== this.orderStore.activeOrder?.companyUserDeliveryAddress?.id) {
        return;
      }

      this.orderStore.update({
        companyUserDeliveryAddress: deliveryAddress,
      });
    },

    removeDeliveryAddress(id: string) {
      if (this.orderStore.activeOrder?.companyUserDeliveryAddress?.id !== id) {
        return;
      }

      this.orderStore.update({
        companyUserDeliveryAddress: undefined,
      });
    },

    async setDefaultDeliveryAddress(addresses: IDeliveryAddress[]) {
      if (this.orderStore.activeOrder?.companyUserDeliveryAddress) {
        return;
      }

      let selectedAddress = addresses.find(({ isDefault }) => isDefault);

      if (!selectedAddress && addresses.length === 1) {
        [selectedAddress] = addresses;
      }

      if (!selectedAddress) {
        this.setVisibilityErrors();

        return;
      }

      await this.orderStore.update({
        companyUserDeliveryAddress: selectedAddress,
      });

      this.setVisibilityErrors();
    },

    setDefaultUserCustomer(userCustomers: IUserCustomer[]) {
      if (this.orderStore.activeOrder?.userCustomer) {
        return;
      }

      let selectedCustomer = userCustomers.find(({ isDefault }) => isDefault);

      if (!selectedCustomer && userCustomers.length === 1) {
        [selectedCustomer] = userCustomers;
      }

      if (!selectedCustomer) {
        return;
      }

      this.updateUserCustomer(selectedCustomer);
    },

    setVisibilityErrors(state = true, withScroll = true) {
      this.isVisibleErrors = state;

      if (withScroll) {
        this.scrollToErrorField(this.orderStore.errorsByKeys);
      }
    },

    refreshOrder() {
      this.orderStore.fetchActiveOrder();
      this.orderStore.clearOrderErrorCode();
    },
  },
});
