
import { defineComponent, PropType } from 'vue';

import IBonusTransaction from '@/models/bonusTransaction';

import Item from '@/components/shared/HistoryScores/Item/Item.vue';

export default defineComponent({
  name: 'HistoryScores',

  components: {
    Item,
  },

  props: {
    list: {
      type: Array as PropType<IBonusTransaction[]>,
      required: true,
    },
  },
});
