
import { defineComponent, PropType } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import IUserCustomer from '@/models/userCustomer';
import { IUser } from '@/models/user';

import useAuth from '@/stores/auth';
import useUserCustomer from '@/stores/userCustomer';

type IUserCustomerEdit = Omit<IUserCustomer, 'id'>;
const regExpPhoneWithMask = /\+\d{1}\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/;

const presetValidation = (userCustomer: IUserCustomer) => {
  const { errors, meta } = useForm<Omit<IUserCustomerEdit, 'isDefault'>>({
    initialValues: {
      fullName: userCustomer.fullName,
      phone: userCustomer.phone,
    },
    validationSchema: yup.object({
      fullName: yup.string().required().label('Получатель'),
      phone: yup
        .string()
        .required()
        .matches(
          regExpPhoneWithMask,
          'Номер телефона должен содержать 11 цифр.',
        )
        .label('Телефон'),
    }),
  });

  return {
    errors,
    meta,

    fullName: useField<string>('fullName').value,
    phone: useField<string>('phone').value,
  };
};

export default defineComponent({
  name: 'UserCustomerForm',

  props: {
    userCustomer: {
      type: Object as PropType<IUserCustomer>,
      required: true,
    },
    isAutocomplete: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close', 'wasCreated', 'wasRemoved'],

  setup(props) {
    const validationData = presetValidation(props.userCustomer);

    return {
      isNew: !props.userCustomer.id,
      ...validationData,
      userCustomerStore: useUserCustomer(),
      mask: '+7 (###) ###-##-##',
    };
  },

  data() {
    if (this.isAutocomplete) {
      // eslint-disable-next-line prefer-destructuring
      const activeUser = useAuth().activeUser;

      this.setUserInfo(activeUser);
    }

    return {
      isProcessing: false,
      phoneRaw: '',
    };
  },

  computed: {
    title(): string {
      return this.isNew ? 'Добавление' : 'Редактирование данных получателя';
    },
  },

  methods: {
    setUserInfo(activeUser?: IUser) {
      const {
        phone,
        moBaseUser,
      } = activeUser || {};

      this.phone = phone || '';
      this.fullName = moBaseUser?.fullName || '';
    },

    async save() {
      if (!this.meta.valid) {
        return;
      }

      let userCustomer: IUserCustomer | undefined;
      let success = true;
      this.isProcessing = true;

      const method = this.isNew ? this.create : this.update;

      try {
        userCustomer = await method();
      } catch (error) {
        success = false;
        console.log('Ошибка сохранения пользователя');
      } finally {
        this.isProcessing = false;
      }

      if (success) {
        if (this.isNew) {
          this.$emit('wasCreated', userCustomer);
        }

        this.$emit('close');
      }
    },

    async remove() {
      this.isProcessing = true;

      await this.userCustomerStore.remove(this.userCustomer.id);

      this.isProcessing = false;

      this.$emit('wasRemoved', this.userCustomer.id);
    },

    update() {
      return this.userCustomerStore.update({
        id: this.userCustomer.id,
        fullName: this.fullName,
        phone: this.phoneRaw,
        isDefault: this.userCustomer.isDefault,
      });
    },

    create() {
      return this.userCustomerStore.create({
        fullName: this.fullName,
        phone: this.phoneRaw,
        isDefault: true,
      });
    },
  },
});
