import jsonApi from '@/libs/jsonapi';

interface IOrderPlacementOptions {
  id: string;
  personCountEnabled: boolean;
  confirmByPhoneEnabled: boolean;
  isContactlessEnabled: boolean;
}

jsonApi.define<IOrderPlacementOptions>('order_placement_option', {
  personCountEnabled: false,
  confirmByPhoneEnabled: false,
  isContactlessEnabled: false,
});

export default IOrderPlacementOptions;
