import jsonApi from '@/libs/jsonapi';

interface ICompanyBanner {
  href: string;
  mobileImage: string;
  desktopImage: string;
}

jsonApi.define<ICompanyBanner>('banner', {
  href: '',
  mobileImage: '',
  desktopImage: '',
});

export default ICompanyBanner;
