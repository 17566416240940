import ICompany from './company';
import IProductOption from './productOption';

import jsonApi from '@/libs/jsonapi';

enum TProductSort {
  byPriceAsk = 'price',
  byPriceDesk = '-price',
}

function isProductSort(str: string | undefined): str is TProductSort {
  return Object.values(TProductSort)
    .some((stringType) => stringType === str);
}

interface IProduct {
  id: string;
  productGroupId: string;
  companyId: string;
  name: string;
  image: string;
  newUntil: string;
  isDeleted: boolean;
  isNew: boolean;

  company: ICompany;
  productOptions: IProductOption[];
}

jsonApi.define<IProduct>('product', {
  productGroupId: '',
  companyId: '',
  name: '',
  image: '',
  newUntil: '',
  isDeleted: false,
  isNew: false,

  company: {
    jsonApi: 'hasMany',
    type: 'companies',
  },
  productOptions: {
    jsonApi: 'hasMany',
    type: 'product_option',
  },
});

export { IProduct as default, TProductSort, isProductSort };
