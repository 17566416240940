import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5991207c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warning" }
const _hoisted_2 = { class: "warning__title" }
const _hoisted_3 = { class: "warning__text" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1),
    _createElementVNode("button", {
      class: _normalizeClass(["warning__btn base-btn base-btn--black", {
        'base-btn--disabled': _ctx.isLoading,
      }]),
      disabled: _ctx.isLoading,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, _toDisplayString(_ctx.buttonText), 11, _hoisted_4)
  ]))
}