import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b188523"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedPage !== _ctx.pagesCount)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "base-btn base-btn--default",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showMore && _ctx.showMore(...args)))
        }, " Показать еще "))
      : _createCommentVNode("", true),
    _createElementVNode("ul", null, [
      _createElementVNode("li", {
        class: _normalizeClass(["pagination__arrow", {
          'pagination__arrow--disabled': _ctx.selectedPage === 1,
        }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.select(_ctx.selectedPage - 1)))
      }, null, 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(["pagination__item", {
          'pagination__item--selected': _ctx.selectedPage === index,
        }]),
          onClick: ($event: any) => (_ctx.select(index))
        }, _toDisplayString(index), 11, _hoisted_2))
      }), 128)),
      _createElementVNode("li", {
        class: _normalizeClass(["pagination__arrow pagination__arrow--right", {
          'pagination__arrow--disabled': _ctx.selectedPage === _ctx.pagesCount,
        }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.select(_ctx.selectedPage + 1)))
      }, null, 2)
    ])
  ]))
}