
import { defineComponent, PropType } from 'vue';

import IOrderItem from '@/models/orderItem';

import useOrder from '@/stores/order';

import FinalPricePanel from '@/components/shared/Products/FinalPricePanel/FinalPricePanel.vue';

export default defineComponent({
  name: 'ProductsPanel',

  components: {
    FinalPricePanel,
  },

  props: {
    orderItems: {
      type: Array as PropType<IOrderItem[]>,
      required: true,
    },
  },

  emits: ['increaseItemQuantity', 'decreaseItemQuantity', 'deleteItem'],

  setup() {
    return {
      orderStore: useOrder(),
    };
  },

  methods: {
    isSingleOptionItem(item: IOrderItem) {
      return item.product?.productOptions.length === 1;
    },
  },
});
