
import { defineComponent, PropType } from 'vue';

interface IType {
  isEnabled: boolean;
  title: string;
}

export default defineComponent({
  name: 'TypePanel',

  props: {
    type: {
      type: Object as PropType<IType>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    typeId: {
      type: String,
      required: true,
    },
  },

  emits: ['select'],
});
