
import { defineComponent, PropType } from 'vue';

import IOrderItem from '@/models/orderItem';

import useOrder from '@/stores/order';

import QuantityControl from '@/components/shared/QuantityControl/QuantityControl.vue';
import FinalPricePanel from '@/components/shared/Products/FinalPricePanel/FinalPricePanel.vue';

export default defineComponent({
  components: {
    QuantityControl,
    FinalPricePanel,
  },

  props: {
    orderItem: {
      type: Object as PropType<IOrderItem>,
      required: true,
    },
    isVisibleRestaurant: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['toggleFastView'],

  setup(props) {
    return {
      orderStore: useOrder(),
      isSingleOption: props.orderItem?.product?.productOptions.length === 1,
    };
  },
});
