import jsonApi from '@/libs/jsonapi';

interface ILegalDocument {
  id: string;
  slug: string;
  url: string;
  title: string;
  isDisplayInFooter: boolean;
  file?: string;
  fileName: string;
}

enum DOCUMENT_TYPE {
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  CONTRACT_OFFER = 'contract-offer',
  LOYALTY_PROGRAM_RULES = 'loyalty-program-rules',
  CUSTOM_DOCUMENT = 'other-document',
}

const DOCUMENT_TYPE_TITLE: { [x: string]: string } = {
  [DOCUMENT_TYPE.PRIVACY_POLICY]: 'Политика конфиденциальности',
  [DOCUMENT_TYPE.TERMS_OF_USE]: 'Пользовательское соглашение',
  [DOCUMENT_TYPE.CONTRACT_OFFER]: 'Договор публичной оферты',
  [DOCUMENT_TYPE.LOYALTY_PROGRAM_RULES]: 'Правила программы лояльности',
  [DOCUMENT_TYPE.CUSTOM_DOCUMENT]: 'Другой тип',
};

function isLegalDocument(type: string): type is DOCUMENT_TYPE {
  return (Object.values(DOCUMENT_TYPE) as string[]).includes(type);
}

jsonApi.define<ILegalDocument>(
  'legal_document',
  {
    slug: '',
    url: '',
    title: '',
    isDisplayInFooter: false,
    file: '',
    fileName: '',
  },
  {
    collectionPath: 'legal-documents',
  }
);

export {
  ILegalDocument as default,
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_TITLE,
  isLegalDocument,
};
