import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ymap_marker = _resolveComponent("ymap-marker")!
  const _component_yandex_map = _resolveComponent("yandex-map")!

  return (_openBlock(), _createBlock(_component_yandex_map, {
    settings: _ctx.SETTINGS,
    zoom: _ctx.options.zoom,
    coords: _ctx.options.centerCoords,
    "scroll-zoom": false,
    controls: ['zoomControl']
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zones, (item) => {
        return (_openBlock(), _createBlock(_component_ymap_marker, {
          key: item.id,
          "marker-id": item.id,
          coords: [item.coordinates],
          "marker-stroke": {
        color: item.color,
        width: '1px',
      },
          "marker-fill": {
        color: item.color,
        opacity: (item.id === _ctx.selectedId || item.id === _ctx.hoveredId)
          ? _ctx.DELIVERY_ZONE_OPACITY.selected
          : _ctx.DELIVERY_ZONE_OPACITY.unselected,
      },
          "marker-type": "Polygon",
          onMouseenter: ($event: any) => (_ctx.toggleOpacity(item.id)),
          onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOpacity())),
          onClick: ($event: any) => (_ctx.$emit('select', item.id))
        }, null, 8, ["marker-id", "coords", "marker-stroke", "marker-fill", "onMouseenter", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["settings", "zoom", "coords"]))
}