
import { defineComponent } from 'vue';
import { MoPreloader } from '@mo/uikit';

import { CARD_TYPE_TITLES, PAYMENT_ERRORS } from '@/constants';

import { PAYMENT_TYPE } from '@/models/paymentType';
import { IOrderPayment, PAYMENT_STATUSES } from '@/models/orderPayment';

import useAuth from '@/stores/auth';
import useCompany from '@/stores/company';
import useOrder from '@/stores/order';
import usePaymentType from '@/stores/paymentType';

import pluralize from '@/helpers/pluralize';
import getDeviceType from '@/helpers/getDeviceType';
import CouponPanel from '@/components/company/Order/CouponPanel/CouponPanel.vue';
import BonusesPanel from '@/components/company/Order/BonusesPanel/BonusesPanel.vue';
import NumberPhonePanel from '@/components/shared/NumberPhonePanel/NumberPhonePanel.vue';
import YMetrika from '@/libs/yandex-metrika';
import ModalDialog from '@/components/shared/ModalDialog/ModalDialog.vue';
import SBPPanel from '@/components/company/Order/SBPPanel/SBPPanel.vue';

enum STOCKS {
  coupon = 'coupon',
  bonuses = 'bonuses',
}

export default defineComponent({
  name: 'SummaryLayout',

  components: {
    MoPreloader,
    CouponPanel,
    BonusesPanel,
    NumberPhonePanel,
    ModalDialog,
    SBPPanel,
  },

  setup() {
    const companyStore = useCompany();
    const orderStore = useOrder();
    const paymentTypeStore = usePaymentType();
    const authStore = useAuth();
    const hasBonusPoints = companyStore.activeCompany?.hasBonusPoints;

    return {
      authStore,
      companyStore,
      orderStore,
      paymentTypeStore,
      pluralize,
      CARD_TYPE_TITLES,
      STOCKS,
      PAYMENT_ERRORS,
      hasBonusPoints,
    };
  },

  data(): {
    errorCouponMessage: string,
    errorBonusesMessage: string,
    isLoadingCoupon: boolean,
    isLoadingBonuses: boolean,
    isMounted: boolean,
    stockType: STOCKS,
    paymentErrorText: string | null,
    payment: IOrderPayment | null,
    } {
    return {
      errorCouponMessage: '',
      errorBonusesMessage: '',
      isLoadingCoupon: false,
      isLoadingBonuses: false,
      isMounted: false,
      stockType: this.hasBonusPoints
        ? STOCKS.bonuses
        : STOCKS.coupon,
      paymentErrorText: null,
      payment: null,
    };
  },

  computed: {
    buttonTitle(): string {
      switch (this.orderStore.activeOrder?.paymentTypeId) {
        case PAYMENT_TYPE.CASH:
        case PAYMENT_TYPE.CARD_OFFLINE:
          return 'Оформить';
        case PAYMENT_TYPE.CARD_ONLINE:
          return 'Оплатить';
        default:
          return 'Оформить и оплатить';
      }
    },

    isEveryOrderItemStopPrice(): boolean {
      return this.orderStore.activeOrder?.items?.every(
        ({ productOption: { hasStopPrice } }) => hasStopPrice,
      ) || false;
    },

    buttonTitleError(): string {
      const [firstError] = Object.keys(this.orderStore.errorsByKeys).sort(
        (a, b) => this.orderStore.errorOrders[a] - this.orderStore.errorOrders[b],
      );

      return this.orderStore.errorsKeysMessage[firstError];
    },

    isVisibleDeliveryBlock(): boolean {
      return Boolean(this.orderStore.deliveryPrices.main
        || this.orderStore.deliveryPrices.isVisibleRestaurants
        || this.orderStore.deliveryAddress
        || this.orderStore.deliveryTime);
    },

    isVisibleDeliveryTopBorder(): boolean {
      return Boolean(this.orderStore.deliveryPrices.main
        || this.orderStore.deliveryPrices.isVisibleRestaurants
        || this.orderStore.deliveryTime);
    },
  },

  watch: {
    isEveryOrderItemStopPrice(state) {
      if (!state) {
        return;
      }

      this.stockType = STOCKS.coupon;
    },
  },

  mounted() {
    this.isMounted = true;

    if (this.isEveryOrderItemStopPrice) {
      this.stockType = STOCKS.coupon;
    }
  },

  methods: {
    processSbp() {
      window.location.href = String(this.payment?.sbpQrUrl);
    },

    async process() {
      const type = getDeviceType(navigator.userAgent);

      this.orderStore.processBtnWasClicked = true;

      if (this.orderStore.priceForDelivery) {
        return;
      }

      const isValid = await this.orderStore.validate();

      if (!isValid) {
        return;
      }

      YMetrika.goal('process');

      const order = await this.orderStore.process({
        deviceType: type,
      });
      this.payment = order?.payment || null;

      this.orderStore.fetchActiveOrder();

      this.resetPaymentError();

      if (!order || !order.paymentTypeId) {
        return;
      }

      this.paymentTypeStore.initStrategies(this.$router, window);

      if (!this.paymentTypeStore.isSbp) {
        this.paymentTypeStore.toPostPaidPage({
          id: order.id,
          type: order.paymentTypeId,
          redirectUrl: this.payment?.redirectFormUrl || '',
        });
      } else {
        this.paymentTypeStore.openSbpModal();
      }

      if (this.hasBonusPoints) {
        this.authStore.fetchActiveUser('bonus_info');
      }
    },

    async checkPayment() {
      if (!this.payment) {
        return;
      }

      const payment = (await this.orderStore.getPayment(this.payment.id)).data;

      if (payment.status === PAYMENT_STATUSES.successful && this.orderStore.activeOrder?.id) {
        this.paymentTypeStore.toPostPaidPage({ id: this.orderStore.activeOrder.id });
      } else if (payment.status === PAYMENT_STATUSES.failed) {
        this.setPaymentError(PAYMENT_ERRORS.serverError);
      }
    },

    setPaymentError(text: string) {
      this.paymentErrorText = text;
    },

    resetPaymentError() {
      this.paymentErrorText = null;
    },

    async applyCoupon(couponCode: string) {
      this.isLoadingCoupon = true;
      this.errorCouponMessage = '';

      let coupon;

      try {
        coupon = (await this.orderStore.checkCoupon(couponCode))?.data;

        await this.orderStore.update({
          coupon,
        });
      } catch (error: any) {
        this.errorCouponMessage = error.response.data.errors?.[0].detail
          || error.response.data.errors?.[0].title
          || 'Промокод недействителен';

        return;
      } finally {
        this.isLoadingCoupon = false;
      }
    },

    async resetCoupon() {
      this.isLoadingCoupon = true;

      await this.orderStore.update({
        coupon: null,
      });

      this.isLoadingCoupon = false;
    },

    toggleStock(stock: STOCKS) {
      this.stockType = stock;
    },

    async applyBonuses(bonuses: number) {
      this.errorBonusesMessage = '';

      const floatRegExp = /^\d+(\.\d{1,2})?$/;

      if (bonuses < 1 || !new RegExp(floatRegExp).test(String(bonuses))) {
        this.errorBonusesMessage = 'Введено некорректное значение';
        return;
      }

      if (+bonuses > Number(this.orderStore.activeOrder?.bonusesMaxWriteOffSum)) {
        this.errorBonusesMessage = `Максимальное количество баллов для списывания - ${
          this.orderStore.activeOrder?.bonusesMaxWriteOffSum
        }`;

        return;
      }

      if (+bonuses > Number(this.authStore.bonusInfo?.balance)) {
        this.errorBonusesMessage = `Максимальное количество баллов для списывания - ${
          this.authStore.bonusInfo?.balance
        }`;

        return;
      }

      this.isLoadingBonuses = true;

      try {
        await this.orderStore.applyBonuses(bonuses);
      } catch (error: any) {
        this.errorBonusesMessage = error.response.data.errors?.[0].detail
          || error.response.data.errors?.[0].title
          || 'Неудалось списать баллы';

        return;
      } finally {
        this.isLoadingBonuses = false;
      }
    },

    async resetBonuses() {
      this.isLoadingBonuses = true;
      try {
        await this.orderStore.resetBonuses();
      } finally {
        this.isLoadingBonuses = false;
      }
    },
  },
});
