import ICompanyDomain from './companyDomain';
import ICompanyBanner from './banner';
import ICustomPage from './customPage';
import ILegalDocument from './legalDocument';
import IRestaurant from './restaurant';
import ISeoTag from './seoTag';
import IOrderPlacementOptions from './orderPlacementOptions'

import jsonApi from '@/libs/jsonapi';

type WorkScheduleGrouped = {
  name: string;
  schedule: number[];
};

interface ICompany {
  id: string;
  name: string;
  login: string;
  isMain: boolean;
  description: string;
  logoUrl: string;
  additionalLogoUrl: string;
  coverUrl: string;
  phones?: string[];
  deliveryTime?: number[];
  workSchedule?: number[][];
  workScheduleGrouped: WorkScheduleGrouped[];
  yandexMetricKey?: string;
  customScript?: string;
  vk?: string;
  youtube?: string;
  facebook?: string;
  instagram?: string;
  hasOnlinePayment: boolean;
  hasCourierDelivery: boolean;
  hasMoCardDiscount: boolean;
  hasDeliveryZone: boolean;
  hasBonusPoints: boolean;

  children?: ICompany[];
  parent?: ICompany;
  domain: ICompanyDomain;
  banners?: ICompanyBanner[];
  customPages?: ICustomPage[];
  legalDocuments?: ILegalDocument[];
  restaurants?: IRestaurant[];
  seoTags?: ISeoTag[];
  orderPlacementOptions: IOrderPlacementOptions;
}

jsonApi.define<ICompany>('company', {
  name: '',
  login: '',
  isMain: false,
  description: '',
  logoUrl: '',
  additionalLogoUrl: '',
  coverUrl: '',
  phones: [],
  deliveryTime: [],
  workSchedule: [],
  workScheduleGrouped: [],
  yandexMetricKey: '',
  customScript: '',
  vk: '',
  youtube: '',
  facebook: '',
  instagram: '',
  hasOnlinePayment: false,
  hasCourierDelivery: false,
  hasMoCardDiscount: false,
  hasDeliveryZone: false,
  hasBonusPoints: false,

  children: {
    jsonApi: 'hasMany',
    type: 'company',
  },
  banners: {
    jsonApi: 'hasMany',
    type: 'banner',
  },
  parent: {
    jsonApi: 'hasOne',
    type: 'company',
  },
  domain: {
    jsonApi: 'hasOne',
    type: 'company_domain',
  },
  customPages: {
    jsonApi: 'hasMany',
    type: 'custom_page',
  },
  legalDocuments: {
    jsonApi: 'hasMany',
    type: 'legal_document',
  },
  restaurants: {
    jsonApi: 'hasMany',
    type: 'restaurant',
  },
  seoTags: {
    jsonApi: 'hasMany',
    type: 'seo_tag',
  },
  orderPlacementOptions: {
    jsonApi: 'hasOne',
    type: 'order_placement_option',
  },
});

export default ICompany;
